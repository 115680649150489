//
// Content Holder Widget
//
//.card-places-tree-widget {
//  .no-admin-mode & {
//    border-radius: 0;
//  }
//}
.PlacesTreeItem {
  .my-tree-item-text {
    padding-top: ($spacer / 2);
    padding-bottom: ($spacer / 2);
  }
}

.LandingPage {
  &.no-admin-mode .aside {
    .LayoutDropPanelCell {
      position: fixed !important;
      z-index: 5;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: $spacer ($spacer * 2) ($spacer * 2);
      @extend .overflow-hidden !optional;
      border: none;
      border-radius: 0;
      background-color: rgba($white, 0.5);

      .card-widget:not(.card-places-tree-widget):not(.card-workspace-widget) {
        display: none;
      }
    }

    &.hasWidget {
      z-index: 5;
    }

    .ui-places-tree-widget {
      border: none;
      background-color: transparent;
    }
  }
}
