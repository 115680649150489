//
// Admin Mode
.no-admin-mode {
  //.card-infinite-scroll-view-widget .x-grid3-td-__plus__,
  //.card-tile-view .x-grid3-td-__plus__,
  .x-grid3-hd-__plus__,
  .no-widgets,
  .Tabhidden {
    display: none !important;
  }
}

.admin-mode {
  .layout-dropzone {
    position: relative;
    margin-bottom: 1rem;
    padding: $spacer * 2.5 $spacer / 2 $spacer / 2;
    border-radius: $border-radius;
    background-color: rgba($black, 0.05);
  }

  .dropzone-settings {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 6px;
  }

  .BaseWidget-root {
    display: flex !important;

    .noMargin {
      padding: 5px;
    }
  }

  .switchViewWidgetButton {
    display: none;
  }

  .ConversionWidget-root {
    display: inherit !important;
  }

  .banner-overall {
    .config-button {
      display: block;
    }
  }

  .DraggableTab {
    &.Tabhidden,
    .x-tool-gear {
      display: inherit;
    }
  }

  .footerContainer,
  .headerContainer {
    min-height: $spacer * 4;
  }

  .action-palette {
    .x-tool-gear {
      display: flex;
    }
  }

  .ContentHolderWidget-root {
    &.titleless {
      &.noMargin {
        .BaseWidget-handle {
          &.dragdrop-handle {
            width: 100%;
            height: 26px;
          }
        }
      }
    }
  }

  .SearchStatusMenuPanel {
    &.empty {
      display: inherit;
    }
  }

  .LayoutHeader {
    td {
      .layout-dropzone {
        min-height: 100px;
      }
    }
  }

  .VerticalColumnsLayout {
    .layout-dropzone {
      min-height: 100px;
    }
  }

  .TwoColumnHeaderFooter {
    tbody {
      tr {
        &:not(.LayoutHeader) {
          td {
            .layout-dropzone {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .MBHoverChangeButton {
    &.MBButton {
      &.flexRow {
        &.x-tool-gear {
          animation: fade-in 0.2s normal;
        }
      }
    }
  }

  .Tabhidden {
    opacity: 0.6;
  }
}

body {
  &:not(.admin-mode) {
    .d-none-no-admin-mode {
      display: none !important;
    }
  }

  &.admin-mode {
    .d-none-admin-mode {
      display: none !important;
    }
  }
}

.nologin {
  .card,
  .card-header,
  .card-footer,
  .card-body {
    border: none;
  }

  .card-content-holder-widget {
    background-color: transparent;
  }
}
