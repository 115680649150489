// stylelint-disable selector-no-qualifying-type
//
// Button Groups
//
.btn-group-spacing {
  .btn {
    + .btn {
      margin-left: 0.25rem;
    }
  }
}

.btn-group {
  > .btn {
    &.item-fill {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.btn-group-nested {
  > *:first-child .btn {
    margin-left: 0;
  }

  // Reset rounded corners
  > *:not(:last-child):not(.dropdown-toggle) .btn {
    @include border-right-radius(0);
  }

  > *:not(:first-child) .btn {
    @include border-left-radius(0);
  }
}

.btn-group-badges {
  &.btn-group-vertical {
    .btn-icon {
      width: 38px;
    }
  }
}
