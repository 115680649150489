// stylelint-disable declaration-no-important
//
// Animations
//
.willChange {
  will-change: transform;
}

.resize-will-change {
  &:hover,
  &:active {
    transform: translateZ(0);
    will-change: left, top, width, height;
    backface-visibility: hidden;
  }
}

.backface-hidden {
  backface-visibility: hidden;
}

.opacityAnimate {
  transition: opacity 0.25s ease-in-out;
}

.default-transition {
  transition: $default-transition;
}

.opacity-50 {
  transition: all 0.3s ease-out;
  opacity: 0.5;
  background-color: $black;
}

.transition-height {
  transition: all 0.2s ease-in;
}

.transition-none {
  transition: none !important;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@if $enable-prefers-reduced-motion-media-query {
  @media (prefers-reduced-motion: reduce) {
    .animated {
      animation: unset !important;
      transition: none !important;
    }
  }
}

//@media (prefers-reduced-motion) {
//  .animated {
//    animation: unset !important;
//    transition: none !important;
//  }
//}
.notification-animation {
  animation-name: notification-read;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated-fast {
  animation-duration: 0.3s;
}

.slideInUp {
  animation-name: slideInUp;
}

.attention {
  animation: attentionAnimation 0.2s ease-out;
}

.popIn {
  animation: popInAnimation 0.2s ease-out;
}

.pulse {
  animation: pulsate 2s ease-out;
  opacity: 1;
}

.successfulAdd {
  cursor: default !important;

  .material-icon {
    -webkit-animation: addedSuccessfullyAnimation 0.25s linear;
    animation: addedSuccessfullyAnimation 0.25s linear;
    color: $md-green-a700 !important;
  }
}

.deletion {
  animation: deleteAnimation 0.2s ease-out;
  opacity: 0;
}

.copyNotification {
  position: absolute;
  top: 3px;
  right: 40px;
  animation: copyfade 3s;
  pointer-events: none;
  border: 5px solid $color_quill_gray_approx;
  background-color: $color_quill_gray_approx;
}

.flash {
  animation: flashFrames 0.5s forwards ease-in-out normal;
  animation-iteration-count: 2;
}

.zoomIn {
  animation-name: zoomIn;
}

.spin {
  animation: spin 550ms linear infinite;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes addedSuccessfullyAnimation {
  0% {
    color: $color_alto_approx;
  }
  50% {
    transform: scale(1.1);
  }
  to {
    color: $md-green-a700;
    transform: scale(1);
  }
}

@keyframes pulsate {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  to {
    background-color: transparent;
    opacity: 1;
  }
}

@keyframes attentionAnimation {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes popInAnimation {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

@keyframes deleteAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes pop-in {
  0% {
    transform: scale(0.1) translateX(100%);
  }
  65% {
    transform: scale(1.2) translateX(-15%);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes notification-read {
  0% {
    background-color: $component-active-bg-light;
  }
  to {
    background-color: $list-group-bg;
  }
}

@keyframes pop-out {
  0% {
    @extend .w-100;
    transform: scale(1);
  }
  to {
    width: 1%;
    transform: scale(0.01);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes copyfade {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes flashFrames {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $color_asparagus_approx;
  }
  to {
    background-color: transparent;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  to {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes delayedShow {
  99% {
    display: none;
  }
  100% {
    display: block;
  }
}

.delayedShow {
  display: none;
  animation: 5s delayedShow;
  animation-fill-mode: forwards;
  backface-visibility: hidden;
}

.delayed-show {
  visibility: hidden;
  animation: 1s delayShow;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  backface-visibility: hidden;
}

@keyframes delayShow {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes fade-out-2 {
  from {
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

.fade-out-2 {
  animation: 250ms fade-out-2;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

.stacked-drag-image-1 {
  z-index: -1;
  animation-name: stacked-drag-image-1;
}

@keyframes stacked-drag-image-1 {
  from {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(25%, 25%, 0);
  }
}

@keyframes stacked-drag-image-2 {
  from {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(50%, 50%, 0);
  }
}

.stacked-drag-image-2 {
  z-index: -2;
  animation-name: stacked-drag-image-2;
}
