//
// LoadingDockStatusWidget Widget
//
.card-loading-dock-status-widget {
  .aside & {
    .input-group-page-count {
      display: none;
    }
  }

  .loading-dock-asset {
    max-width: 80px;
  }
}
