// stylelint-disable declaration-no-important
// Width and height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

$square-sizes: 20, 22, 25, 30, 35, 40, 45, 50, 70, 80, 120, 150;
@each $size in $square-sizes {
  .square-#{$size} {
    @include size(#{$size}px);
  }
}

.userIcon {
  @include size(48px);
  border-radius: 3px;
  background-size: cover;
}

.h-inherit {
  height: inherit !important;
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .h#{$infix}-100 {
      height: 100% !important;
      min-height: 0;
    }
  }
}

.min-height-0 {
  min-height: 0;
}

.mw-150-px {
  max-width: 125px;
}

.mw-80-px {
  max-width: 80px;
}

.mh-80-px {
  max-height: 80px;
}

.mw-90 {
  max-width: 90% !important;
}

.h-90 {
  height: 90% !important;
}

.mh-200 {
  max-height: 200px !important;
}

.mh-300 {
  max-height: 300px !important;
}

.mh-500 {
  max-height: 500px !important;
}

.min-h-1 {
  min-height: 1px !important;
}

.min-h-400 {
  min-height: 400px;
}

.min-w-290 {
  min-width: 290px;
}

.mh-800 {
  max-height: 800px;
}

.mx-h-200 {
  max-height: 200px;
}

.h-100-px {
  height: 100px;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-100-px {
  min-width: 100px;
}

.min-h-100 {
  min-height: 100% !important;
}

.h-fit-context {
  height: fit-content;
}
