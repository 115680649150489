// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$enable-deprecation-messages: false;
//
// Color system
//
// stylelint-disable
$white: $white;
$gray-100: $md-gray-100;
$gray-200: $md-gray-200;
$gray-300: $md-gray-300;
$gray-400: $md-gray-400;
$gray-500: $md-gray-500;
$gray-600: $md-gray-600;
$gray-700: $md-gray-700;
$gray-800: $md-gray-800;
$gray-900: $md-gray-900;
$black: $black;
$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);
$blue: $mb-2018-blue;
$indigo: $md-indigo-500;
$purple: $md-purple-500;
$pink: $md-pink-500;
$red: $md-red-600;
$orange: $md-orange-500;
$yellow: $md-yellow-500;
$green: $md-green-500;
$teal: $md-teal-500;
$cyan: $md-cyan-500;
//$purple: #5856d6;
//$pink: #ff2d55;
//$red: #ff3b30;
//$orange: #ff9502;
//$yellow: #ffcc00;
//$green: #4bd963;
//$teal: #5ac8fa;
$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);
$primary: $blue;
$secondary: $gray-300;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);
$alert-colors: ();
$alert-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $gray-200,
    "success": $success,
    "danger": $danger,
    "warning": $warning,
  ),
  $alert-colors
);
//******************************************************
// START MEDIABEACON
//******************************************************
$font-family-material: "Material Icons";
// Defaults
$_mat-elevation-prefix: "wf-";
$btn-pseudo-size-lg: 48px;
$btn-pseudo-size-sm: 31px;
$btn-pseudo-size: 38px;
$default-active-bg: rgba($black, 0.15);
$default-body-bg: #e9ebee;
$default-border-color: lighten($md-gray-400, 5%);
$default-border: 1px solid $default-border-color;
$default-font-size-sm: 0.925rem;
$default-hover-bg: rgba($black, 0.09);
$default-icon-font-size-lg: 1.5rem;
$default-icon-font-size-sm: ($spacer * 1.25);
$default-icon-font-size-xl: ($spacer * 1.75);
$default-icon-font-size: ($spacer * 1.375);
$default-margin: 2px;
$pseudo-before-content: " ";
$component-active-border-light: transparentize(theme-color("primary"), 0.95);
$component-active-bg-light: transparentize(theme-color("primary"), 0.9);
$drag-and-drop-bg: #e8f0fe;
$bg-red-default: #f00;
$modal-full: 100%;
$modal-xl: 1000px;
$modal-xxl: 1200px;
$zindex-infinite-scroll-gradient: 1;
$zindex-infinite-scroll-badges: $zindex-infinite-scroll-gradient + 1;
$col-aside-width-lg: 300px;
$col-aside-width-xl: 350px;
$disabled-opacity: 0.65;
$font-weight-500: 500;
$line-height-xs: 1.3;
$modal-min-width: 350px;
$modal-med: 600px;
$default-text-shadow: 0 2px 2px $md-black-dark;
$default-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
$wf-dropdown-menu: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
$wf-default: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
$whiteframe-3d: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
$whiteframe-3d-extended: $whiteframe-3d, 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
$media-gradient: 45deg, $md-gray-300 25%, transparent 25%, transparent 75%, $md-gray-300 75%, $md-gray-300;
$media-gradient-dark: 45deg, $md-gray-600 25%, transparent 25%, transparent 75%, $md-gray-600 75%, $md-gray-600;
// Loading Mask
$loading-mask-backdrop-bg: $white;
$loading-mask-backdrop-opacity: 0.7;
$zindex-loading-mask-backdrop: 100;
// Animations
$mat-linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1);
$mat-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$mat-fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1);
$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1);
$swift-ease-out-duration: 300ms;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function;
$swift-ease-in-duration: 300ms;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function;
$swift-ease-in-out-duration: 500ms;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
$swift-linear-duration: 80ms;
$swift-linear-timing-function: linear;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function;
//******************************************************
// END MEDIABEACON
//******************************************************
// Set a specific jump point for requesting color jumps
$theme-color-interval: 9%;
// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white !default;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: false;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: false;
$enable-deprecation-messages: true !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.25,
  ),
  5: (
    $spacer * 1.5,
  ),
  6: (
    $spacer * 2,
  ),
  7: (
    $spacer * 3,
  ),
);
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
) !default;
// Body
//
// Settings for the `<body>` element.
//$body-bg:                   $default-body-bg;
$body-bg: $default-body-bg;
$body-color: $gray-900;
// Links
//
// Style anchor elements.
$link-color: theme-color("primary");
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline !default;
// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 1rem !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1450px,
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 16px;
// Components
//
// Define common padding and border radius sizes and more.
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$border-width: 1px !default;
$border-color: $default-border-color;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$component-active-color: $white !default;
$component-active-bg: theme-color("primary");
$caret-width: 0.3em !default;
$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
// Fonts
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$display1-size: 5rem;
$display2-size: 4rem;
$display3-size: 3rem;
$display4-size: 2rem;
$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;
$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;
$small-font-size: 80% !default;
$text-muted: $gray-600;
$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25) !default;
$hr-border-color: $border-color;
$hr-border-width: $border-width !default;
$mark-padding: 0;
$dt-font-weight: $font-weight-bold !default;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;
$list-inline-padding: 0.5rem !default;
$mark-bg: $md-yellow-a200;
$hr-margin-y: $spacer !default;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;
$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color;
$table-head-bg: $gray-200;
$table-head-color: $gray-700;
$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg;
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba(theme-color("primary"), 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-border-width: $border-width !default;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-line-height: $input-btn-line-height !default;
$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;
$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;
$btn-border-width: $input-btn-border-width !default;
$btn-font-weight: $font-weight-500;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;
$btn-link-disabled-color: $gray-600;
$btn-block-spacing-y: 0.5rem !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
// Forms
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;
$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;
$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;
$input-bg: $white !default;
$input-disabled-bg: lighten($body-bg, 5%);
//
$input-color: $body-color;
$input-border-color: $border-color;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;
$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-focus-bg: $input-bg !default;
$input-focus-border-color: theme-color("primary");
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;
$input-placeholder-color: $gray-600;
$input-height-border: $input-btn-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$form-text-margin-top: 0.25rem !default;
$form-check-margin-bottom: 0.5rem !default;
$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.25rem !default;
$form-check-input-margin-x: 0.25rem !default;
$form-check-inline-margin-x: 0.75rem !default;
$form-group-margin-bottom: 1rem !default;
$input-group-addon-color: $input-color;
$input-group-addon-bg: lighten($border-color, 15%);
$input-group-addon-border-color: $input-border-color;
$custom-control-gutter: 1.5rem !default;
$custom-control-spacer-y: 0.25rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $gray-300;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-description-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: theme-color("primary");
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;
$custom-control-indicator-active-color: $white !default;
$custom-control-indicator-active-bg: lighten(theme-color("primary"), 35%);
$custom-control-indicator-active-box-shadow: none !default;
$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-checkbox-indicator-indeterminate-bg: theme-color("primary");
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-select-padding-y: 0.375rem !default;
$custom-select-padding-x: 0.75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075),
  0 0 5px rgba($custom-select-focus-border-color, 0.5);
$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;
$custom-range-track-width: 100% !default;
$custom-range-track-height: 0.25rem;
$custom-range-track-cursor: pointer !default;
$custom-range-track-bg: rgba($black, 0.26);
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;
$custom-range-thumb-width: 1rem !default;
$custom-range-thumb-height: $custom-range-thumb-width !default;
$custom-range-thumb-bg: $component-active-bg !default;
$custom-range-thumb-border: 0 !default;
$custom-range-thumb-border-radius: 1rem !default;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$custom-file-height: $input-height !default;
$custom-file-width: 14rem !default;
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.2rem theme-color("primary");
$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
  placeholder: (
    en: "Choose file...",
  ),
  button-label: (
    en: "Browse",
  ),
) !default;
// Form validation
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;
// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-min-width: 13rem;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: rgba($black, 0.15);
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-200;
//
$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: darken($dropdown-link-hover-bg, 5%);
$dropdown-link-disabled-color: $gray-600;
$dropdown-item-padding-y: 0.25rem !default;
$dropdown-item-padding-x: 1.5rem !default;
$dropdown-header-color: $gray-600;
// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
// Navs
$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600;
$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;
// Navbar
$navbar-padding-y: ($spacer / 2) !default;
$navbar-padding-x: $spacer !default;
$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;
$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-dark-color: rgba($white, 0.9);
$navbar-dark-hover-color: rgba($white, 0.95);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.5);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.8);
$navbar-light-active-color: rgba($black, 1);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;
// Pagination
$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;
$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300;
$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;
$pagination-active-color: $white !default;
$pagination-active-bg: theme-color("primary");
$pagination-active-border-color: theme-color("primary");
$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300;
// Jumbotron
$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-200;
// Cards
$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.15);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-bg: $white !default;
$card-img-overlay-padding: 1.25rem !default;
$card-group-margin: ($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;
$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;
// Tooltips
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: rgba($black, 0.85);
$tooltip-opacity: 1;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;
$tooltip-arrow-width: 0.4rem !default;
$tooltip-arrow-height: $tooltip-arrow-width !default;
$tooltip-arrow-color: $tooltip-bg !default;
// Popovers
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;
$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;
$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;
$popover-arrow-width: 0.8rem !default;
$popover-arrow-height: 0.4rem !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;
// Badges
$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.4em !default;
$badge-border-radius: $border-radius !default;
$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;
// Modals
// Padding applied to the modal body
$modal-inner-padding: 1rem !default;
$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;
$modal-title-line-height: $line-height-base !default;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;
$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.6;
$modal-header-border-color: darken($md-gray-300, 5%);
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1rem !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 400px;
$modal-transition: transform 0.3s ease-out !default;
// Alerts
//
// Define alert colors, border radius, and padding.
$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;
$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: -10;
// Progress bars
$progress-height: 1rem !default;
$progress-font-size: ($font-size-base * 0.75) !default;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color("primary");
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;
// List group
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;
$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;
$list-group-hover-bg: darken($gray-100, 2%);
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;
$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;
//
$list-group-action-color: $body-color;
$list-group-action-hover-color: $list-group-action-color;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;
// Image thumbnails
$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;
// Figures
$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600;
// Breadcrumbs
$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: "/" !default;
// Carousel
$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;
$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;
$carousel-control-icon-width: 20px !default;
$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$carousel-transition: transform 0.6s ease !default;
// Close
$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;
// Code
$code-font-size: 90% !default;
$code-padding-y: 0.2rem !default;
$code-padding-x: 0.4rem !default;
$code-color: #bd4147 !default;
$code-bg: $gray-100;
$kbd-color: $white !default;
$kbd-bg: $gray-900;
$pre-color: $gray-900;
$pre-scrollable-max-height: 340px !default;
