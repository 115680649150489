//
// Layout
//
html,
body,
.skeleton {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
}

body {
  position: relative;
  @extend .d-flex;
  @extend .flex-column;
  overflow-x: hidden;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.MainPage,
.skeleton {
  min-height: 100vh;
}

// Random
.ext-shim,
.x-drag-overlay {
  @extend .d-none;
}

// Skeleton
// Initial body class of GwtMain.html. It is removed when PerspectiveTabBody has loaded.
.skeleton {
  > .MainPage {
    pointer-events: none;
  }

  .MainPage,
  .banner-overall,
  .MBPerspectivesTabPanel {
    opacity: 0;
  }
}

// View-transition
// Added to body when changing perspectives. This adds min heights to the navbar's so they don't look empty on first
.skeleton,
.view-transition {
  .navbar-primary,
  .navbar-subnav {
    min-height: 55px;
  }
}

.selection-palette {
  overflow: hidden;
  height: 54px !important;

  .selection-palette-count {
    font-size: $font-size-sm !important;
  }

  .selection-palette-icon {
    font-size: 1.2rem !important;
  }

  &.selection-hidden {
    height: 0px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

// Add padding between banner menu and perspective tabs
.nav-tabs-workspace {
  padding-top: $spacer;
  // Remove padding when menu is hidden, in no-admin mode.
  .menubar-hidden.no-admin-mode & {
    padding-top: 0;
  }
}

//body,
//.RootPanel,
.gwt-DecoratedTabPanel,
.scroll-panel {
  @include size(100%);
}

.container-fluid-external {
  max-width: 1000px;
}

@include media-breakpoint-up(xl) {
  .aside {
    flex: 0 0 350px;
    max-width: 350px;
  }
}

.skeleton {
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-tabs-workspace {
  > .main-wrapper {
    > .deck-panel {
      &,
      > .deck-panel {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
      }
    }
  }
}

.col-view-widgets {
  overflow: hidden;
}

.mb-toolbar-wrapper {
  position: relative;
  height: 38px;

  .x-masked {
    display: none !important;
  }
}

.LayoutDropPanelCell {
  max-width: 100%;
}

.PagingPanel {
  .card-widget & {
    height: 38px;
  }

  .card-infinite-scroll-view-widget & {
    position: absolute;
    top: 0;
    right: 0;
  }
}
