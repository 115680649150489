//
// C3
//
//
.c3 {
  //margin: 0 auto;
  //text-align: center;
  svg {
    //font: 10px sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  path,
  line {
    fill: none;
    stroke: $md-gray-400;
  }

  path.area {
    fill: none;
  }

  text {
    user-select: none;
  }

  &.donut {
    path {
      stroke: none !important;
    }
  }
}

.treeMapChart .d3plus_data {
  stroke-width: 0 !important;
}

.tick {
  tspan {
    font-size: $font-size-sm;
    fill: $md-gray-800;
  }
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc {
  path {
    stroke: $white;
  }

  text {
    font-size: 0.8em;
    fill: $white;
    //font-size: 13px;
  }
}

.c3-grid {
  text,
  line {
    stroke: $default-border-color;
  }
}

.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}

.c3-text.c3-empty {
  font-size: $font-size-base;
  fill: $md-pink-a400;
}

.c3-line {
  stroke-width: 1px;
}

.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: $white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 0.75;
}

.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

.c3-region {
  fill: theme-color("primary");
  fill-opacity: 0.1;
}

.c3-brush .extent {
  fill-opacity: 0.1;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  opacity: 0.75;
  fill: $white;
  stroke: $md-pink-a400;
  stroke-width: 1;
}

.c3-tooltip-container {
  font-size: $font-size-sm;
  @include reset-text();
  z-index: 10;
  padding: $popover-body-padding-y $popover-body-padding-x;
  word-wrap: break-word;
  color: $popover-body-color;
  @include border-radius($border-radius-lg);
  background-color: $popover-bg;
  background-clip: padding-box;
  box-shadow: 0 8px 10px 1px rgba($black, 0.14), 0 3px 14px 2px rgba($black, 0.12), 0 5px 5px -3px rgba($black, 0.2);
}

.c3-tooltip {
  width: 100%;
  max-width: 100%;
  empty-cells: show;
  //tr {
  //  border: 1px solid #CCC;
  //}
  tr,
  td {
    padding: 0;
  }

  td {
    vertical-align: middle;

    > span {
      display: inline-block;
      @include size(10px);
      margin-right: 0.5rem;
      border-radius: 5rem;
    }

    &.value {
      font-weight: bold;
      padding-left: 6px;
      text-align: right;
    }
  }
}

.c3-area {
  opacity: 0.2;
  stroke-width: 0;
}

.c3-chart-arcs-title {
  font-size: 1em;
  dominant-baseline: middle;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: $md-pink-a400;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  font-size: $font-size-lg;
  fill: $black;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: $md-pink-a400;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: $md-pink-a400;
}

.c3-chart-arc .c3-gauge-value {
  fill: $black;
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

text.c3-chart-arcs-title:hover {
  font-weight: bold;
  cursor: pointer;
}

//.c3 {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//
//  svg {
//    font-size: 9px !important;
//  }
//  .LoginWidget {
//    padding: $spacer;
//
//    > .gwt-Button {
//      float: right;
//    }
//  }
//}
//
//.statPanel {
//  .c3-chart-arcs-title {
//    color: $white;
//    fill: $white;
//  }
//}
//
//.c3-axis-x-label {
//
//  font-weight: $font-weight-bold;
//}
//
//.c3-axis-y-label {
//
//  font-weight: $font-weight-bold;
//}
//
//.c3-chart-arcs .back:hover {
//  font-weight: $font-weight-bold;
//}
//
//.c3-chart-arcs-title.back {
//  cursor: pointer;
//}
//.c3-title-holder .back:hover {
//  font-weight: $font-weight-bold;
//  cursor: pointer;
//}
//
//
//.d3plus_title.title {
//  .back:hover {
//    font-weight: $font-weight-bold;
//    cursor: pointer;
//  }
//}
//
//rect.d3plus_data {
//  cursor: pointer;
//}
//
//.treeMapLegend .c3-legend-item {
//  cursor: auto !important;
//}
//
//
//#d3plus {
//  float: left;
//  #bg {
//    opacity: 0;
//    fill-opacity: 0;
//  }
//  #container #d3plus_viz #data g {
//    rect {
//      opacity: 1;
//      stroke: $white !important;
//    }
//    text {
//      text-transform: lowercase;
//    }
//  }
//  #key text {
//    text-transform: lowercase;
//  }
//}
//
//.d3plus_tooltip_arrow,
//.d3plus_tooltip_data_name {
//  width: 0;
//  opacity: 0;
//  fill-opacity: 0;
//}
//
//.d3plus_tooltip_data_seperator {
//  opacity: 0;
//  fill-opacity: 0;
//}
//
//.d3plus_tooltip_container {
//  display: flex;
//  flex-direction: row;
//  width: auto !important;
//  padding: 0 !important;
//
//  opacity: .9;
//}
//
//.d3plus_tooltip_header {
//  max-height: 20px;
//  padding-bottom: 2px;
//  border-right: 1px dotted $color_mountain_mist_approx;
//  &:before {
//    padding: 0 3px;
//    font-size: $font-size-lg;
//    line-height: 20px;
//    content: "■";
//  }
//}
//
//.d3plus_tooltip_data_container {
//  padding-left: 15px !important;
//  overflow: hidden;
//  .d3plus_tooltip_data_block:first-child {
//    display: none;
//  }
//}
//
//.d3plus_tooltip_data_value {
//  position: relative !important;
//  display: inline-block !important;
//  width: auto !important;
//  font-size: $font-size-sm !important;
//  font-weight: $font-weight-base !important;
//}
//
//#d3plus_tooltip_id_tree_map {
//  margin-top: 10px;
//  margin-left: $spacer;
//}
//
//.d3plus_tooltip_title {
//  width: auto !important;
//  padding: 2px 0;
//  padding-right: 5px !important;
//  font-size: $font-size-sm;
//  font-weight: 400 !important;
//  color: $black !important;
//}
//
//.d3plus_tooltip_data_block {
//  padding: 0 !important;
//}
//
//.d3Fade {
//  opacity: .5 !important;
//}
