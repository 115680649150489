//
// Landing Page
//
.LandingPage,
.nologin {
  .LandingPagePanel {
    .dissolvingImage {
      // position: fixed;
      //z-index: 1;
    }
  }
}

.menubar-hidden {
  &.no-admin-mode {
    .asset-view-media-panel {
      .dissolvingImage {
        position: absolute !important;
      }
    }

    .dissolvingImage {
      position: fixed !important;
    }
  }
}

.LandingPage {
  &:not(.menubar-hidden) {
    &:not(.has-sub-nav) {
      &.no-admin-mode {
        .dissolvingImage {
          position: fixed !important;
          z-index: -1;
          top: 55px;
          left: 0;
        }
      }
    }

    &.has-sub-nav {
      &.no-admin-mode {
        .card-quick-search {
          top: 8rem;
        }

        .dissolvingImage {
          position: fixed !important;
          z-index: -1;
          top: 94px;
          left: 0;
        }
      }
    }
  }
}

.LandingPagePanel {
  .dissolvingImage {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}

.admin-mode {
  .LandingPagePanel {
    height: 250px;

    .dissolvingImage {
      position: absolute;
    }
  }
}

.thumbnailIconButton {
  padding-left: 22px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px 18px;
}

//.btn-thumbnail-cover {
//  transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
//
//  @include hover-focus {
//    transform: scale(.95);
//  }
//}
.LandingPage {
  &.admin-mode {
    .btn-thumbnail-cover {
      min-height: $spacer * 3;
    }
  }

  &.no-admin-mode {
    .btn-thumbnail-cover {
      position: relative;
      min-height: $spacer * 10;
      border: none;
      background-color: transparent;
    }

    //.QuickSearch {
    //  height: 50px;
    //  background-color: transparent;
    //  .quicksearch-button {
    //    &:hover
    //    {
    //      background-color: $md-black-lighter;
    //    }
    //    font-weight: 600;
    //    outline-width: 0;
    //    position: absolute;
    //    right: 5px;
    //    z-index: 5;
    //    height: 40px;
    //    width: 40px;
    //    line-height: 30px;
    //    background-color: transparent;
    //    color: theme-color("primary");
    //    &:before {
    //      width: 50px;
    //      font-size: 36px;
    //      text-align: center;
    //    }
    //  }
    //  .DictionaryBackedAutoCompleteItemizedTextBox {
    //    input{
    //      @extend .wf-10;
    //      width: 300px;
    //      border-top-right-radius: 4px;
    //      border-bottom-right-radius: 4px;
    //      height: 50px;
    //      padding-right: 45px;
    //    }
    //  }
    //  .advancedsearch-button {
    //    width: 50px;
    //    height: auto;
    //    line-height: 50px;
    //  }
    //  > div {
    //    margin: 0;
    //  }
    //  .standard-text-box {
    //    margin-left: 10px;
    //  }
    //}
    //
    //.noIcon {
    //  min-width: 250px;
    //  min-height: 150px;
    //  margin: auto 20px;
    //  font-size: $font-size-lg;
    //  border: 3px solid theme-color("primary");
    //  &.WorkspacePanel {
    //    padding-left: 22px;
    //  }
    //}
    //
    //.thumbnailCoverButton {
    //  min-width: 250px;
    //  min-height: 150px;
    //}
    //
    //.My-Downloads-pn {
    //  display: none;
    //}
    //
    //.QuickSearch-root {
    //  border: none;
    //  margin: 0 auto;
    //  top: 65px;
    //  z-index: 1;
    //  background-color: transparent;
    //  .QuickSearchContent {
    //    background-color: transparent;
    //  }
    //  .BaseWidgetContentPanel:after {
    //    position: absolute;
    //    top: 0;
    //    z-index: -1;
    //    @extend .w-100;
    //    height: 61px;
    //    content: "";
    //    background-color: $black_30;
    //    filter: blur(15px);
    //  }
    //}
    //
    //
    //.WorkspaceWidget-root {
    //  position: absolute;
    //  right: 0;
    //  bottom: 0;
    //  left: 0;
    //
    //  @extend .w-100;
    //  min-height: 250px;
    //  padding: 20px;
    //  margin-bottom: 0;
    //  overflow: hidden;
    //  background: $white_50;
    //}
    //
    //.QuickSearchIcon {
    //  display: inherit;
    //  height: 50px;
    //  margin: 5px;
    //  content: $url_mb_175;
    //  @extend .wf-10;
    //}
    //
    //.QuickSearchContent {
    //  margin: 0;
    //  .DictionaryBackedAutoCompleteItemizedTextBox > .gwt-TextBox {
    //
    //  }
    //}
  }
}
