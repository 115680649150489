//
// Text View Widget
//
.card-text-view-gxt {
  &:not(.card-widget-has-error) {
    .x-grid3-scroller {
      background-color: $white;
    }
  }

  .draggable-source-is-dragging {
    opacity: 1 !important;

    .is-selected {
      opacity: 0.2;
    }
  }

  &.custom-width-enabled {
    width: auto !important;
  }

  .x-grid3-row.is-selected {
    .x-grid3-td-__select__ {
      .x-grid3-col-__select__ {
        .asset-select-badge {
          transform: scale(1.05);
          .material-icon {
            color: $white !important;
            z-index: 1;
          }
        }
      }
    }
  }

  .asset-select-badge {
    color: $md-black-lighter;
    opacity: 1 !important;
  }

  .x-editor {
    .d-flex {
      display: flex !important;
    }
  }
}

.text-white {
  .x-grid3-cell-inner {
    color: $white !important;
  }
}

.cell-editor {
  min-width: 250px;
}

.textViewCheckOutBadge {
  position: absolute;
  left: 0;
}

.textViewCheckbox {
  height: 22px;
  border: none;
  background-color: $white;
}

.TextView-initialdate {
  color: $md-green-a700;
}

.TextView-datehighlight {
  color: $red;
}

.TextView-currentdate {
  color: $blue;
}

.TextView-draghandle {
  background-color: $red;
}

.TextView-limitedRows {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
