//
// Nav's
//
.nav-tabs-primary {
  .nav-link {
    white-space: nowrap;
    border: none;

    &.disabled {
      color: $nav-link-disabled-color;
      border-color: transparent;
      background-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

.mr-25r {
  margin-right: 0.25rem;
}

.nav-tabs {
  &:empty {
    display: none;
  }
}

.system-status-toolbar {
  z-index: 3;
}

.nav-pills-hover {
  .nav-link {
    &:not(.active, .disabled) {
      @include hover-focus {
        background-color: $default-hover-bg;
      }
    }
  }
}

.nav-tabs-advanced-search {
  &.nav-tabs {
    .nav-link {
      font-weight: 500;

      &.active {
        z-index: 1;
        color: theme-color("primary");
      }
    }
  }
}

//
// Nav Tabs Material
//
.nav-tabs-material {
  position: relative;
  z-index: 10;

  &.nav-tabs {
    .nav-item {
      font-weight: $font-weight-500;
      position: relative;
      margin-bottom: 0;
      color: $gray-600;
      border: none;

      &::after {
        position: absolute;
        right: $nav-link-padding-x / 2;
        bottom: 0;
        left: $nav-link-padding-x / 2;
        height: 3px;
        content: "";
      }

      @include hover-focus {
        color: $gray-700;
        outline: none;
        &::after {
          background-color: $default-hover-bg;
        }
      }

      &.active {
        color: $body-color;

        &::after {
          background-color: $nav-pills-link-active-bg;
        }
      }

      &.disabled {
        color: $nav-link-disabled-color;
      }
    }
  }
}

.toolbar-paging {
  display: flex;
  width: 100%;
  padding: $dropdown-padding-y $dropdown-item-padding-x / 2 !important;
  border-top: 1px solid $card-border-color;
  background-color: $card-cap-bg;
  //td {
  //  width: auto !important;
  //}
  &.disabled {
    display: none;
  }
}

.nav-sm {
  .nav-link {
    //padding: $btn-padding-y-sm $btn-padding-x-sm; 0.25rem 0.5rem
    font-size: $font-size-sm;
    line-height: $btn-line-height-sm;
    padding: ($nav-link-padding-y / 1.5) ($nav-link-padding-x / 1.5);
    border-radius: $btn-border-radius-sm;
  }
}

.nav-tabs {
  .nav-link {
    color: $md-gray-800;
  }
}
