// stylelint-disable declaration-no-important
//
// Utilities for common `display` values
//
.d-block-active {
  display: none;

  .active & {
    display: block;
  }
}

.d-block-important {
  display: block !important;
}

.d-none-active {
  display: block;

  .active & {
    display: none;
  }
}

.d-none-empty {
  &:empty {
    display: none;
  }
}

.mr-2-half {
  margin-right: ($spacer * 0.75);
}

.d-flex-important {
  display: flex !important;
}

@include media-breakpoint-up(lg) {
  .d-lg-flex-important {
    display: flex !important;
  }
}

iframe {
  border: 0;
}

.inline,
.inlineItem {
  display: inline;
}

//.YV-PW .x-window-body {
//  overflow: visible;
//}
.dragged .x-tool-gear,
.hiddenActionPanel {
  visibility: hidden;
}

.inlineBlock {
  display: inline-block;
}

.ContentAsset,
.flowpanel-element,
.inlineTable {
  display: inline-table;
}

//d-md-none-down
@include media-breakpoint-down(md) {
  .d-md-none-down {
    display: none !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline;
    }
    .d#{$infix}-inline-block {
      display: inline-block;
    }
    .d#{$infix}-block {
      display: block;
    }
    .d#{$infix}-table {
      display: table;
    }
    .d#{$infix}-table-row {
      display: table-row;
    }
    .d#{$infix}-table-cell {
      display: table-cell;
    }
    .d#{$infix}-flex {
      display: flex;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex;
    }
  }
}

.noDisplayImportant,
.CuePointsUpdatingHide,
.emptyLabel,
.defaultPanel .BulkEdit > .flexColumn,
.empty .menu-save-button,
.empty .btn-home,
.hideMandatorySearches .MandatorySearch,
.Metrics .rightContainer,
.hiddenMBSelectionMenu,
.x-hide,
.d-none-default {
  display: none;
}

.x-tip,
.HidePlus,
.hidden {
  display: none !important;
}

//
// Utilities for toggling `display` in print
//
//.d-print-block {
//  display: none !important;
//
//  @media print {
//    display: block !important;
//  }
//}
//
//.d-print-inline {
//  display: none !important;
//
//  @media print {
//    display: inline !important;
//  }
//}
//
//.d-print-inline-block {
//  display: none !important;
//
//  @media print {
//    display: inline-block !important;
//  }
//}
//
//.d-print-none {
//  @media print {
//    display: none !important;
//  }
//}
