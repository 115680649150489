//
// Infinite Scroll Widget
//
.InfiniteScrollViewWidget {
  contain: strict;

  .scroll-container {
    min-height: 100%;
  }

  //.scroll-container,
  //.photo-wall {
  //  contain: layout;
  //  backface-visibility: hidden;
  //
  //}
}

.card-infinite-scroll-view-widget {
  .x-grid3-scroller {
    display: none;
  }

  &.is-open {
    &:not(.minimized) {
      flex: 1 1 auto !important;
    }
  }

  .card-sub-header {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .PagingPanel {
    .LeftSide {
      display: none;
    }

    .RightSide {
      z-index: 1;
      top: 0;
      right: 0;

      .DisplayingLabel {
        display: none;
      }
    }
  }

  .x-grid3-td-__select__ {
    width: 0 !important;
  }

  .loading {
    line-height: 50px;
    position: absolute;
    z-index: 20;
    right: 18px;
    bottom: 1px;
    left: 3px;
    height: 50px;
    text-align: center;
    color: $white;
    background: $black_40;
  }

  .PagingPanel {
    height: 0;
    min-height: 0;
    border-width: 0;
  }
}

.ScratchBasketItem {
  img {
    user-select: none;
  }
}

.img-asset {
  z-index: 0;
}

.infinite-scroll-container {
  &,
  .photo-wall-item-container,
  .embed-responsive-item {
    //contain: paint;
    //backface-visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &:not(.dragdrop-draggable) {
    .img-asset {
      user-select: none;
      pointer-events: none;
    }
  }
}

.infinite-scroll-item {
  @extend .photo-wall-item;
  min-height: 1px;
  background-color: darken($body-bg, 5%);
  contain: layout paint;
  backface-visibility: hidden;

  .btn-primary {
    position: absolute;
    z-index: 10;
  }

  img {
    &.lazyerror {
      text-indent: -10000px;

      &::before {
        display: none;
      }

      &::after {
        text-indent: 0;
      }
    }
  }

  .band-select-is-dragging & {
    will-change: background-color, color;

    .photo-wall-item-container,
    .img-asset {
      transition: transform 135ms ease-out !important;
      will-change: transform;
    }
  }

  .photo-wall-item-container {
    @extend .overflow-hidden !optional;
  }

  &.active {
    color: $white;
    background-color: theme-color("primary");

    .bg-hover-gradient {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
    }
  }

  &.tippy-is-open,
  &:hover,
  &:focus,
  &.editing-fields {
    .meta-holder-hover {
      opacity: 1;
    }

    .meta-holder {
      &::after {
        opacity: 1;
      }
    }
  }

  .ratio-box {
    outline: none;
  }
}

.draggable-widget {
  .card-grid-box,
  .infinite-scroll-item {
    &.active {
      pointer-events: none;
    }
  }
}

.infinite-scroll-item-badges {
  .band-select-is-dragging & {
    display: none;
  }
}

.meta-holder {
  z-index: 1;

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    transition: opacity $swift-ease-out-duration;
    @extend .bg-hover-gradient-top;
    transform: translateY(0);
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
  }
}

.meta-holder-hover {
  transition: opacity $swift-ease-out-duration;
  opacity: 0;
}

.card-grid-box {
  .video-timecode {
    transition: opacity $swift-ease-out-duration $swift-ease-out-timing-function;
    opacity: 0;
  }

  &.tippy-is-open,
  &:hover,
  &:focus,
  &.editing-fields {
    .meta-holder,
    .video-timecode {
      opacity: 1;
    }
  }
}

.infinite-scroll-item-badges {
  position: absolute;
  z-index: $zindex-infinite-scroll-badges + 1;
  top: ($spacer / 4);
  right: ($spacer / 4);
  display: flex;
  flex-direction: column;
}

.infinite-scroll-item-fields {
  position: absolute;
  z-index: $zindex-infinite-scroll-badges;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $btn-padding-y $btn-padding-x;

  .standard_font {
    color: $white;
    outline: none;
    text-shadow: 0 1px 3px $black;
  }
}
