//
// Table's
//
.x-small-editor {
  .x-btn-wrap .x-btn-left,
  .x-btn-wrap .x-btn-right,
  .ytb-sep {
    display: none;
  }
}

.table-sm,
.x-grid3 {
  font-size: 90%;
}

.table-fixed {
  table-layout: fixed;
}

.x-grid3-row-selected {
  .x-grid3-row-checker::before {
    color: theme-color("primary") !important;
  }
}

.text-view-table-fix {
  > table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
  }

  table,
  tbody,
  tr,
  th,
  td {
    border-width: 0 !important;
  }

  .table {
    th,
    td {
      border-top-width: 1px !important;
    }
  }
}

.x-grid-table-hover {
  .x-grid3-row {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}

.x-grid-table-delete-hover {
  .x-grid3-row {
    .x-grid3-td-delete {
      .btn {
        pointer-events: none;
        opacity: 0;
      }
    }

    @include hover {
      .x-grid3-td-delete {
        .btn {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
}

.table-sm,
.x-grid3 {
  font-size: 0.9rem;
}

.x-grid3-col {
  &:focus {
    outline: 0;
  }
}

.table-fix {
  width: 100%;
  max-width: 100%;

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: middle;
  }
}

.remove-grid-item {
  .x-grid3,
  .x-grid3-scroller {
    @include size(100% !important);
  }

  .x-grid3-header {
    padding: 0 0.625rem;
  }

  .x-grid3-row {
    width: 100% !important;
    @extend .list-group-item;
    @extend .list-group-item-action;
  }

  .x-grid3-body {
    @extend .list-group;
    @extend .list-group-sm;
  }

  .x-grid3-row-table {
    width: 100% !important;

    tr {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.x-grid-empty {
  width: inherit !important;
}

.table-block {
  table,
  tbody,
  tr,
  td {
    display: block;
    width: 100%;
    padding: 0;
  }
}

.table-flex-row {
  &,
  table,
  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: $table-border-width solid $default-border-color;
  }

  td {
    @extend .col-md;
  }
}

.flex-table {
  &,
  table,
  tbody {
    display: flex;
    flex-direction: column;
  }
}

.table-flex-row-sm {
  tr {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border: none !important;
  }
}

.table-no-border {
  th,
  td {
    border: none;
  }
}

.table-flex {
  table,
  tbody,
  tr,
  td {
    display: flex;
    justify-content: flex-end;
  }

  table,
  tbody,
  tr,
  .inner-cell {
    width: 100%;
  }
}

.modal-footer-table-flex {
  > table,
  tbody,
  tr,
  .inner-cell,
  em {
    @extend .d-flex;
    @extend .justify-content-end;
    width: 100% !important;
  }

  td {
  }

  .x-btn {
    td {
      width: 100% !important;
    }
  }

  .x-btn-text {
    @extend .btn-block;
  }

  .inner-cell {
    table > tbody > tr {
      > td {
        & + td:not(.x-btn-center) {
          padding-left: $spacer !important;
        }
      }
    }
  }
}

.modal-footer-table-two-btn {
  table,
  tbody,
  tr,
  td {
    display: flex;
    justify-content: space-between;
  }

  table,
  tbody,
  tr,
  .inner-cell {
    width: 100%;
  }
}

.vertical-panel-table-fix {
  &,
  table,
  tbody,
  tr,
  td {
    display: block;
    width: 100%;
  }
}

.x-panel-header-text {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  @extend .h6;
}

.x-panel-body {
  position: relative;
  //overflow: hidden;
}

.x-panel-bwrap {
  overflow: hidden;
}

.x-grid-table-bordered {
  .x-grid3-row {
    border-top: $table-border-width solid $table-border-color;
  }
}

.XmpFieldEditSummary {
  .x-panel-bwrap {
    display: flex;
  }

  .x-grid3,
  .x-grid3-scroller {
    @include size(auto !important);
  }

  .x-grid-table-bordered .x-grid3-row {
    border-top: none;
  }

  .x-grid3-col-values {
    white-space: normal;
  }

  .x-grid3-col-values > div {
    display: flex;
    flex-wrap: wrap;
  }

  .x-grid-group-hd,
  .x-grid3-row {
    width: 100% !important;
    max-width: 100%;
  }

  .x-grid3-col {
    padding: 0.15rem;
  }

  .x-grid3-td-action {
    font-style: italic;
  }
}

.x-panel-header {
  margin-bottom: 0.5rem;
}

.x-grid-group-hd {
  position: relative;
  display: flex;
  cursor: pointer;
  user-select: none;
  //border-bottom: $default-border;
  div {
    flex: 1 1;
  }
}

.x-grid-group {
  padding: 0.3rem 0;
  border-bottom: $default-border;

  &:last-child {
    border-bottom: none;
  }

  //&:not(.x-grid-group-collapsed) {
  //  @extend .card;
  //}
  .x-grid-group-body {
    flex: 1 1 auto;
    padding-left: $card-spacer-x;
  }

  .x-grid-group-hd {
    padding: 0.25rem 0;
    //margin-bottom: 0; // Removes the default margin-bottom of <hN>
    //border-bottom: $card-border-width solid $card-border-color;
  }
}

.x-grid-group-body {
  .x-grid-group-collapsed & {
    display: none;
  }
}

//
// Tile View Table
//
.x-masked {
  .viewWidget {
    overflow: hidden !important;
  }
}

.table-md {
  th,
  td {
    padding: 0.5rem;
    border: none;
  }
}

.GridHeaderContainer {
  max-width: 100% !important;

  .card-infinite-scroll-view-widget &,
  .card-tile-view & {
    .x-grid3-hd-row {
      .x-grid3-hd-inner {
        width: auto !important;
      }
    }
  }
}

//
// Tile View
//
.table-tile-view {
  width: auto;
  max-width: 100%;
  table-layout: fixed;

  .EditableLabel {
    .clickable {
      font-size: $font-size-sm;
      padding: 0.313rem 0;
    }
  }
}

.x-grid3-row-table {
  .x-grid-cell-first {
    padding: 0.5rem;
  }
}

.WorkflowMetricsPanel {
  .toolbar-wrapper {
    flex: 0 1 auto !important;
  }

  .x-grid3 {
    height: inherit !important;
  }
}

.x-grid3 {
  position: relative;
  overflow: hidden;

  table {
    table-layout: fixed;
  }

  // Styling for Grid Header Horizontal Positioner
  + div {
    border-left: 2px solid $md-blue-a700 !important;
  }
}

.x-editor {
  .x-form-check-wrap,
  .x-form-radio-wrap {
    background-color: $pink;
  }

  //
  //.x-form-checkbox {
  //  height: 13px;
  //}
}

.x-grid3-resize-proxy,
.x-grid3-resize-marker {
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: 1px;
  height: 100px;
  border: 0;
  background-color: $color_tapa_approx;
}

.x-grid3-row {
  width: 100%;
  cursor: default;

  .x-grid3-td-TOP {
    vertical-align: top;
  }

  .x-grid3-td-BOTTOM {
    vertical-align: bottom;
  }
}

.cursor-text {
  .x-grid3-row {
    cursor: text;
  }
}

.x-grid3-starrating-editor {
  position: relative;
  left: 7px;
  margin-top: 4px !important;
  border: none !important;
  background-color: $color_spindle_approx !important;
}

.x-grid3-textview-editor {
  background-color: $color_spindle_approx !important;
}

/*
.x-grid3-row {
  transition: $swift-ease-out;
}*/
.x-grid3-row-selected {
  //background-color: theme-color("primary");
  //color: $white !important;
  //&,
  //.color-inherit {
  //  color: $white !important;
  //}
  //.x-grid3-row-table {
  //  color: $white;
  //  background-color: theme-color("primary");
  //
  //  td {
  //    border-color: $white;
  //  }
  //}
  .x-grid3-row-checker:before {
    content: "check_box";
    color: $white;
  }
}

.x-grid3-col-changePriority {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.mb-header-grid {
  .x-grid3-hd-inner {
    display: flex;
  }

  .x-grid3-td-__select__ {
    .x-grid3-hd-__select__::after {
      display: none !important;
    }
  }

  .x-grid3-td-__plus__ .x-grid3-hd-btn,
  .x-grid3-sort-icon {
    display: none !important;
  }

  .x-grid3-hd-btn {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: none;
    width: 14px;
    cursor: pointer;
  }

  .search-header-impossible + .x-grid3-sort-icon {
    display: none !important;
  }

  .x-grid3-hd-menu-open,
  .x-grid3-hd-over {
    .x-grid3-hd-btn {
      display: block;
      background: none;
    }
  }

  a.x-grid3-hd-btn:hover {
    background-position: 0;
  }
}

.x-grid3-cell-inner {
  //width: auto;
  @extend .text-truncate;

  .BulkEditSummary & {
    width: 100%;
  }
}

// Do not remove or comment, this is needed for text view header alignment!
.x-grid3-hd {
  border-right: 2px solid transparent;

  &.x-grid3-td-__plus__ {
    border-right: 1px solid transparent;
  }
}

.x-grid3-hd-inner {
  font-weight: 500;
  position: relative;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;

  .mb-header-grid & {
    font-size: $font-size-sm;
    padding: $btn-padding-y 0px;
    transition: color 0.15s ease-in-out;
    color: $md-gray-600;
  }

  .mb-header-grid .x-grid3-hd-over & {
    background-color: transparent;
  }

  .sort-asc &,
  .sort-desc &,
  &:hover {
    &,
    &::before,
    &::after {
      color: $body-color;
    }
  }

  .sort-asc &,
  .sort-desc & {
    &::before {
      display: inline-block;
      content: $icon_arrow_downward;
      transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
    }
  }

  .sort-asc & {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.x-grid3-td-middle {
  vertical-align: middle !important;
}

.x-grid3-td-bottom {
  vertical-align: bottom !important;
}

.mb-header-grid {
  .x-grid3-td-__select__ {
    .x-grid3-sort-icon {
      &::after {
        content: "" !important;
      }
    }
  }
}

.mb-header-grid .x-grid3-hd-inner:not(.x-grid3-hd-__plus__) {
  &::after {
    content: $icon_arrow_drop_down;
  }

  &::after,
  &::before {
    font-size: 1.2rem;
  }
}

.x-clear {
  @extend .clearfix;
}

.x-grid3-header {
  table {
    width: 100% !important;
    max-width: 100%;
  }

  .x-grid3-td-checker {
    .x-grid3-hd-inner {
      height: 100%;
      padding: 0 !important;
    }
  }
}

td {
  &.x-date-mp-sep {
    border-right: 1px solid $color_concrete_approx;
  }

  img {
    display: block;
  }

  &.x-grid3-hd-menu-open {
    .x-grid3-hd-inner {
      background: $color_pumice_approx;
    }
  }

  &.x-date-mp-month,
  &.x-date-mp-year {
    a {
      padding: 4px;
      color: $color_fuscous_gray_approx;
    }
  }

  &.x-date-mp-sel {
    a {
      padding: 4px;
      border: none;
      background: $color_concrete_approx;
    }
  }

  &.x-grid3-hd-over {
    .x-grid3-hd-inner {
      background-color: $color_gray_nurse_approx;
    }
  }
}

.x-grid3-header-inner {
  overflow: hidden;
  width: 100% !important;
  max-width: 100%;
}

.x-grid3-dirty-row {
}

.x-grid3-col-delete {
  padding: 0 !important;
}

.x-grid3-col-imageId {
  padding: 0 1px;
}

.x-grid3-col-wrap {
  white-space: normal;
}

.ViewLogPanel {
  .x-grid3-col-description {
    white-space: normal;
  }
}

.x-grid3-col-queryString {
  white-space: normal;
  word-break: break-word;
}

.x-grid3-td-__icon__ {
  cursor: pointer;
}

.x-grid3-hd-menu-only {
  display: block;
}

.x-grid3-col-remove {
  margin: 2px !important;
  padding: 0 !important;
}

.x-grid3-header-offset {
  width: auto;
}

.x-grid3-summary-row td {
  line-height: 16px;
  padding-right: 0;
  padding-left: 0;
}

.x-grid3-td-__plus__ .x-grid3-hd-inner * {
  background: none !important;
}

.unselectable_grid .x-grid3-cell-selected {
  background: inherit !important;
}

.x-grid3-scroll-fix {
  width: 100% !important;
  max-width: 100%;

  .x-grid3,
  .x-grid3-scroller,
  .x-grid3-viewport {
    @include flexScrollFix();
  }

  .x-grid3,
  .x-grid3-row,
  .x-grid3-row-table {
    width: 100% !important;
    max-width: 100%;
  }

  .x-grid3-scroller {
    overflow: auto !important;
  }
}

.x-grid3-scroll-with-toolbar {
  position: relative;

  .x-grid3-scroll-fix {
    $a_local_padding: ($spacer * 1.25);
    padding: $a_local_padding $a_local_padding ($a_local_padding * 3.5);
  }

  .x-grid3-header {
    .x-grid3-hd {
      padding: 0.5rem 0;
    }
  }

  .x-grid3-body {
    background-color: $white;
  }

  .toolbar-paging {
    display: flex !important;
    background-color: $white;
  }
}
