//
// Brightcove
//
.video-js {
  .vjs-tech {
    position: relative !important;
  }
}

.bc-iframe,
.bc-iframe body,
.vjs-poster {
  background-color: $black !important;
}

.vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-top: -50px;
  margin-left: -75px;
  background-color: $md-white-light !important;
}

.BrightcovePlayerPanel {
  left: 0 !important;
  margin: auto;
}

.CuePointMarkerPanel {
  top: 0;
}

.CuePointTimeMarkerTop,
.CuePointTimeMarkerBottom {
  background: grey;
}

.CuePointsEditPanel {
  position: relative;
}

.CuePointTimeMarkerMiddle {
  font-size: 0.875rem;
  position: absolute;
  top: 55px;
  left: -9px;
  overflow: visible;
  width: 16px;
  height: 32px;
  text-align: center;
  color: grey;
  background-color: transparent;
}

.CuePointsUpdatingShow {
  font-weight: bold;
  width: 600px;
  margin: auto;
  text-align: center;
  color: red;
}

.CuePointMarkerAd {
  width: 17px;
  height: 157px;
  background-image: url("../images/cuepoint-ad.png");
}

.CuePointMarkerCode {
  width: 17px;
  height: 157px;
  background-image: url("../images/cuepoint-code.png");
}

.CuePointMarkerPanel {
  position: absolute;
  z-index: 1;
}

.CuePointTimeMarkerPanel {
  position: absolute;
  top: 13px;
  width: 1px;
  height: 157px;
}

.CuePointTimeMarkerTop {
  width: 1px;
  height: 50px;
  margin-left: 8px;
}

.CuePointTimeMarkerMiddle {
  font-size: 13px !important;
  position: absolute;
  top: 57px;
  left: -10px;
  overflow: visible;
  width: 17px;
  height: 30px;
  text-align: center;
  background-color: transparent;
}

.CuePointTimeMarkerBottom {
  position: absolute;
  top: 80px;
  width: 1px;
  height: 50px;
  margin-left: 8px;
}
