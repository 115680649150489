//
// Background
//
//.bg-loading-texture {
//  background-color: rgba($black, .05);
//  background-image:
//          linear-gradient(90deg, $body-bg 0, transparent 0, transparent 266px, $body-bg 266px, $body-bg),
//          linear-gradient(0deg,  $body-bg 0, transparent 0, transparent 200px, $body-bg 200px, $body-bg);
//  background-size: 270px 204px;
//  background-position: 0 0, 0 -4px;
//}
.bg-checkerboard {
  background-color: $white;
  background-image: linear-gradient($media-gradient), linear-gradient($media-gradient);
  background-position: 0 0, $spacer / 2 $spacer / 2;
  background-size: $spacer $spacer;
}

.bg-checkerboard-dark {
  background-color: $md-gray-800;
  background-image: linear-gradient($media-gradient-dark), linear-gradient($media-gradient-dark);
}

.bg-hover-gradient {
  //height: 100%;
  //width: 100%;
  //@include size(100%);
  position: absolute;
  z-index: $zindex-infinite-scroll-gradient;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.135s $mat-fast-out-linear-in-timing-function;
  opacity: 0;
}

.bg-lazy-load {
  .SquaredAsset {
    background-color: rgba($black, 0.1) !important;
  }
}

.bg-hover-gradient-top {
  background-image: linear-gradient(to bottom, $md-black-dark, transparent 56px, transparent);
}

.bg-hover-gradient-bottom {
  background-image: linear-gradient(to top, $md-black-dark, transparent 56px, transparent);
}

.infinite-scroll-item-fields {
  background-image: linear-gradient(to top, $md-black-dark, transparent);
}

.bg-transparent {
  background-color: transparent !important;
}

.overlay-50 {
  //@include gradient-y(transparent, rgba($black, .6));
  //
  padding: 0.5rem;
  text-shadow: 0 0 3px rgba($black, 0.8);
}

//SummaryImage
.bg-white-important {
  background-color: $white !important;
}

.HoverStylePanelHover {
  background-color: $pink;
}

.bg-primary-light {
  background-color: $component-active-bg-light;
}

.bg-loading {
  background-color: rgba($black, 0.1);
}

.bg-gray-50 {
  background-color: $md-gray-50;
}

.bg-gray-100 {
  background-color: $md-gray-100;
}

.bg-gray-200 {
  background-color: $md-gray-200;
}

.bg-gray-800 {
  background-color: $md-gray-800;
}

.bg-gray-900 {
  background-color: darken($md-gray-800, 7%);
}

.bg-gray-dark {
  background-color: $md-gray-900;
}

.bg-body {
  background-color: $body-bg;
}

.bg-black {
  background-color: $black;
}

.bg-ad-cuepoint {
  background-color: $color-ffcc66 !important;
}

.bg-red {
  background-color: $bg-red-default !important;
}

.active-light {
  z-index: 2; // Place active items above their siblings for proper border styling
  border-color: $component-active-border-light;
  background-color: $component-active-bg-light;
}

.bg-white-overlay {
  background-color: $md-black-dark;
}

.teal-background,
.orange-background,
.blue-background,
.purple-background,
.green-background {
  &.tab {
    &::before {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      width: 5px;
      content: "";
    }
  }
}

.teal-background {
  &::before {
    background-color: color("teal");
  }
}

.orange-background {
  &:before {
    background-color: color("orange");
  }
}

.blue-background {
  &:before {
    background-color: color("blue");
  }
}

.purple-background {
  &:before {
    background-color: color("purple");
  }
}

.green-background {
  &:before {
    background-color: color("green");
  }
}

.teal .x-panel-footer {
  background-color: color("teal");
}

.orange .x-panel-footer {
  background-color: color("orange");
}

.MBBannerContainer,
.searchrefine_bg-global-fill,
.blue .x-panel-footer {
  background-color: theme-color("primary");
}

.purple .x-panel-footer {
  background-color: color("purple");
}

.green .x-panel-footer {
  background-color: color("green");
}

.viewport {
  background-color: $pink;
}

.discussion_disabled {
  background-color: $md-gray-100;
}

.public {
  background-color: color("green");
}

.MetadataElementPanel-error {
  background: theme-color("danger");
}

// Positioning
.bg-no-repeat,
.contain {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-contain,
.contain {
  background-size: contain;
}

.bg-pos-center,
.contain {
  background-position: center;
}
