//
// List Groups
//
.list-group {
  &[class^="wf-"],
  &[class*=" wf-"] {
    @include border-radius($list-group-border-radius);

    .list-group-item {
      border-top: $list-group-border-width solid $list-group-border-color;

      &:first-child {
        border-top: none;
      }
    }
  }

  //&:empty:not(.pretty-scroll):not(.pretty-scroll-y) {
  //  display: none;
  //}
}

.list-group-border-0-first-child {
  > .list-group-item {
    &:first-child {
      border: none;
    }
  }
}

.list-group-sm {
  > .list-group-item {
    padding: ($list-group-item-padding-y / 2) ($list-group-item-padding-x / 2);
  }
}

.list-group-md {
  > .list-group-item {
    padding: ($spacer / 2) $spacer;
  }
}

.list-group-item-action {
  cursor: pointer;
  @include transition($btn-transition);

  &.active {
    .material-icon {
      color: $white;
    }
  }

  &.tippy-is-open {
    z-index: 1; // Place hover/active items above their siblings for proper border styling
    text-decoration: none;
    color: $list-group-action-hover-color;
    background-color: $list-group-hover-bg;
  }

  &.is-open {
    color: $list-group-action-active-color !important;
    background-color: $white !important;
  }
}

.list-group-item-search {
  .card-saved-search-widget & {
    padding-right: ($spacer * 2.5);

    &::after {
      right: ($spacer / 2);
      @include absolute-centered-y($default-icon-font-size);
      content: "chevron_right";
      opacity: 0;
    }

    // Hover state
    @include hover-focus {
      &::after {
        opacity: 1;
      }
    }
  }
}

.list-group-item-primary.list-group-item-action {
  color: $body-color;
  background-color: $component-active-bg-light;
  @include hover-focus {
    color: $body-color;
    background-color: $component-active-bg-light;
  }
}

.list-group-dropdown-holder {
  padding: $dropdown-padding-y $dropdown-item-padding-x;

  .list-group-item {
    &.x-grid3-row {
      padding: 0;
    }
  }
}

.list-group-conversion-preset {
  padding: $list-group-item-padding-y / 2.5 $list-group-item-padding-x / 2;

  &:focus {
    z-index: 1;
  }
}

.SearchCriteriaPanel {
  .fields-combobox {
    min-width: 300px;
  }

  //.form-row > [class*="col-"] {
  //  padding: 0;
  //
  //  .input-group {
  //    padding-right: 5px;
  //    padding-left: 5px;
  //  }
  //}
  &:only-child {
    .closeButton-base {
      display: none;
    }
  }

  .PlusButton {
    display: none;
  }

  &:last-child {
    .PlusButton {
      display: block;
    }
  }
}

.list-group-header {
  border-color: transparent;
  background: unset;
}

.list-group-item-header {
  margin-bottom: $list-group-item-padding-y;

  & + .list-group-item {
    @include border-top-radius($list-group-border-radius);
  }
}

.list-group-navigation-list {
  .img-fluid {
    @extend .avatar-sm;
    margin-right: $spacer;
    @extend .rounded;
  }

  .standard_font {
    @extend .text-truncate;
  }
}

.grid-panel-fix {
  &,
  .x-grid3,
  .x-grid3-row,
  .x-grid3-scroller,
  .x-grid3-row-table {
    width: 100% !important;
    height: 100% !important;
  }

  .x-grid3-scroller {
    max-height: 204px;
  }

  .x-grid3-cell:not(.x-grid3-cell-last) {
    width: auto !important;
  }

  .x-grid3 {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid lighten($border-color, 13%);
    @include border-radius($list-group-border-radius);
  }

  .x-grid3-body {
    position: relative;
    z-index: 0;
  }

  .x-grid3-row {
    position: relative;
    display: block;
    // Place the border on the list items and negative margin up for better styling
    margin-bottom: -$list-group-border-width;
    border: $list-group-border-width solid $list-group-border-color;
    background-color: $list-group-bg;
    // padding: $list-group-item-padding-y / 2 $list-group-item-padding-x / 2;
    &:last-child {
      margin-bottom: 0;
      @include border-bottom-radius($list-group-border-radius);
    }

    @include hover-focus {
      text-decoration: none;
      background-color: $list-group-hover-bg;
    }

    &.disabled,
    &:disabled {
      color: $list-group-disabled-color;
      background-color: $list-group-disabled-bg;
    }
  }
}

.list-group-item-danger-icon {
  display: flex;
  align-items: center;

  .material-icon {
    font-size: $default-icon-font-size;
    position: relative;
    z-index: 0;
    color: $md-red-600;

    &::after {
      position: absolute;
      @include size(32px);
      z-index: -1;
      top: 50%;
      left: 50%;
      margin-top: -16px;
      margin-left: -16px;
      content: "";
      background-color: $md-red-50;
      @include border-radius(33px);
    }
  }
}

.list-group-advanced-xmp {
  &:first-child {
    .list-group-advanced-xmp-heading {
      border-top: none !important;
    }
  }

  .btn-delete {
    transition: $swift-ease-out;
    transform: scale(0);
    opacity: 0;
  }

  &:hover {
    .btn-delete {
      transform: scale(1);
      opacity: 0.75;
    }
  }

  .btn-delete {
    @include hover-focus {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:nth-of-type(even) {
    background-color: $table-accent-bg;
  }

  .row {
    border-bottom: $default-border;

    &:last-child {
      padding-bottom: ($spacer * 1.5) !important;
      border-bottom: none;
    }
  }
}

.list-group-advanced-xmp-heading {
  position: relative;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}

.list-group-item-search-refine {
  // Hover state
  @include hover-focus-active {
    .progress {
      background-color: darken($progress-bg, 10%);
    }
  }
}

.list-group-remove-item-list {
  .dragdrop-positioner {
    border: 2px dotted $blue;
    background-color: transparent;
  }

  .dragdrop-selected,
  .dragdrop-dragging,
  .dragdrop-proxy {
    opacity: 1 !important;
  }

  .dragdrop-movable-panel {
    box-shadow: none !important;

    > .list-group-item {
      display: flex !important;
      width: 100%;
      padding: 0.375rem 0.625rem;
      border: 0;
      @include mat-elevation(15);
    }
  }
}
