//
// Video
//
.video-timeline-holder,
.smallAssetDisplay {
  overflow: hidden;
  @include border-radius($border-radius-lg);
  background-color: $md-gray-800;
}

.VideoEditTimeline {
  .modal-asset-view & {
    overflow: hidden;
  }

  .video-clip-handle {
    margin-right: 22px;
    background-color: $black;
  }
}

.VideoClipHolder {
  height: 100%;

  td {
    padding: 0 !important;
  }
}

.VideoEditTimeline {
  position: relative;
  display: block;
  padding: $list-group-item-padding-y $list-group-item-padding-x;
  height: 90px;
}

.video-strip-timeline {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border: 2px solid $md-amber-500;
    border-right: 0;
    border-left: 0;
  }
}

.btn-film-strip-trim {
  position: relative;
  width: 11px;
  height: 100%;
  cursor: move !important;
  background-color: $md-amber-500;

  &::before {
    font-size: $default-icon-font-size;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -($default-icon-font-size / 2);
    margin-left: -($default-icon-font-size / 2);
    content: $icon_drag_handle;
    transform: rotate(90deg);
    color: $black;
  }
}

.VideoTrimOverlay {
  position: absolute;
  top: 0 !important;
  bottom: 0;
  height: 100%;
  background-color: rgba($black, 0.75);
}

.VideoTrimMask {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border: 2px solid $md-gray-800;
  }
}

.video-annotation-marker {
  .user-avatar {
    position: relative;
    z-index: 5;
    transform: translate(-10px, -7px);
    border-width: 2px !important;
    background-color: $md-gray-900;

    .circle,
    .rounded-circle {
      background-color: $white;
    }
  }

  &:hover {
    .user-avatar {
      z-index: 10;
    }
  }
}

.video-clip-holder {
  &.is-empty {
    & + .VideoTimelineSpace {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.VideoScrubber-base {
  z-index: 1;
  top: 6px;
  width: 17px;
  cursor: move;
  backface-visibility: hidden;
  will-change: transform;
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    visibility: hidden;
    opacity: 0;
  }

  .material-icon {
    $local_video_scrubber_base_font_size: 2.5rem;
    font-size: $local_video_scrubber_base_font_size;
    top: -($local_video_scrubber_base_font_size / 2);
    left: 50%;
    margin-left: -($local_video_scrubber_base_font_size / 2);
    color: $bg-red-default;
  }

  .video-line {
    left: 50%;
    width: 2px;
    margin-left: -1px;
    background-color: $bg-red-default;
    box-shadow: 0 10px 10px rgba($black, 0.5);
  }
}

.VideoCommentPanel {
  position: relative;
}

.VideoFrame {
  margin-top: 3px;
  padding-top: 7px;
  padding-right: 7px;
  padding-left: 7px;
}

.VideoTimelineLeft {
  width: 5px;
  height: 25px;
  background: url("../images/VideoTimelineLeft.png") no-repeat;
}

.VideoTimelineScrubberWrapper {
  position: relative;
  cursor: move;
  transition: width 0.1s $swift-ease-out-timing-function;
}

.VideoTimelineScrubber {
  position: absolute;
  right: 0;
  transform: scale(0);

  .material-icon {
    left: -(1.2rem/2);
    transition: opacity $swift-ease-out-duration $swift-ease-out-timing-function;
    opacity: 0;
    color: #f00;
    @include absolute-centered-y(1.2rem);
  }
}

.video-timeline-progress {
  position: relative;
  overflow: visible;
  height: 3px;
  transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
  background-color: rgba(255, 255, 255, 0.5);

  .video-progress-container:hover &,
  &.progress-moving {
    transform: scaleY(2);

    .VideoTimelineScrubber {
      transform: scale(1);
    }

    .material-icon {
      transform: scale3d(1.15, 0.58, 1);
      opacity: 1;
    }
  }
}

.VideoTimelineRight {
  width: 5px;
  height: 25px;
  background-image: url("../images/VideoTimelineRight.png");
}

.VideoFrameInfo {
  padding: 5px;
}

.VideoFrameTime {
  font-style: italic;
  line-height: 60px;
}

.VideoFrameImageWrapper {
  position: relative;
  margin: 5px;
}

.video_annotation_active {
  background-color: $component-active-bg-light;
}

.videoLoadProgress {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  opacity: 0.3;
  background-color: $gray;
}

.VideoFrameFoundText {
  font-weight: $font-weight-bold;
  display: inline;
}

.VideoFrameText {
  display: inline;
}

.VideoPanel.embed-responsive-item {
  position: absolute !important;
}

.VideoPanel {
  .card-grid-box & {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
    @include border-top-radius($card-inner-border-radius);

    &.Playing {
      @extend .object-fit-contain;
    }
  }

  .BrightcovePrePanel {
    left: 20px !important;
    border-left: none !important;
  }
}

.btn-media-play {
  position: relative;
  z-index: 4;
  margin-top: 8px;
  margin-left: 8px;
  color: $white;
  background-color: transparent !important;
  @include hover {
    color: $white_80;
  }
  .material-icon {
    text-shadow: 0 1px 2px rgba($black, 0.87);
  }
}

.media-play-icon {
  position: absolute;
  z-index: 4;
  transform: scale(1.5) translate(10px, 10px);
  pointer-events: none;
  color: $white;
  text-shadow: 0 1px 2px rgba($black, 0.87);
}

.video-expand-button {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
}

.video-timecode {
  font-size: $font-size-sm;
  font-weight: 500;
  line-height: 1;
  position: absolute;
  z-index: 6;
  right: 0;
  bottom: 0;
  padding: $btn-padding-y $badge-padding-x;
  @include border-radius($badge-border-radius);
  text-align: center;
  white-space: nowrap;
  opacity: 0.8;
  background-color: $md-black-lighter;
}

.video-timecode-label {
  margin-top: 0 !important;
  margin-right: 2px;
  margin-bottom: 0 !important;
  margin-left: 2px;
  color: $white !important;
}

.video-expanded {
  position: absolute !important;
  z-index: 11;
}

.Playing {
  .infinite-scroll-item-badges,
  .infinite-scroll-item-fields,
  .bg-hover-gradient {
    opacity: 0 !important;
  }

  .VideoPanel {
    z-index: 5;
  }
}
