//
// Loading Mask
//
$progress-spinner-color-count: 4;
$progress-spinner-duration: 1.25s;
.ext-el-mask {
  position: absolute;
  z-index: $zindex-loading-mask-backdrop;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: $loading-mask-backdrop-opacity;
  background-color: $loading-mask-backdrop-bg;

  body > & {
    opacity: 1;
    background-color: $body-bg;
    //position: fixed;
  }
}

.ext-el-mask-msg {
  z-index: $zindex-loading-mask-backdrop + 1;

  body > & {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -24px;
    margin-left: -24px;
  }
}

.loading-img-container {
  overflow: hidden;
  vertical-align: middle;
}

.loading-img-container,
.loading-img-icon {
  display: block;
  @include size(24px);
}

.loading-img-icon {
  @extend .spin;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAwFBMVEVMaXHi6fnh6fl+qPzi6PiHrfzi6vrk6vrj6/uErf1glP3i6fni6Phsm/3h6flKh//i6fni6fnn7//h6Phglf7i6flakf6Aqvvh6Pjh6Pji6PlZkP5Ri/7h6fl1ovxmmP3j6/vh6fnj6Pp6p/ykwPvh6vpglP51of2buvxRi/96pf3i6flsnP2Cq/1+qf3h6Phnmf6qxPtbkf55pf/i6vri6flAgP9Jhf5Ujf/N2/lKhv9omv65zvqvyPtflP5ek/5tP3KPAAAANXRSTlMAUPCcwFdgMEBw8KC/3O/+0J8g3++w9odw4E/3/M/A5z+AkMd+X+/WSvv5r9uFmm/m6flKj7+nu8IAAAGHSURBVHhexdVZd5swEAXgC5aQsIkBYze1k7TOnu7r4Kzt//9X1Vin4EUgzEu+93s0zIgRXsPid5L8+FP+nE4vv8Dr+tOYjPvSSr9/RRt9RowDlTcxmqiE/nv5W9amEk7BmNbmo0BPgF/x8dGpTaTOQ77R2khhgzyykQH25MTEBDvksGSfseMtsQgOA9cZARmhhlO84kSMDYqbHyo0kJxIJWoJGRqN4nV3UdG2/haD7aJ4vgKthjxzWIjImKCV3OzUjOcFD57ge1jcIgX4j0jrGczhdVrN4qKqyFvTOzBBRAG8jk1gWAU0vOIqUPibymQ1CTLQQWn0C8y7l3TS46MPbGu/wfHVKOD1of4jwq6Xb9X3ekdkqAN+IGRElKDVDU+h/xKA6LJmhqgpbtRYocFdyi2Se6tyrOGkP9qCui7jiJ5cC39ELFGuZ+n5sbwFc5xB+VZE5cQeruCgQxsp8sVSAWq5yAuitndACXISCk10RnsyjTbRLKQN4SyCV3AuREaUCXGu8Qr+AS5oXZexWkN2AAAAAElFTkSuQmCC");
  background-size: 24px 24px;
  will-change: transform;
  backface-visibility: hidden;
}

.show-loading-indicator,
.empty-loading:empty {
  overflow: hidden !important;

  &::after {
    @include size(24px);
    display: flex;
    margin: $spacer auto;
    content: "";
    @extend .spin;
    @extend .loading-img-icon;
    pointer-events: none;
    backface-visibility: hidden;
  }

  &.loading-position-absolute {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }

  &.loading-position-inline {
    &::after {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }
}

.comment-spinner.empty-loading:empty {
  &::after {
    margin: 0;
  }
}

.progress-spinner {
  @include size($spacer * 3);
}

.progress-spinner-sm {
  @include size($spacer * 2);
}

.progress-spinner-sm-target {
  .progress-spinner {
    @include size($spacer * 2);
  }
}

.progress-spinner-circle {
  animation: progress-spinner-dash $progress-spinner-duration ease-in-out infinite,
    progress-spinner-color ($progress-spinner-duration * $progress-spinner-color-count) ease-in-out infinite;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

@keyframes progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes progress-spinner-color {
  100%,
  0% {
    stroke: $md-indigo-a400;
  }
  40% {
    stroke: $md-light-blue-a400;
  }
  66% {
    stroke: $md-green-a400;
  }
  80%,
  90% {
    stroke: $md-orange-a400;
  }
}

//
//.shimmer-group {
//  &:first-child {
//    .shimmer-text {
//      max-width: 38%;
//    }
//  }
//
//  &:last-child {
//    margin-bottom: 0;
//  }
//}
//
//.shimmer {
//  @extend .not-selectable !optional;
//  @include gradient-x-three-colors($md-black-lighter, $white, 50%, $md-black-lighter);
//  background-size: 100rem 100%;
//  animation-name: shimmer;
//  animation-duration: 2s;
//  animation-iteration-count: infinite;
//  background-position: 0 0;
//}
//
//.shimmer-text,
//.shimmer-input {
//  display: block;
//  &::before {
//    content: $pseudo-before-content;
//  }
//}
//
//
//.shimmer-text {
//  display: block;
//  max-width: 47%;
//  margin-bottom: .5rem;
//  height: 1rem;
//}
//
//.shimmer-input {
//  background-clip: padding-box;
//  border-radius: $input-border-radius;
//}
//
//@keyframes shimmer {
//  0% {
//    background-position: top right;
//  }
//  100% {
//    background-position: top left;
//  }
//}
