//
// Cart Widget
//
.hide-cart-tabs {
  .flex-shrink-none {
    display: flex;
    padding: 0.25rem 0.25rem 0;
  }

  .card-body {
    position: relative;
  }

  .nav-tabs-scratch-basket {
    display: none;
  }

  &:not(.has-scratch-basket-btns) {
    .scratch-basket-holder {
      .badge {
        position: absolute;
        top: 10px;
        right: ($spacer * 3);
      }
    }
  }
}

.btn-scratch-basket-options {
  position: absolute;
  top: 0.3rem;
  right: ($spacer / 2);

  .hide-cart-tabs & {
    position: relative;
    top: inherit;
    right: inherit;
    margin-left: auto;
  }
}

.nav-tabs-scratch-basket {
  padding: ($spacer / 2) ($spacer / 2) 0 ($spacer / 2);

  .nav-item {
    &:only-child {
      .closeButton-base {
        display: none;
      }
    }
  }

  .has-control-tab & {
    padding-right: 3rem;
  }

  .active {
    .badge {
      color: $white;
      background-color: theme-color("primary");
    }
  }

  .btn-sm {
    margin-left: ($spacer / 4);
    @include size($spacer * 1.3);

    .material-icon {
      transform: scale(1.2);
    }
  }
}

.card-scratch-basket {
  &.DropZone-dragging {
    .card-footer-scratch-basket {
      position: relative;
      transform: none;
      opacity: 1;
    }
  }
}

.card-footer-scratch-basket {
  position: absolute;
  z-index: -1;
  width: 100%;
  transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
  transform: translateY(-100%);
  opacity: 0;

  &.drop-zone-valid {
    background-color: $drag-and-drop-bg;
  }
}

.has-items {
  > .material-icon {
    color: #4285f4;
  }
}

.scratchCustomIcon {
  background-position: center;
  background-size: 22px 22px;
}

.scratchCustomIconText {
  padding-left: 25px !important;
}
