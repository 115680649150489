//
// CodeMirror Imports
//

//
@import "~codemirror/lib/codemirror";
@import "~codemirror/addon/dialog/dialog";
@import "~codemirror/addon/fold/foldgutter";
@import "~codemirror/addon/lint/lint";
@import "~codemirror/addon/hint/show-hint";
@import "~codemirror/theme/material-darker";

.OrganizerDetails {
  .CodeMirror {
    height: 700px;
  }
}

.CodeMirror {
  height: auto;
  color: $body-color;
  background-color: $white;
  -moz-osx-font-smoothing: auto;

  &,
  pre,
  code,
  kbd,
  samp {
    font-family: $font-family-monospace !important;
    font-size: ($spacer * 0.75) !important;
  }
}

.CodeMirror-foldmarker {
  color: $md-gray-700;
  text-shadow: none;
  @extend .text-hide;

  &:after {
    font: initial;
    font-weight: bold;
    position: relative;
    top: 2px;
    content: "…";
    color: rgba($black, 0.5);
  }
}

.CodeMirror-gutters {
  border: none;
  background-color: transparent;

  .CodeMirror-focused & {
    background-color: $card-cap-bg;
  }
}

//.CodeMirror-linenumbers {
//  width: 50px;
//}
.CodeMirror-linenumber {
  color: rgba(27, 31, 35, 0.3);
  //padding: 0 10px 0 10px;
}

.cm-s-default {
  .CodeMirror-guttermarker-subtle {
    font-size: 14px;
    color: $md-dark-icons-default;
  }

  .cm-variable-2 {
    color: #e36209;
  }

  .cm-variable-3,
  .cm-qualifier {
    color: #6f42c1;
  }

  .cm-tag,
  .cm-variable-3,
  .cm-type {
    color: #22863a !important;
  }

  .cm-comment {
    color: #6a737d;
  }

  .cm-string {
    color: #032f62;
  }

  .cm-def {
    color: #24292e;
  }

  .cm-keyword {
    color: #d73a49;
  }

  .cm-meta,
  .cm-number,
  .cm-atom,
  .cm-variable,
  .cm-punctuation,
  .cm-property,
  .cm-operator {
    color: #005cc5;
  }
}

.CodeMirror-lint-tooltip {
  font-family: $font-family-monospace !important;
  font-size: ($spacer * 0.75) !important;
  line-height: 1.4;
  z-index: 9999;
  top: 0;
  left: 0;
  visibility: visible;
  min-width: $dropdown-min-width;
  max-width: calc(100% - 10px);
  padding: $dropdown-padding-y ($dropdown-padding-y * 1.5);
  transition-duration: 0ms;
  text-align: left;
  pointer-events: none;
  color: $body-color;
  border-color: $md-red-500;
  outline: 0;
  background-color: $white;
  box-shadow: 0 8px 10px 1px rgba($black, 0.14), 0 3px 14px 2px rgba($black, 0.12), 0 5px 5px -3px rgba($black, 0.2);
  perspective: 700px;
  @include border-radius($dropdown-border-radius);
  will-change: top, left;
}

.cm-s-material-darker.CodeMirror {
  background-color: #212121 !important;
}
