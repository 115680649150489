.ext-ie11 {
  svg {
    // Workaround for the SVG overflow bug in IE10/11 is still required.
    // See https://github.com/twbs/bootstrap/issues/26878
    overflow: hidden;
  }

  .stat-graph {
    height: 300px;
  }

  .slide-image-container .button-holder {
    left: 0px;
  }

  .asset-select-background {
    top: 10px;
    left: 10px;
  }

  .MainPage {
    .selection-palette.justify-content-center {
      justify-content: flex-start !important;
    }
  }

  .form-control {
    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      border: 0;
      background-color: transparent;
    }
  }

  .custom-select {
    // Hides the default caret in IE11
    &::-ms-expand {
      display: none;
    }
  }

  .btn-group-3d {
    top: $spacer;
    left: 50%;
    min-width: auto;
    margin-top: 0;
    transform: translateX(-50%);
  }

  .modal-asset-view {
    .ThreeDimensionalPanel {
      &,
      .ThreeDimensionalPanelImage {
        position: relative !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .ui-button-bar-with-error-message.edited {
      position: absolute !important;
    }
  }

  .selection-menu-label {
    margin-left: 0.5rem !important;
  }

  .gwt-TreeItem {
    display: flex;
    flex-direction: column;
  }

  .btn-tree-trigger::before {
    position: relative !important;
    top: -2px !important;
  }

  .mb-dropdown-menu {
    .tippy-tooltip {
      transform: none !important;
      backface-visibility: hidden;
    }
  }

  .mb-checkbox {
    &.dropdown-item {
      padding: 0.375rem 1.5rem 0.375rem 3.875rem !important;
    }
  }

  .SystemStatusDisplayDialog {
    .flex-fill {
      flex-basis: 0 !important;
    }
  }

  .magazine-viewport {
    overflow: visible !important;
    align-items: flex-start;

    .magazine.animated {
      top: 0;
    }
  }

  .magazine-thumbnail-list {
    .i {
      div {
        display: flex;
      }
    }
  }

  .resize-slider {
    width: 200px !important;
  }

  .imageWrapper {
    img {
      width: 100%;
    }
  }

  .card-widget {
    flex-shrink: 0;

    &.minimized {
      min-height: 47px;
    }
  }

  .ACLMenu {
    .media-body {
      flex-basis: auto !important;
    }
  }

  .TextBoxItem {
    &:after {
      position: relative;
      top: 1px;
    }
  }

  .banner-overall {
    .TextBoxItem {
      &:after {
        top: 1px;
      }
    }
  }

  .ConfigPanel {
    .solr-options-panel {
      .form-control {
        flex-basis: 200px;
        min-width: 200px;
      }
    }
  }

  .CommentBoardInnerWrapper {
    .CommentLabel {
      flex: 0 1 auto;
      max-width: 100%;
      overflow-x: hidden;
      .discussion-entry {
        width: 100%;
      }
    }
    .CommentPanel.media-comment-session-user {
      .media-body {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .ui-add-widgets-page .filterField,
  .system-status-logs-container .MBToolBar .filterField,
  .system-status-logs-container .MBToolBar .LogLevelComboBox,
  .aTriggerItemPanel .ui-trigger-type-list-box .MBComboBox,
  .BaseConversionDialog .modal-header .filterField,
  .ColorBox .MBComboBox,
  .GenericConfigPanel .myEncodingPanel .MBComboBox,
  .XmpCriterionEditorPanel .MBComboBox,
  .system-status-toolbar .filterField,
  .ui-add-widgets-page .filterField,
  .DictionaryAdminPanelFilter {
    min-width: 230px;
  }

  .SearchCriteriaPanel {
    .fields-combobox {
      min-width: auto !important;

      .MBComboBox {
        min-width: 150px !important;
      }
    }

    .conditions-combobox {
      .MBComboBox {
        min-width: 150px !important;
      }
    }

    .TextBoxSearchString .form-control {
      min-width: 100px !important;
    }
  }

  .commentButtons {
    .flex-grow-1 {
      word-break: break-all;
    }
  }
}

.ext-gecko,
.ext-gecko2,
.ext-ie11 {
  .thumb-scroll-panel {
    max-height: 500px;
  }
}
