// stylelint-disable declaration-no-important
// Position
$positions: static, relative, absolute, fixed, sticky;
@each $position in $positions {
  .position-#{$position}-default {
    position: $position;
  }
}

.bottom-0 {
  bottom: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.position-absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
