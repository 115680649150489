// stylelint-disable selector-no-qualifying-type
//
// Form controls
//
:not(output):-moz-ui-valid,
:not(output):-moz-ui-invalid {
  box-shadow: none;
}

.form-group-flex {
  display: flex;
  margin-bottom: $form-group-margin-bottom;
}

.form-control {
  // On input focus, lighten placeholder.
  height: auto;
  // Fixes input placeholder positioning on IE
  &:-ms-input-placeholder {
    line-height: 1;
  }

  // Undo the red box-shadow glow added by Firefox on invalid inputs.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid
  &:-moz-ui-invalid {
    box-shadow: none;
  }

  // Remove IE's default clear and reveal icons.
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  // Clear Safari's decorations for search fields.
  &,
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  // Also clear Safari's autofill icons. Note that this can't be in the
  // same selector as the IE ones, otherwise Safari will ignore it.
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-caps-lock-indicator,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  &:-ms-input-placeholder {
    // fix IE11 not able to focus programmatically with css style -ms-user-select: none
    // see https://github.com/angular/material2/issues/15093
    -ms-user-select: text;
  }

  // Fixes an issue on iOS where the following input types will collapse to 1px,
  // if they're empty, because we've overridden their background color.
  // See: https://stackoverflow.com/questions/18381594/input-type-date-appearance-in-safari-on-ios
  &[type="date"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="month"],
  &[type="week"],
  &[type="time"] {
    &::after {
      width: 1px;
      content: " ";
      white-space: pre;
    }
  }

  // Reduce the size of the native buttons in a date/time input,
  // because they can increase the height of the input (see #13317).
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator,
  &::-webkit-clear-button {
    font-size: 0.75em;
  }

  &:focus {
    box-shadow: none;
    // Placeholder
    &::placeholder {
      color: lighten($input-placeholder-color, 20%) !important;
    }
  }

  &.x-combo-noedit {
    &:disabled,
    &[readonly] {
      background-color: $input-bg;
    }
  }

  &.MBComboBox[readonly] {
    cursor: pointer;
    background-color: $input-bg;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  //&:disabled,
  //&[readonly] {
  //  //border-color: darken($input-disabled-bg, 10%);
  //
  //  & ~ .input-group-btn {
  //    background-color: $input-disabled-bg;
  //
  //    .btn {
  //      z-index: -1;
  //      opacity: 0;
  //    }
  //  }
  //}
}

.URLFieldPanel {
  .xmp-url-panel {
    .btn-link {
      text-align: left;
    }
  }
}

//.RenditionMetadataEditPanel {
//  position: absolute;
//  top: $spacer;
//  z-index: 10;
//  background-color: $white;
//  border: $border-width solid $border-color;
//  > div {
//    width: 190px;
//    min-height: 25px;
//    padding: 3px;
//  }
//}
.form-text {
  &:empty {
    display: none;
  }
}

.form-inline {
  margin-bottom: 5px;
}

.x-form-invalid {
  .form-control {
    border-color: $form-feedback-invalid-color;
  }
}

.CodeFieldContainer {
  width: 100%;

  .CodeMirror {
    @extend .card;
    height: auto;
  }
}

.disabled {
  .TextBoxItem {
    &:after {
      display: none;
      content: "";
    }
  }

  &.WrappedCodeFieldContainer {
    width: 100%;
    pointer-events: none;
  }

  .CodeMirror-scroll {
    background-color: #f3f3f3;
  }
}

.invalid {
  &.TextBoxItem {
    font-weight: 500;
    color: #fff;
    background-color: $form-feedback-invalid-color !important;

    &:after {
      font-weight: bold;
      color: #fff !important;
    }
  }
}

.datablock-invalid {
  border-color: $form-feedback-invalid-color;
}

.input-group-dictionary-typeahead.x-form-invalid,
.input-group-date.x-form-invalid {
  border-color: $form-feedback-invalid-color;
}

.x-form-invalid {
  .input-group-trigger-field {
    border-color: $form-feedback-invalid-color;
  }
}

.form-control-pseudo {
  //font-size: $font-size-base;
  //line-height: $input-line-height;
  //color: $input-color;
  border: $input-border-width solid $input-border-color;
  background-color: $input-bg;
  background-clip: padding-box;
  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }
  @include transition($input-transition);
  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    opacity: 1;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    background-color: $input-disabled-bg;
  }

  .btn,
  .form-control {
    border-color: transparent !important;
  }
}

.form-control-has-focus {
  @include transition($input-transition);
  color: $input-focus-color !important;
  border-color: $input-focus-border-color !important;
  background-color: $input-focus-bg !important;
}

.Brand-Hierarchy-field {
  .input-group {
    flex: 1 1 50px;
    width: auto;
  }
}

.is-invalid {
  .invalid-feedback {
    display: block;
  }
}

.x-form-empty-field {
  opacity: 1;
  // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  color: $input-placeholder-color;
}

.no-resize {
  &,
  textarea {
    overflow: hidden !important;
    resize: none !important;
  }
}

.ImportExportHolderPanel {
  .input-group {
    width: 100% !important;

    .form-control {
      width: 1% !important;
      padding-right: 0;
    }
  }
}

.loading-dock-disabled-label {
  padding-left: $custom-select-padding-x;
  border-left: 1px solid transparent;
}

textarea {
  resize: vertical;

  &.form-control {
    min-height: 38px;

    &.form-control-sm {
      min-height: 31px;
    }

    &.form-control-lg {
      min-height: 48px;
    }
  }

  &.x-form-invalid {
    border: 1px solid $form-feedback-invalid-color !important;
  }
}

.HideFieldEmpty {
  .FieldEmpty {
    display: none;
  }
}

// Override default Bootstrap Box shadows
.form-control,
.custom-select {
  .was-validated &:valid,
  .was-validated &:invalid,
  &.is-valid,
  &.is-invalid {
    &:focus {
      box-shadow: none;
    }
  }

  .was-validated &:valid,
  &.is-valid {
    &,
    &:focus {
      border-color: $input-border-color;
    }
  }
}

.filenamecolumn {
  width: 100%;
  padding: 0;
  text-align: center;
}

.x-form-invalid {
  //background: transparent;
  //border: 1px solid $form-feedback-invalid-color !important;
  //
  @extend .is-invalid;
}

.x-form-modified {
  background-color: $pink;
  //font-weight: $font-weight-bold;
  //> {
  //  .x-form-field {
  //    font-weight: $font-weight-bold;
  //  }
  //  .gwt-TextBox.standard_font {
  //    font-weight: $font-weight-bold;
  //  }
  //}
}

.fakeGXTGrid {
  width: 100%;
  padding: 2px 4px;
  border-bottom: 1px solid $color_alto_approx;
}

.CascadeLabel {
  &:hover {
    text-decoration: underline;
  }
}

.flex-grow {
  flex-grow: 1;
}
