//
// Cards
//
//img {
//  mix-blend-mode: multiply;
//}
//@media (prefers-color-scheme: dark) {
//}
.dark-scheme {
  display: none;
}

.ui-dark-mode {
  $body-bg-color: lighten($gray-900, 3%);

  .light-scheme {
    display: none;
  }

  .loading-dock-actions {
    .btn {
      color: $md-white-darkest;
    }
  }

  .btn-icon-default {
    color: $md-light-icons-default;
  }

  .removePMI {
    &:hover {
      background-color: $md-red-grey !important;
    }
  }

  .toolbar-paging {
    .x-btn-left,
    .x-btn-center,
    .x-btn-right {
      .btn {
        color: $md-white-darkest;
      }
    }
  }

  .selection-palette {
    background-color: #424242 !important;
  }

  .card-body {
    .active {
      .asset-select-badge {
        .material-icon {
          color: $primary !important;
        }
      }
      .asset-select-background.bg-white.rounded {
        background-color: white !important;
      }
    }
  }

  .card-text-view-gxt {
    .x-grid3-row:not(.is-selected) {
      &:hover {
        .x-grid3-td-__select__ {
          .x-grid3-col-__select__ {
            .asset-select-badge {
              .material-icon {
                color: $md-gray-500;
              }
            }
          }
        }
      }
      .x-grid3-td-__select__ {
        .x-grid3-col-__select__ {
          .asset-select-badge {
            .material-icon {
              color: $md-gray-600;
            }
          }
        }
      }
    }
  }

  .list-group-item-action {
    &:hover {
      background-color: $md-black-lighter !important;
    }
  }

  .dark-scheme {
    display: inline-block;
  }

  .asset-view-left {
    background-color: $black;
  }

  .mb-toast-item {
    .notification-wrapper {
      border-color: $gray-800 !important;
      .notification-title {
        color: $white !important;
      }
    }
  }

  .ui-notification-status-bar {
    .notification-bar-header {
      color: $white;
      background-color: $body-bg-color !important;
    }

    .notification-title {
      color: $white;
    }

    .notification-wrapper {
      background-color: $gray-800 !important;

      .notification-title {
        color: $white;
      }

      .btn {
        color: $white !important;
      }
    }

    .notification-bar {
      background-color: $gray-800 !important;
    }

    .LoadingDockNotificationWindow {
      color: $white;
      background-color: $gray-800 !important;

      .card-footer {
        color: $white;
        background-color: $body-bg-color !important;
      }
    }
  }

  .x-grid3-scroll-with-toolbar .x-grid3-body,
  .bg-white {
    background-color: $gray-900 !important;
  }

  .my-tree-item-text:not(:disabled):not(.disabled):hover {
    background-color: $md-black-lighter;
  }

  .form-control-plaintext,
  .fileActionQueuePanelStatusCodeCompleted {
    color: currentColor;
  }

  .nav-tabs .nav-link {
    color: $white;
  }

  .btn.btn-flat-primary {
    color: theme-color("primary") !important;
  }

  .x-grid3-row-alt,
  .bg-gray-100,
  .bg-gray-50,
  .header-footer {
    background-color: $body-bg-color;
  }

  .btn-outline-secondary:hover {
    background-color: $md-white-lighter;
  }

  &,
  .modal-content,
  .asset-view-right {
    color: $md-white-darker;
    background-color: $body-bg-color;
  }

  .asset-view-comment-list {
    background-color: lighten($gray-900, 6%);
  }

  .bg-body {
    border-color: darken($body-bg-color, 15%);
    background-color: $body-bg-color;
  }

  .modal-header,
  .x-grid3-scroll-with-toolbar .toolbar-paging,
  .modal-footer,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: darken($body-bg-color, 15%);
    background-color: $body-bg-color;
  }

  &.admin-mode {
    .layout-dropzone {
      background-color: rgba($black, 0.16);
    }
  }

  .modal-body {
    background-color: $gray-900 !important;
  }

  .modal-asset-view {
    .modal-body {
      background-color: $black;
    }
  }

  .modal-alert {
    .modal-header,
    .modal-footer,
    .modal-body {
      background-color: $body-bg-color !important;
    }
  }

  .form-control-has-focus {
    color: $white !important;
    background-color: $gray-900 !important;
  }

  .table-hover {
    tbody {
      tr:hover {
        color: $white;
      }
    }
  }

  .table {
    color: inherit;
  }

  .text-view-table-fix {
    .table {
      color: $white;
    }
  }

  .navbar-btn-home {
    background-color: transparent;
  }

  .banner-overall {
    color: $md-white-dark;

    .navbar-primary {
      background-color: $gray-800;
    }

    .navbar-subnav {
      border-color: $gray-900;
      background-color: $gray-800;
    }

    .form-control-pseudo {
      overflow: hidden;
      border: 1px solid $gray-700 !important;
      background-color: darken($gray-800, 5%);
      box-shadow: none !important;

      .form-control {
        color: $white !important;
        border-color: transparent !important;
      }

      &:hover,
      &.form-control-has-focus,
      .form-control-has-focus {
        &,
        .form-control:focus {
          background-color: $gray-900 !important;
        }
      }
    }
  }

  .x-grid-group-hd,
  .XmpEditActionButton {
    &::before {
      color: $md-light-icons-active;
    }
  }

  .vertical-drag-handle,
  .icon-default-style {
    color: currentColor !important;

    > .material-icon {
      color: currentColor !important;
    }
  }

  .x-grid3-row-checker::before {
    color: currentColor !important;
  }

  .x-grid3-row-selected {
    .x-grid3-row-checker::before {
      color: theme-color("primary") !important;
    }
  }

  .btn-chip {
    color: $white;
    background-color: $md-black-light;
  }

  .chip-value {
    color: $white;
  }

  .search-status-container .folder-all:before,
  .btn-chip:not(.btn-chip-color):after {
    color: inherit;
  }

  .banner-overall,
  .nav-tabs-workspace {
    .btn-icon-pseudo-bg::before,
    .btn-icon-default {
      color: $md-light-icons-default;
    }

    .btn-tab-item {
      color: $md-white-darker;
      background-color: $md-white-lighter;
      @include hover {
        background-color: $md-white-light;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $white;
        background-color: $mb-2018-blue;
      }
    }

    .btn-icon-default {
      color: $md-light-icons-default !important;
      @include hover {
        color: $md-light-icons-active;
        background-color: $md-white-light;
      }
      // Disabled comes first so active can properly restyle
      &.disabled,
      &:disabled {
        color: $md-light-icons-inactive;
        background-color: transparent;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &.tippy-active,
      .show > &.dropdown-toggle {
        color: $md-light-icons-active;
        background-color: $md-white-light;
      }

      &.text-primary {
        .material-icon {
          color: theme-color("primary") !important;
        }
      }
    }
  }

  .ImportExportHolderPanel {
    color: $md-light-icons-default;
    box-shadow: inset 0 0 0 2px theme-color("primary") !important;

    .mat-radio input:not(:checked) + span::before,
    .mat-radio input:not(:checked) + span::after {
      border: 2px solid $md-light-icons-default;
    }

    .mb-checkbox::before,
    .mb-radio label::before {
      color: $md-light-icons-default;
    }

    .modal-header {
      color: $white;
      border: none;
      background-color: theme-color("primary");
    }
  }

  .infinite-scroll-item {
    &:not(.active) {
      background-color: $md-black-light;
    }

    &.active {
      background-color: theme-color("primary");

      img {
        opacity: 1 !important;
        mix-blend-mode: normal;
      }
    }
  }

  //.tippy-tooltip,
  //.tippy-backdrop {
  //  background-color: $black;
  //}
  .navbar-light {
    .navbar-brand {
      color: $navbar-dark-active-color;
      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }

    .btn-icon-default,
    .btn-flat-secondary {
      color: $navbar-dark-color;
      @include hover-focus {
        color: $navbar-dark-hover-color;
        background-color: $md-black-light;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .btn-flat-secondary {
      &.btn-selected {
        background-color: $md-black-light;
      }

      @include hover {
        background-color: $md-black-light;
      }
    }

    .navbar-toggler {
      color: $navbar-dark-color;
      border-color: $navbar-dark-toggler-border-color;
    }

    .navbar-toggler-icon {
      background-image: $navbar-dark-toggler-icon-bg;
    }

    .navbar-text {
      color: $navbar-dark-color;

      a {
        color: $navbar-dark-active-color;
        @include hover-focus {
          color: $navbar-dark-active-color;
        }
      }
    }
  }

  .admin-mode {
    .layout-dropzone {
      background-color: rgba($white, 0.06);
    }
  }

  .card-view-widget {
    .input-group-page-count {
      color: $md-white-darker !important;
    }
  }

  .btn-group-import-export {
    .btn-white {
      color: $md-white-dark;
      border-color: transparent;
      background-color: $md-white-lighter;
      box-shadow: none;
      @include hover {
        color: $md-white-darkest;
        border-color: transparent;
        background-color: $md-white-light;
      }
    }
  }

  .mb-header-grid {
    .x-grid3-hd-inner {
      &,
      &::after {
        color: $md-white-dark;
      }
    }
  }

  .media-comment:not(.media-comment-session-user) {
    .media-comment-body {
      background-color: theme-color("dark");
    }
  }

  .btn-media-play {
    @include hover {
      color: $white_80 !important;
    }
  }

  .navbar-btn-home .btn-home-bg,
  .logoHolder {
    background-image: url(images/logo-MediaBeacon-dark.png);
  }

  .mb-toast-item {
    background-color: $md-gray-700;
  }

  .tippy-dropdown-menu-theme {
    .dropdown-item-text {
      color: $md-white-darker;
    }
  }

  .dropdown-menu,
  .tippy-tooltip.light-theme,
  .tippy-dropdown-menu-theme {
    background-color: darken($md-gray-800, 4%);

    &,
    .dropdown-header {
      color: $md-white-dark;
    }

    .dropdown-item::before,
    .dropdown-item::after,
    .material-icon {
      color: $md-white-darker;
    }

    .mat-radio input:not(:checked) + span:before,
    .mat-radio input:not(:checked) + span:after {
      border-color: $md-white-darker;
    }

    .x-combo-selected {
      @include gradient-bg($md-white-lighter);
    }

    .dropdown-item {
      color: $md-white-darker;
      @include hover-focus {
        color: $white;
        @include gradient-bg($md-white-lighter);
        &::before,
        &::after,
        .material-icon {
          color: $white;
        }
      }

      &.active,
      &:active {
        color: $md-white-darkest;
        @include gradient-bg($md-white-lighter);

        &::before,
        &::after,
        .material-icon {
          color: $md-light-icons-active;
        }
      }

      &.disabled,
      &:disabled {
        color: $md-white-50;
      }
    }

    .RemovePrioritizedMenuItem,
    .RemoveIconMenuItem:not(.Delete-Workspace),
    .border-top {
      border-color: $gray-900 !important;
    }

    .dropdown-divider {
      border-color: darken($gray-900, 10%) !important;
    }

    .input-group-trigger-field {
      border: 1px solid $gray-700 !important;
      background-color: darken($gray-800, 5%);
      box-shadow: none !important;

      .form-control {
        color: $white !important;
        border-color: transparent !important;
        background-color: transparent;
      }

      &:hover,
      &.x-trigger-wrap-focus,
      .x-trigger-wrap-focus {
        &,
        .form-control:focus {
          background-color: $gray-900 !important;
        }
      }
    }

    .bg-gray-50 {
      background-color: $md-black-lighter;
    }

    .list-group-item {
      color: $white;
      border-color: $md-white-light;
      background-color: transparent;
    }

    .border-bottom {
      border-color: $md-black-lighter !important;
    }

    .card {
      border-color: $md-white-light;
      background-color: transparent;
    }

    .mb-checkbox:before,
    .mb-radio label:before {
      color: $md-white-darker;
    }

    .toolbar-paging {
      background-color: $md-black-lighter;
    }

    &.dropdown-menu-pantone {
      .x-menu-list-item:not(:first-child) {
        border-top: $md-white-light;
      }

      .dropdown-header {
        padding-bottom: ($spacer * 0.75);
        color: $md-white-dark;
      }

      .text-dark {
        color: $md-white-darker !important;
      }

      .pantone-color-container {
        width: ($spacer * 1.5);
      }
    }
  }

  .trash-svg {
    fill: $md-white-darker;
  }

  .dropdown-holder-combo-box {
    &.has-scroll:after {
      background-color: $gray-800;
    }
  }

  .pretty-scroll {
    .card-widget &,
    .dropdown-filter-menu & {
      &:hover,
      &:focus {
        &::-webkit-scrollbar-thumb {
          background-color: rgba($white, 0.5);
        }
      }
    }
  }

  .photo-wall .alert-component-error:before {
    color: $md-white-50;
  }

  td.x-grid3-hd-menu-open .x-grid3-hd-inner {
    background-color: $md-white-lighter;
  }

  .sort-asc .x-grid3-hd-inner:before,
  .sort-desc .x-grid3-hd-inner:before {
    color: $md-white-darker;
  }

  .modal-dialog,
  .AlternativesPanel {
    .has-icon-before::before,
    .has-icon-after::after {
      color: $md-white-darker;
    }
  }

  .modal-header,
  .header-panel {
    .material-icon {
      color: $md-white-darker;
    }
  }

  .input-group-trigger-field {
    .input-group-append {
      .material-icon {
        color: $md-white-darker;
      }
    }
  }

  .btn-outline-secondary {
    color: $white;
  }

  .xmpButtonBar {
    background-color: color("gray-dark");
    .btn-outline-secondary {
      border-color: transparent;
    }
  }

  .card {
    border-color: rgba($white, 0.05);
    background-color: $gray-800;

    .card-header {
      border: none !important;
      background-color: rgba($white, 0.05);
    }

    &.is-open {
      .card-header {
        color: $md-white-dark;
      }
    }

    .XmpEditActionButton {
      background-color: $md-gray-800 !important;
    }

    .M3TAFormWidget .btn-flat-dark {
      color: $white !important;
    }

    .card-header:first-child {
      color: $white;
    }

    &.card-expandable > .card-header:first-child {
      color: $md-white-dark;
      background-color: rgba($white, 0.05);
    }

    .btn-white:not(.disabled) {
      .material-icon {
        color: $md-black-dark !important;
      }
    }

    .hasChildren:before,
    .material-icon,
    .has-icon-before::before,
    .has-icon-after::after {
      color: $md-white-darker;
    }

    .card-body {
      color: $white;
    }

    .sub-header-actions,
    .more-filter-field {
      background-color: rgba($white, 0.05);
    }

    .mb-tree-table:hover .gwt-TreeItem-selected .my-tree-item-text:not(:disabled):not(.disabled) {
      &,
      &:hover {
        background-color: transparentize(theme-color("primary"), 0.75);
      }
    }

    .TabPanel-Tabs-holder .badge-secondary,
    .badge-tree-count {
      color: $md-white-darker;
      background-color: $md-black-dark;
    }

    .list-group-item {
      color: $white;
      border-color: rgba($white, 0.15);
      background-color: transparent;
    }

    .list-group-item-action {
      // Hover state
      @include hover-focus {
        color: $white;
        background-color: $md-white-lighter;
      }

      &:active {
        color: $white;
        background-color: $md-white-lighter;
      }
    }

    .gwt-TreeItem-selected {
      .my-tree-item-text {
        &,
        &:hover {
          background-color: transparentize(theme-color("primary"), 0.75);
        }
      }

      .folder-icon,
      .workflow-icon {
        color: theme-color("primary");
      }
    }

    .tick {
      tspan {
        fill: $md-white-dark;
      }
    }

    .c3,
    text {
      fill: $md-white-dark;
    }

    .bg-white {
      &:not(.asset-badge):not(.date-picker-months-years) {
        background-color: transparent !important;
      }
    }

    .custom-select {
      color: $white;
      background: darken($gray-800, 5%)
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$white}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right $custom-select-padding-x center;
      background-size: $custom-select-bg-size;
    }

    .card-grid-box {
      background-color: darken($md-gray-700, 5%);
    }

    .card-img-top-contain {
      border-bottom-color: lighten($body-bg-color, 5%);
      background-color: darken($md-gray-700, 13%);
    }

    .nav-tabs {
      border-color: $md-white-light;

      .nav-link {
        color: $md-white-darker;
        border: $nav-tabs-border-width solid transparent;
        @include border-top-radius($nav-tabs-border-radius);
        @include hover-focus {
          border-color: transparent;
          background-color: rgba($white, 0.1);
        }

        &.disabled {
          color: $nav-link-disabled-color;
          border-color: transparent;
          background-color: transparent;
        }
      }

      .nav-link.active,
      .nav-item.show .nav-link {
        color: $white;
        border-color: $md-gray-700;
        background-color: $md-gray-700;
      }
    }
  }

  .card-text-view-gxt {
    &:not(.card-widget-has-error) {
      .x-grid3-scroller {
        color: $md-white-darker;
        background-color: transparent;

        .table th,
        .table td {
          border-color: $md-white-light;
        }

        .x-grid3-row {
          @include hover {
            color: $white;
            background-color: $md-white-lighter;
          }
        }
      }
    }
  }

  .ImportExportHolderPanel {
    .btn-secondary {
      color: hsla(0, 0%, 100%, 0.7) !important;
      border-color: transparent !important;
      background-color: hsla(0, 0%, 100%, 0.12) !important;
    }
  }

  .table-danger {
    background-color: $md-red-grey !important;
  }

  .table-primary,
  .table-primary > td,
  .table-primary > th {
    background-color: $md-blue-grey-700;
  }

  .btn-outline-secondary {
    border-color: $gray-700;
  }

  .input-group-text {
    color: $white !important;
    border: 1px solid $gray-700 !important;
    background-color: darken($gray-800, 5%);
  }

  &.mail-dialog-iframe {
    //box-shadow: none !important;
    //background-color: #353535;
    //border: 1px solid #616161 !important;
    //overflow: hidden;
    //color: #fff !important;
    //&:focus {
    //  background-color: #212121 !important;
    //}
  }

  .btn-secondary,
  .btn-white:not(.btn-annotation) {
    //background-color: $md-white-lighter;
    //border-color: $gray-700;
    @include button-variant($md-white-lighter, $gray-700);
    color: $white !important;
    border-color: transparent !important;
  }

  .card {
    .btn-control-prev,
    .btn-control-next {
      &.btn-icon {
        &.disabled {
          color: $md-dark-icons-inactive !important;
        }
        background-color: $gray-800 !important;
        .material-icon {
          color: $white !important;
        }
      }
    }
  }

  .btn-annotation {
    color: $black !important;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: currentColor !important;
    background-color: $md-white-lighter;
  }

  .tippy-dropdown-menu-theme {
    .list-group-item-primary.list-group-item-action {
      color: $body-color;
      background-color: $component-active-bg-light;
      @include hover-focus {
        color: $body-color;
        background-color: $component-active-bg-light;
      }
    }
  }

  //.btn-date {
  //  @include button-flat-variant($gray-600, $default-hover-bg);
  //  color: $white !important;
  //}
  .tippy-dropdown-menu-theme ul {
    color: $white !important;
  }

  .x-date-disabled {
    .btn-date {
      color: #9e9e9e !important;
    }
  }

  .btn-date {
    color: $white !important;
    border-color: transparent;
    background-color: transparent;
  }

  .x-date-prevday,
  .x-date-nextday {
    .btn-date {
      opacity: 0.7;
    }
  }

  .MBDatePicker {
    .date-picker-months-years.bg-white {
      background-color: $md-gray-800 !important;
    }
  }

  .x-date-middle {
    .x-btn-text {
      color: $md-white-darker !important;
    }
  }

  hr,
  .border-top,
  .border-bottom,
  .border-left,
  .border-right,
  .x-grid-table-bordered .x-grid3-row,
  .x-date-days,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-color: $gray-700 !important;
  }

  .quick-search.form-control-pseudo {
    transition: none !important;
  }

  .form-control {
    &.disabled,
    &:disabled {
      //202678006
      background-color: $gray-900 !important;
      opacity: 0.7;
    }
  }

  .custom-select,
  .card-widget select,
  .card-widget .MBComboBox[readonly],
  .card-widget .input-group-trigger-field,
  .card-widget .form-control,
  .input-group-trigger-field,
  &.mail-dialog-iframe,
  &.form-control,
  .form-control,
  .form-control-pseudo {
    overflow: hidden;
    color: $white !important;
    border: 1px solid $gray-700;
    background-color: darken($gray-800, 5%);
    box-shadow: none !important;

    .form-control {
      transition: none !important;
      color: $white !important;
      border-color: transparent !important;
    }

    &:hover,
    &:focus,
    &.x-trigger-wrap-focus,
    &.form-control-has-focus,
    .form-control-has-focus {
      &,
      .form-control,
      .form-control:hover,
      .form-control:focus {
        background-color: $gray-900 !important;
      }
    }
  }

  p.bg-gray-200 {
    background-color: $md-black-light;
  }

  .alignment-image.active {
    background-color: $md-white-lighter !important;
    border-color: $md-white-lighter !important;
  }

  .dropdown-item.dropdown-has-submenu.tippy-active {
    background-color: $md-white-lighter !important;
  }

  .thumbnail-icon {
    .rounded-circle.btn {
      .material-icon {
        color: $md-black-dark !important;
      }
    }
  }

  .clear-all-notifications {
    color: $danger !important;
  }

  .dropdown-item.RemoveIconMenuItem:hover {
    background-color: $md-red-grey;
  }

  &.mail-dialog-iframe {
    overflow: auto !important;
    border: none !important;
    border-radius: 0.25rem;
  }

  //.form-control-pseudo {
  //  .form-control {
  //    background-color: transparent !important;
  //    border-color: transparent !important;
  //  }
  //}
  //.quick-search.form-control-pseudo {
  //  box-shadow: none !important;
  //  background-color: darken($gray-800, 5%);
  //  border: 1px solid $gray-700 !important;
  //
  //  .form-control {
  //    border-color: transparent !important;
  //  }
  //
  //  &:hover,
  //  &.form-control-has-focus,
  //  .form-control-has-focus {
  //    &,
  //    .form-control:focus {
  //      background-color: $gray-900 !important;
  //    }
  //  }
  //}
  .sort-asc .x-grid3-hd-inner,
  .sort-asc .x-grid3-hd-inner::before,
  .sort-asc .x-grid3-hd-inner::after,
  .sort-desc .x-grid3-hd-inner,
  .sort-desc .x-grid3-hd-inner::before,
  .sort-desc .x-grid3-hd-inner::after,
  .x-grid3-hd-inner:hover,
  .x-grid3-hd-inner:hover::before,
  .x-grid3-hd-inner:hover::after {
    color: currentColor !important;
  }

  .form-control.MBComboBox[readonly],
  .list-group-item {
    color: currentColor !important;
    border-color: $gray-700 !important;
    background-color: darken($gray-800, 5%) !important;

    &.list-group-item-action {
      @include hover-focus {
        color: currentColor;
        background-color: darken($gray-800, 8%);
      }

      &.active {
        color: $white !important;
        border-color: theme-color("primary") !important;
        background-color: theme-color("primary") !important;
      }
    }
  }

  .form-control.MBComboBox[readonly] {
    border: none !important;
  }

  .folder-icon,
  .workflow-icon {
    color: currentColor !important;
  }

  .mat-radio input:not(:checked) + span::before,
  .mat-radio input:not(:checked) + span::after {
    border-color: $md-white-dark;
  }

  .mb-checkbox.active::before,
  .x-menu-item-checked .mb-checkbox::before {
    content: "check_box";
    color: #4177f6;
  }

  .list-group-notification-item {
    .notification-delete-placeholder.material-icon {
      color: $md-black-dark !important;
    }
  }

  .mb-checkbox,
  .mb-radio label {
    &:not(.active) {
      &::before {
        color: $md-white-darker;
      }
    }
  }

  .modal-backdrop.show {
    opacity: 0.85;
  }

  .html-config-panel {
    .outer {
      border-bottom: none;
      background-color: inherit;
    }
  }
}
