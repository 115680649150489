//
// Date Menu - X Date Menu - x-date-menu
//
$date_picker_padding: $spacer / 2;
.date-picker.CalendarFacetPanel {
  width: auto !important;
}

body > .MBDateMenu {
  display: none;
}

.date-picker {
  width: 250px !important;

  > table:not(:first-child) {
    width: 100% !important;
    table-layout: fixed;
    border-collapse: collapse;
  }

  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }

  th,
  td {
    text-align: center;
  }

  .x-date-mp-prev,
  .x-date-mp-next {
    text-decoration: none;
  }
}

.x-date-bottom {
  table {
    width: 100% !important;
    table-layout: fixed;
    border-collapse: collapse;
  }
}

.data-menu-header-center {
  > table {
    width: 100% !important;
  }

  em {
    display: block;
  }

  .x-btn-left,
  .x-btn-right {
    display: none;
  }
}

.btn-date {
  white-space: nowrap;
  color: $body-color;
  border-color: $white;
  background-color: $white;
  @include hover {
    color: $body-color;
    border-color: $md-gray-300;
    background-color: $md-gray-300;
  }
  // Disabled comes first so active can properly restyle
  .x-date-disabled & {
    color: $md-gray-500;
    border-color: $white;
    background-color: $white;
  }

  // x-date-mp-sel
  .x-date-active:not(.x-date-disabled).x-date-selected &,
  .x-date-mp-sel & {
    color: $white;
    border-color: theme-color("primary") !important;
    background-color: theme-color("primary") !important;
  }

  .x-date-prevday &,
  .x-date-nextday & {
    color: $md-gray-500;
  }
}

.date-picker-months-years {
  top: 0 !important;
  bottom: 0 !important;

  table {
    width: 100% !important;
    table-layout: fixed;
    border-collapse: collapse;
  }

  td {
    width: 100%;
  }
}

.x-date-days {
  border-bottom: $default-border;

  th,
  td {
    span {
      font-size: 0.7rem;
      line-height: 12px;
      height: 16px;
      text-align: center;
      color: $text-muted;
    }
  }
}
