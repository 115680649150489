//
// Dashboard
//
.system-logs-scroll-panel {
  &:not(.is-loading):empty {
    & + .empty-state {
      display: flex !important;
    }
  }
}

.stat-graph {
  .graph-flex-body {
    flex-grow: 2 !important;
  }
}

.statBody {
  &.display-4 {
    font-size: 1.5rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }
}

.dock-panel .x-combo-list {
  width: 100% !important;
}

.statsHolder {
  .statPanel {
    min-height: 150px;
    margin-bottom: 0;
  }
}

//.graph-flex-body {
//  &,
//  > div,
//  svg {
//    height: 100% !important;
//    width: 100% !important;
//    max-height: 250px;
//  }
//}
.statGraph {
  width: 100% !important;
  margin: 0 auto;
  text-align: center;

  > div {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    border: 0;
  }
}

//.statHeader,
//.statTitle {
//  color: $white;
//}
//.statBody {
//  color: $white;
//  &.bold {
//    font-size: $font-size-lg;
//  }
//}
.statPanel {
  //min-width: 200px;
  //min-height: 150px;
  //background-color: theme-color("primary");
  //
  //&.orange {
  //  background-color: color("orange");
  //}
  //
  //&.green {
  //  background-color: color("green");
  //}
  //
  //&.lightBlue {
  //  background-color: $md-light-blue-500;
  //}
  .PreviewPanel {
    padding: 2px;
    //background-color: $white;
  }

  .ImageHolder {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}

.StaticTextEditPanel {
  width: 100%;
}

.Dashboard {
  .leftContainer {
    position: absolute;
    z-index: 11;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none !important;
    min-height: 350px;

    .LayoutDropPanelCell {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      background-color: $concrete_80;

      .BaseWidget-root {
        flex: auto;
        flex-grow: 1;
      }
    }
  }

  .rightContainer {
    position: absolute;
    z-index: 11;
    top: 65px;
    right: 65px;
    max-width: none !important;
    min-height: 350px;

    .LayoutDropPanelCell {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      background-color: $concrete_80;
    }

    .GraphSearchWidget-root {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .GraphSearchWidget-root {
    min-width: 300px;
    margin-right: 40px;
    margin-bottom: 0;
    margin-left: 40px;

    .BaseWidget-title {
      font-size: 18px !important;
      align-items: center;
      justify-content: center;
    }

    .BaseWidgetContentPanel {
      align-self: center;
      width: 300px !important;
      height: 309px;
    }

    .c3 {
      max-height: 309px !important;
    }
  }

  .banner-overall {
    height: 45px;
  }

  .BaseWidget-title:before {
    content: "";
  }
}
