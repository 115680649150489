@import "../_mixins.scss";
@import "../_vars.scss";

.tippy-popper {
  line-height: $line-height-base;

  .fab-badge-theme {
    padding: 0;
  }

  @each $placement in $placements {
    &[x-placement^="#{$placement}"] {
      .tippy-tooltip.light-theme {
        .tippy-arrow {
          @include arrow($placement, 7px, $white);
        }
      }
    }
  }

  & ~ .dropdown-menu {
    z-index: 9999 !important;
  }
}

.tippy-tooltip {
  @include border-radius($dropdown-border-radius);
  box-shadow: 1px 1px 2px rgba($black, 0.1);

  &.light-theme {
    color: $popover-body-color;
    background-color: $white;

    .tippy-backdrop {
      background-color: $white;
    }

    .tippy-roundarrow {
      fill: $white;
    }

    &[data-animatefill] {
      background-color: transparent;
    }
  }
}

.dropdown-menu-theme {
  background-color: $dropdown-bg !important;
  background-clip: padding-box;
  @include border-radius($dropdown-border-radius);

  .tippy-backdrop {
    background-color: $dropdown-bg !important;
  }
}

.popover-description-theme {
  max-width: $popover-max-width;
  padding: $popover-body-padding-y $popover-body-padding-x !important;

  .tippy-content {
    //font-size: $popover-font-size;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.tippy-dropdown-menu-theme {
  font-size: $font-size-base !important; // Redeclare because nesting can cause inheritance issues
  display: block;
  min-width: $dropdown-min-width;
  margin: $dropdown-spacer 0 0; // override default ul
  padding: $dropdown-padding-y 0 !important;
  list-style: none;
  text-align: left;
  color: $body-color;
  background-color: $dropdown-bg;
  background-clip: padding-box;

  .context-menu {
    position: relative !important;
    z-index: auto;
  }

  ul {
    font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
    margin: 0; // override default ul
    padding: 0;
    list-style: none;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    color: $body-color;
  }

  .tippy-roundarrow {
    fill: $dropdown-bg;
  }

  &.popover-theme {
    padding: 0 !important;
  }
}

.header-plus-theme {
  width: 350px !important;
}

.tippy-active {
  > .x-grid3-hd-inner {
    &,
    &::before,
    &:not(.x-grid3-hd-__plus__)::after {
      color: theme-color("primary");
    }
  }
}
