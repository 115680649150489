//
// Progress Bar
//
$pace-bg-color: theme-color("primary");
.pointer-events-none {
  pointer-events: none;
  touch-action: none;
}

.pace {
  z-index: $zindex-tooltip;
  contain: size layout style;
  backface-visibility: hidden;

  &:not(.pace-active) {
    opacity: 0;
  }
}

.pace-progress {
  position: absolute;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
  background: $pace-bg-color;
  backface-visibility: hidden;
  contain: size layout style;

  &.show {
    will-change: transform;
  }
}

.pace-progress-inner {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  transform: rotate(3deg) translate(0px, -3px);
  box-shadow: 0 5px 10px $pace-bg-color, 0 2px 5px $pace-bg-color;
  contain: strict;
}

.progress-holder {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
}

.ProgressImage-empty {
  display: flex !important;
}

.progress {
  .progress-image & {
    height: 5px;
  }
}

//.ProgressImage-full {
//  z-index: 1000;
//  float: left;
//  width: 1px;
//  height: 9px;
//  background: $url_mb_58 repeat-x;
//  transition: width .3s ease-out;
//}
//
//.ProgressImage-empty {
//  width: 100%;
//  height: 9px;
//  background: $pink;
//  border: 1px solid $pink;
//}
//.loading-progress-bar {
//  display: block;
//  // Height is provided for mat-progress-bar to act as a default.
//  height: $mat-progress-bar-height;
//  overflow: hidden;
//  position: relative;
//  // translateZ is added to force the mat-progress-bar into its own GPU layer.
//  transform: translateZ(0);
//  transition: opacity $mat-progress-bar-piece-animation-duration linear;
//  width: 100%;
//
//  .loading-progress-bar-sm & {
//    height: 3px;
//  }
//
//  // Base styles that are re-used across all of the progress bar children.
//  .loading-progress-bar-element,
//  .loading-progress-bar-fill::after {
//    height: 100%;
//    position: absolute;
//    width: 100%;
//  }
//
//  // The progress bar background is used to show the bubble animation scrolling behind a
//  // buffering progress bar.
//  .loading-progress-bar-background {
//    background-repeat: repeat-x;
//    background-size: 10px 4px;
//    display: none;
//  }
//
//  // The progress bar buffer is the bar indicator showing the buffer value and is only visible
//  // beyond the current value of the primary progress bar.
//  .loading-progress-bar-buffer {
//    transform-origin: top left;
//    transition: transform $mat-progress-bar-piece-animation-duration ease;
//  }
//
//  // The secondary progress bar is only used in the indeterminate animation, because of this it
//  // is hidden in other uses.
//  .loading-progress-bar-secondary {
//    display: none;
//  }
//
//  // The progress bar fill fills the progress bar with the indicator color.
//  .loading-progress-bar-fill {
//    animation: none;
//    transform-origin: top left;
//    transition: transform $mat-progress-bar-piece-animation-duration ease;
//  }
//
//  // A pseudo element is created for each progress bar bar that fills with the indicator color.
//  .loading-progress-bar-fill::after {
//    animation: none;
//    content: '';
//    display: inline-block;
//    left: 0;
//  }
//
//  // Reverse the apparent directionality of progress vars for rtl.
//  &[dir='rtl'],
//  [dir='rtl'] & {
//    transform: rotateY(180deg);
//  }
//
//  &[mode='query'] {
//    transform: rotateZ(180deg);
//
//    &[dir='rtl'],
//    [dir='rtl'] & {
//      transform: rotateZ(180deg) rotateY(180deg);
//    }
//  }
//
//  &[mode='indeterminate'],
//  &[mode='query'] {
//    .loading-progress-bar-fill {
//      transition: none;
//    }
//    .loading-progress-bar-primary {
//      animation: mat-progress-bar-primary-indeterminate-translate $mat-progress-bar-full-animation-duration infinite linear;
//      left: -145.166611%;
//    }
//    .loading-progress-bar-primary.loading-progress-bar-fill::after {
//      animation: mat-progress-bar-primary-indeterminate-scale $mat-progress-bar-full-animation-duration infinite linear;
//    }
//    .loading-progress-bar-secondary {
//      animation: mat-progress-bar-secondary-indeterminate-translate $mat-progress-bar-full-animation-duration infinite linear;
//      left: -54.888891%;
//      display: block;
//    }
//    .loading-progress-bar-secondary.loading-progress-bar-fill::after {
//      animation: mat-progress-bar-secondary-indeterminate-scale $mat-progress-bar-full-animation-duration infinite linear;
//    }
//  }
//
//  &[mode='buffer'] {
//    .loading-progress-bar-background {
//      animation: mat-progress-bar-background-scroll $mat-progress-bar-piece-animation-duration infinite linear;
//      display: block;
//    }
//  }
//}
//
//
//
//
//.loading-progress-bar-background {
//  background-color: lighten(theme-color("primary"), 20%);
//}
//
//.loading-progress-bar-buffer {
//  background-color: lighten(theme-color("primary"), 20%);
//}
//
//.loading-progress-bar-fill::after {
//  background-color: theme-color("primary");
//}
//.loading-progress-bar.loading-accent {
//  .loading-progress-bar-background {
//    background-image: #{_mat-progress-bar-buffer($accent, lighter)};
//  }
//
//  .loading-progress-bar-buffer {
//    background-color: mat-color($accent, lighter);
//  }
//
//  .loading-progress-bar-fill::after {
//    background-color: mat-color($accent);
//  }
//}
//
//.loading-progress-bar.loading-warn {
//  .loading-progress-bar-background {
//    background-image: #{_mat-progress-bar-buffer($warn, lighter)};
//  }
//
//  .loading-progress-bar-buffer {
//    background-color: mat-color($warn, lighter);
//  }
//
//  .loading-progress-bar-fill::after {
//    background-color: mat-color($warn);
//  }
//}
// The values used for animations in mat-progress-bar, both timing and transformation, can be
// considered magic values. They are sourced from the Material Design example spec and duplicate
// the values of the original designers definitions.
//
// The indeterminate state is essentially made up of two progress bars, one primary (the one that
// is shown in both the determinate and indeterminate states) and one secondary, which essentially
// mirrors the primary progress bar in appearance but is only shown to assist with the
// indeterminate animations.
// KEYFRAME BLOCK                      DESCRIPTION
// primary-indeterminate-translate     Translation of the primary progressbar across the screen
// primary-indeterminate-scale         Scaling of the primary bar as it's being translated
//                                     across the screen
// secondary-indeterminate-translate   Translation of the secondary bar across the screen
// secondary-indeterminate-scale       Scaling of the secondary bar as it's being translated
//                                     across the screen
//
// Because two different transform animations need to be applied at once, the translation is
// applied to the outer element and the scaling is applied to the inner element, which provides the
// illusion necessary to make the animation work.
//
//// Progress Bar Timing functions:
//// $mat-progress-bar-primary-indeterminate-translate-step-1 has no timing function.
//$mat-progress-bar-primary-indeterminate-translate-step-2: cubic-bezier(0.5, 0, 0.701732, 0.495819) !default;
//$mat-progress-bar-primary-indeterminate-translate-step-3: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352) !default;
//// $mat-progress-bar-primary-indeterminate-translate-step-4 has no timing function.
//
//// $mat-progress-bar-primary-indeterminate-scale-step-1 has no timing function
//$mat-progress-bar-primary-indeterminate-scale-step-2: cubic-bezier(0.334731, 0.12482, 0.785844, 1) !default;
//$mat-progress-bar-primary-indeterminate-scale-step-3: cubic-bezier(0.06, 0.11, 0.6, 1) !default;
//// $mat-progress-bar-primary-indeterminate-scale-step-4 has no timing function
//
//$mat-progress-bar-secondary-indeterminate-translate-step-1: cubic-bezier(0.15, 0, 0.515058, 0.409685) !default;
//$mat-progress-bar-secondary-indeterminate-translate-step-2: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712) !default;
//$mat-progress-bar-secondary-indeterminate-translate-step-3: cubic-bezier(0.4, 0.627035, 0.6, 0.902026) !default;
//// $mat-progress-bar-secondary-indeterminate-translate-step-4 has no timing function
//
//$mat-progress-bar-secondary-indeterminate-scale-step-1: cubic-bezier(0.15, 0, 0.515058, 0.409685) !default;
//$mat-progress-bar-secondary-indeterminate-scale-step-2: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712) !default;
//$mat-progress-bar-secondary-indeterminate-scale-step-3: cubic-bezier(0.4, 0.627035, 0.6, 0.902026) !default;
//// $mat-progress-bar-secondary-indeterminate-scale-step-4 has no timing function
//
//// Animations for indeterminate and query mode.
//
//// Primary indicator.
//@keyframes mat-progress-bar-primary-indeterminate-translate {
//  0% {
//    transform: translateX(0);
//  }
//  20% {
//    animation-timing-function: $mat-progress-bar-primary-indeterminate-translate-step-2;
//    transform: translateX(0);
//  }
//  59.15% {
//    animation-timing-function: $mat-progress-bar-primary-indeterminate-translate-step-3;
//    transform: translateX(83.67142%);
//  }
//  100% {
//    transform: translateX(200.611057%);
//  }
//}
//
//@keyframes mat-progress-bar-primary-indeterminate-scale {
//  0% {
//    transform: scaleX(0.08);
//  }
//  36.65% {
//    animation-timing-function: $mat-progress-bar-primary-indeterminate-scale-step-2;
//    transform: scaleX(0.08);
//  }
//  69.15% {
//    animation-timing-function: $mat-progress-bar-primary-indeterminate-scale-step-3;
//    transform: scaleX(0.661479);
//  }
//  100% {
//    transform: scaleX(0.08);
//  }
//}
//
//// Secondary indicator.
//@keyframes mat-progress-bar-secondary-indeterminate-translate {
//  0% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-translate-step-1;
//    transform: translateX(0);
//  }
//  25% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-translate-step-2;
//
//    transform: translateX(37.651913%);
//  }
//  48.35% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-translate-step-3;
//    transform: translateX(84.386165%);
//  }
//  100% {
//    transform: translateX(160.277782%);
//  }
//}
//
//@keyframes mat-progress-bar-secondary-indeterminate-scale {
//  0% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-scale-step-1;
//    transform: scaleX(0.08);
//  }
//  19.15% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-scale-step-2;
//    transform: scaleX(0.457104);
//  }
//  44.15% {
//    animation-timing-function: $mat-progress-bar-secondary-indeterminate-scale-step-3;
//    transform: scaleX(0.72796);
//  }
//  100% {
//    transform: scaleX(0.08);
//  }
//}
//
//// Animation for buffer mode.
//@keyframes mat-progress-bar-background-scroll {
//  to {
//    transform: translateX(-10px);
//  }
//}
// In buffer mode a repeated SVG object is used as a background.
// Each of the following defines the SVG object for each of the class defined colors.
//
// The string is a URL encoded version of:
//
// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
//      version="1.1" x="0px" y="0px" enable-background="new 0 0 5 2"
//      xml:space="preserve" viewBox="0 0 5 2" preserveAspectRatio="none slice">
//   <circle cx="1" cy="1" r="1" fill="{INJECTED_COLOR}"/>
// </svg>
//@function mat-color($palette, $hue: default, $opacity: null) {
//  // If hueKey is a number between zero and one, then it actually contains an
//  // opacity value, so recall this function with the default hue and that given opacity.
//  @if type-of($hue) == number and $hue >= 0 and $hue <= 1 {
//    @return mat-color($palette, default, $hue);
//  }
//
//  $color: map-get($palette, $hue);
//  $opacity: if($opacity == null, opacity($color), $opacity);
//
//  @return rgba($color, $opacity);
//}
//
//
//@function _mat-progress-bar-buffer($palette, $hue) {
//  $color: mat-color($palette, $hue) + '';
//
//  // We also need to escape the hash in hex colors,
//  // otherwise IE will throw an XML error.
//  @if str-index($color, '#') == 1 {
//    $color: '%23' + str-slice($color, 2);
//  }
//
//  $result: '' +
//    'data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2F' +
//    'www.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%2' +
//    '7%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%' +
//    '3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none' +
//    '%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27' +
//    $color + '%27%2F%3E%3C%2Fsvg%3E';
//
//  @return url($result);
//}
//
//
//@mixin mat-progress-bar-theme($theme) {
//  $primary: map-get($theme, primary);
//  $accent: map-get($theme, accent);
//  $warn: map-get($theme, warn);
//
//  .loading-progress-bar-background {
//    background-image: #{_mat-progress-bar-buffer($primary, lighter)};
//  }
//
//  .loading-progress-bar-buffer {
//    background-color: mat-color($primary, lighter);
//  }
//
//  .loading-progress-bar-fill::after {
//    background-color: mat-color($primary);
//  }
//
//  .loading-progress-bar.loading-accent {
//    .loading-progress-bar-background {
//      background-image: #{_mat-progress-bar-buffer($accent, lighter)};
//    }
//
//    .loading-progress-bar-buffer {
//      background-color: mat-color($accent, lighter);
//    }
//
//    .loading-progress-bar-fill::after {
//      background-color: mat-color($accent);
//    }
//  }
//
//  .loading-progress-bar.loading-warn {
//    .loading-progress-bar-background {
//      background-image: #{_mat-progress-bar-buffer($warn, lighter)};
//    }
//
//    .loading-progress-bar-buffer {
//      background-color: mat-color($warn, lighter);
//    }
//
//    .loading-progress-bar-fill::after {
//      background-color: mat-color($warn);
//    }
//  }
//}
//
//@mixin mat-progress-bar-typography($config) { }
