//
// ViewLogWidget Widget
//
.ViewLogPanel {
}

.card-view-log-widget {
  .x-grid3 {
    height: auto !important;
  }
}

.view-log-panel-controls {
  .aside & {
    display: block;
    width: 100%;

    .form-group {
      margin-bottom: ($spacer * 0.5) !important;
    }

    .form-control-label {
      font-size: $font-size-sm;
      margin-bottom: ($spacer / 4);
    }

    .form-control {
      @extend .form-control-sm;
    }

    .input-group {
      @extend .input-group-sm;
    }

    .custom-select {
      @extend .custom-select-sm;
    }

    .btn {
      @extend .btn-sm;
    }
  }
}
