.ext-gecko,
.ext-gecko2 {
  .mh-100-gecko {
    max-height: 100%;
  }

  .SystemStatusDisplayDialog {
    .flex-fill {
      flex-basis: 0 !important;
    }
  }

  //.modal-dialog {
  //  height: 100%;
  //}
  //
  //.modal-content {
  //  max-height: 100%;
  //}
}
