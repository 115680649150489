//
// Content Holder Widget
//
.card-content-holder-widget {
  .no-admin-mode & {
    &.landing-page-mode-enabled {
      flex: 1 1 auto;
      margin-bottom: 0;
    }
  }

  .admin-mode &,
  &:not(.titleless) {
    .btn-control-indicator {
      margin-top: 8px;
    }
  }
}

//
.contentWidget {
  width: 135px;
  margin: auto;
  padding: 0.5rem;
}

.table-content-holder {
  position: relative;
  table-layout: fixed;
  transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;

  &,
  table {
    width: 100%;
  }

  tr,
  td,
  th {
    padding: 0;
    border: none;
  }
}
