//
// Drag & Drop
//
.dragdrop-capture-mask {
  //z-index: 1000;
  //background-color: blue;
  //pointer-events: none;
}

.draggable-is-dragging,
.draggable-is-dragging *,
.draggable-widget .MainPage,
.band-select-is-dragging,
.band-select-is-dragging *,
.x-unselectable,
.x-unselectable *,
.dragdrop-draggable,
.dragdrop-handle,
.dragdrop-dropTarget-engage,
.list-group-facet {
  user-select: none !important;
  touch-action: none !important;
}

.draggable-widget {
  .MainPage {
    pointer-events: none;
  }
}

.layout-drag-ghost {
  height: 51px;

  margin-bottom: 0.5rem;
  border: 2px dotted $blue;
  background-color: transparent;
  &:empty {
    display: flex !important;
  }
}

.draggable-source-is-dragging,
.draggable-asset-is-dragging {
  transition: opacity 0.15s $mat-fast-out-linear-in-timing-function;
  pointer-events: none;
  opacity: 0.5 !important;

  &.card-widget {
    opacity: 1;
  }
}

// Drag & Drop - Hover Zone
.drop-zone-valid,
.DropZone-dragging {
  position: relative;
  z-index: $zindex-modal-backdrop * 2;
  @include drag-drop-highlight();
  pointer-events: none;
}

.html-widget-drop-zone-valid .list-group-item-action {
  border-color: $blue;
  background-color: $component-active-bg-light !important;
}

.layout-dropzone-hover {
  margin: 5px;
}

.draggable-is-dragging {
  .card-widget {
    overflow: hidden;
  }
}

.x-dd-drop-ok {
  z-index: $zindex-modal-backdrop + 2 !important;
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  text-align: center;
  color: $tooltip-color;
  @include border-radius($border-radius);
  background-color: $tooltip-bg;
}

.x-insert-bar {
  z-index: $zindex-tooltip;
  @include mat-elevation(6);
  border: 2px solid $blue;
  background-color: $md-white-dark;
}

.DropZone-dragging {
  position: relative;
  z-index: 14 !important;
}

.dnd-drag-hand-icon {
  display: inline-table;
}

.layout-dropzone-hover-mask {
  padding: $card-spacer-x;
  cursor: default;
}

.Dnd-upload-label-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
}

.Dnd-upload-fileinput {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  opacity: 0;
}

.Dnd-upload-container {
  height: 200px;
}

.Dnd-upload-parent {
  position: relative;
  z-index: 14;
}

.Dnd-upload-link {
  position: relative;
  z-index: 13;
}

.DragDropStart {
  .ScratchBasketTabDropTarget {
    display: flex;
  }

  .hideTabBars .ScratchBasketTabDropTarget {
    display: none;
  }
}

.btn-dnd-handle {
  cursor: move;
  color: $md-dark-icons-default;
}

.dnd-selected {
  color: $white !important;
  background-color: $pink !important;
}

.layout-dropzone {
  &.dragdrop-dropTarget-engage {
    background-color: $component-active-bg-light;

    &::before {
      position: absolute;
      z-index: 10;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      content: "";
      box-shadow: inset 2px 2px $blue, inset -2px -2px $blue, 0 1px 2px rgba($black, 0.15);
      @include border-radius($card-border-radius);
    }

    .card-infinite-scroll-view-widget {
      flex: none;
    }

    .BaseWidget-buttons,
    .card-sub-header,
    .card-dot-paging-panel,
    .card-body,
    .card-footer {
      display: none !important;
    }
  }
}

// Drag & Drop - Ghost panel during moving
.dragdrop-movable-panel .BaseWidget-root {
  width: 100%;
}

.dragdrop-movable-panel:not(.drag-proxy-base) {
  z-index: $zindex-tooltip !important;
  @include mat-elevation(15);
  height: auto !important;
  transform: translateZ(0);
  will-change: transform;

  .card {
    position: relative !important;
    width: 100%;
    border: none;
    backdrop-filter: saturate(180%) blur(20px);
  }

  .card-dot-paging-panel,
  .card-sub-header,
  .card-footer,
  .BaseWidget-buttons,
  .card-body {
    display: none;
  }

  .asset-view-drag-container & {
    box-shadow: none;

    .vertical-drag-container {
      opacity: 1;
    }
  }
}

.drag-proxy-inner {
  width: 50px;
  height: 50px;
}

.drag-proxy-base {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  pointer-events: none;
  outline: 0;
  will-change: transform;

  &,
  .folder-icon {
    color: $component-active-color;
  }

  .plus-button:before {
    color: theme-color("primary");
  }

  .my-tree-item-text {
    margin-left: 0;
    padding: 0;

    .btn,
    .EditableLabel {
      display: none;
    }

    ~ .PlusButton {
      left: 15px;

      &:before {
        color: theme-color("primary");
      }
    }

    .badge-tree-count {
      line-height: 19px;
      position: absolute;
      z-index: 11;
      top: 2px;
      left: 17px;
      min-width: 24px;
      height: 24px;
      margin-top: -12px;
      padding-right: 0.6em;
      padding-left: 0.6em;
      color: $white;
      border-radius: 5em;
      background-color: theme-color("danger");
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }

    .folder-icon {
      font-size: 50px !important;
      transform: scale(0.7);
      animation: folder-dnd-pop;
      color: #9e9e9e !important;
    }
  }

  .avatar-lg {
    position: absolute !important;

    &:first-child {
      z-index: 1;
    }
  }
}

.badge-drag-proxy-count {
  line-height: 24px;
  position: absolute;
  z-index: 11;
  top: 2px;
  left: 42px;
  min-width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-right: -12px;
  opacity: 0;

  .drag-proxy-base-multiple & {
    opacity: 1;
  }
}

.band-select-is-dragging {
  .MainPage {
    pointer-events: none;
  }

  &.draggable-widget {
    .infinite-scroll-item {
      &.active {
        opacity: 0.65;
      }
    }
  }
}

.dragdrop-selected,
.dragdrop-proxy {
  opacity: 1;
}

.dndStatus {
  position: absolute;
  @include size($btn-pseudo-size);
  z-index: 11;
  top: 25px;
  left: 6px;
  margin-top: -$btn-pseudo-size / 2;
  border: none;
  background-color: theme-color("primary");

  .material-icon {
    color: $white;
  }

  &::before,
  &.PlusButton {
    font-size: 1.5rem;
    color: $white;
  }
}

@keyframes folder-dnd-pop {
  from {
    font-size: 24px;
  }
  to {
    font-size: 70px;
  }
}

.coverPage {
  position: fixed !important;
  @include size(100%);
  top: 0;
  left: 0;
  contain: strict;
}

.Dnd-upload-anywhere {
  position: relative;
  z-index: 12;
}

.DragDropUploadViewWidgetPanel {
  position: fixed !important;
  z-index: 2;

  &:not(.coverPage) {
    display: none !important;
    pointer-events: none;
  }
}
