// stylelint-disable
//
// GWT Base Widgets
//
.TurnJSPanel {
  position: relative;
  @include size(100%);
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
}

.x-view {
  .green {
    background-color: color("green");
  }

  .orange {
    background-color: color("orange");
  }

  .blue {
    background-color: color("blue");
  }
}

.x-progress-wrap {
  border: none;

  &.error {
    border: 1px solid $white;
  }
}

.x-editor {
  .DictionaryBackedAutoCompleteItemizedTextBox {
    display: flex !important;
  }
}

.x-form-item {
  label {
    white-space: normal;
  }

  .override label {
    display: inline;
    float: inherit;
  }
}

.x-btn-cal-text {
  @include size(20px, 24px);
  border: none;
  background-repeat: no-repeat;
  background-position: 100%;
}

.x-dd-cursor {
  cursor: default !important;
}

.x-panel-noborder .x-panel-header-noborder {
  border-width: 0;
  border-bottom: $default-border;
}

.x-form-check-wrap .x-form-radio {
  display: inline;
}

.EditFieldContentPanel {
  .one,
  .two {
    display: inline-table;
    width: 48%;
    padding: 0 5px;
  }

  > {
    .flexRow {
      align-items: flex-start;
      margin-bottom: 10px;

      > {
        .AbstractMultiField,
        .DictionaryBackedAutoCompleteItemizedTextBox,
        .HtmlFieldContainer,
        .MBCheckbox,
        .MBDateField,
        .MBHierarchyField,
        .MBItemizedTextbox,
        .URLFieldPanel,
        .x-form-field,
        .x-form-text {
          flex-grow: 2;
        }

        .DictionaryBackedAutoCompleteItemizedTextBox {
          width: inherit;
        }

        .standard_font {
          white-space: normal;
        }
      }
    }
  }

  .flexRow {
    .x-form-invalid-icon {
      display: none;
    }

    > .textArea {
      align-items: flex-start;
      min-height: 50px;
    }
  }

  .DictionaryBackedAutoCompleteItemizedTextBox {
    display: flex !important;
  }
}

.PriorityPanelHeaderCheckbox {
  display: inline-block;
  width: auto !important;
  vertical-align: middle;
}

.AudioPanel {
  .video-timecode {
    display: none;
  }
}

.FTP-assets-cm,
.Remove-Metadata-cm,
.Remove-all-cm,
.Unbundle-cm,
.Workspaces-Summary-cm,
.bandSelectButtonOff,
.bandSelectButtonOn,
.dnd-plugin-image,
.editLinkButton-base,
.editLinkButton-down,
.editLinkButton-hover,
.savedSelectionDeleteButton,
.standard_button_size {
  @include square(16px);
  cursor: pointer;
}

.bandSelectButtonOff {
  background-image: $url_mb_31;
}

.bandSelectButtonOn {
  background-image: $url_mb_30;
}

.dnd-plugin {
  @include square(16px);
  display: inline-table;
  cursor: move;
  background-image: $url_mb_46 !important;
  background-repeat: no-repeat;
}

.dnd-plugin-image {
  opacity: 0;
}

.FTP-assets-cm {
  background-image: $url_mb_116;
}

.Remove-Metadata-cm {
  background-image: $url_mb_117;
}

.savedSelectionDeleteButton {
  margin-right: 5px;
}

.Unbundle-cm {
  background-image: $url_mb_117;
}

.MBContentPanel {
  .footer {
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .x-panel-header {
    margin-top: 0;
  }
}

.WorkflowTypePanel {
  .Icon {
    > label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > input {
      height: 20px;
    }

    label::before {
      margin-right: 4px;
    }
  }

  .Regular input[type="radio"]:checked + label::before {
    opacity: 1;
  }

  .Advanced label::before {
    content: "code";
    color: $orange;
  }

  .Library label::before {
    content: "library_books";
    color: theme-color("primary");
  }
}

.GeneralXmpTabItem {
  position: relative;

  .imageHolder {
    align-self: flex-start;
    flex-grow: 3;
    width: 230px;
  }
}

.WorkspaceOption {
  min-width: 610px;

  .IconSelectionPanel {
    flex-grow: 0;
  }

  > :first-child {
    min-width: 210px;
  }
}

.XmpEditActionButton {
  padding: 4px;
  background-color: $md-gray-100;

  > div {
    @include size(18px);
  }

  &::before {
    width: 18px;
  }

  &::after {
    content: "expand_more";
  }
}

.Email-all-cm {
  @include size(18px);
}

.xmpedit-regex-icon {
  background: $url_mb_1;
}

.FullScreenPanel {
  position: absolute !important;
  z-index: 1092;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  background-color: $white;

  .MBButton {
    &:hover {
      background-color: transparent;

      &::before {
        color: $black;
      }
    }

    &::before {
      font-size: $font-size-lg;
      line-height: ($spacer * 2);
    }
  }
}

.FullScreenPanelCloseButton {
  z-index: 1;
  top: 4px !important;
  right: 4px !important;
  background-color: transparent !important;

  &::before {
    content: "close";
  }

  &.MBButton {
    &::before {
      font-weight: $font-weight-bold;
      font-size: 2rem;
    }
  }
}

.APIKeyExplorerPanel {
  .x-grid3 {
    overflow: inherit;
  }

  .x-toolbar {
    right: 0;
    bottom: 0;
    left: 0;
  }

  .MBHoverChangeButton > .standard_font {
    padding-top: 2px;
  }

  .DictionaryBackedAutoCompleteItemizedTextBox .gwt-TextBox {
    width: 100%;
    height: 19px !important;
    margin: 0;
  }
}

.UnmatchedValues {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: -6px;
  margin-left: 1px;

  .standard_font {
    float: left;
    margin: 2px 1px 0;
    border: $border-width solid $border-color;
    border-radius: 3px;
    background-color: $red;
  }

  .MBHoverChangeButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ext-ie11 {
  .MetricsViewWidget {
    .c3 {
      svg {
        height: 300px !important;
      }
    }
  }
}

.MetricsViewWidget {
  position: relative;
  padding-top: 20px;
  padding-right: 20px;

  .header {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
  }

  .c3 {
    svg {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }
}

.CuePointEditPanelHeader {
  height: 28px;
  border-bottom: $default-border;
  background-color: $md-gray-100;
}

.TransformationActionPanelHeader {
  height: 28px;
  border-bottom: $default-border;
  background-color: $md-gray-100;

  .standard_font {
    top: 4px;
    left: ($spacer * 2);
  }
}

.searchrefine_popup {
  position: absolute;
  z-index: 502;
  width: 300px;

  & > div:hover {
    background-color: rgba($black, 0.05);
  }
}

.TransformationActionPanel {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  width: 250px;
  border: $border-width solid $border-color;

  .cancel {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 4px;
    border-top: $default-border;
    background-color: $md-gray-100;

    .btn {
      width: 100% !important;
    }
  }
}

.error {
  .x-progress-bar {
    background-color: theme-color("danger");
  }

  .x-progress-text {
    cursor: pointer;
    text-decoration: underline;
  }
}

.CuePointsUpdatingShow {
  font-weight: $font-weight-bold;
  width: 480px;
  text-align: center;
  color: color("red");
}

.top-database-graphic {
  font-weight: $font-weight-bold;
  width: 259px;
  height: 37px;
  color: theme-color("primary");
}

.warningmessage {
  color: color("red");
}

.xmp-tab-item-title {
  font-weight: $font-weight-bold;
  width: 100%;
  height: $spacer;
  margin: 3px 0 0;
  padding-right: 5px;
  padding-left: 5px;
  vertical-align: bottom;
  white-space: nowrap;
  color: $white;
}

.lowerPreExt {
  z-index: auto !important;
}

.MBFileUpload {
  input {
    cursor: initial;
  }
}

.UploadPanelInternal {
  .UploadAndXHolder {
    .MBFileUpload {
      width: 100%;

      input[type="text"] {
        padding-right: 38px;
        text-overflow: ellipsis;
      }
    }

    .closeButton-base {
      position: absolute;
      z-index: 5;
      right: 0px;
    }
  }
}

.UploadProgressDialogInternal {
  .individualScroll {
    height: auto;
    max-height: 300px;
    margin-top: 4px;
    @extend .pretty-scroll !optional;

    .x-progress {
      margin-top: 4px;
    }
  }
}

.mobile-right-column {
  position: absolute;
  right: 0;
  width: 80% !important;
  height: 100%;
}

.TJSPageDialog {
  .MBHoverChangeButton {
    width: $spacer !important;
    height: $spacer !important;
  }

  .gwt-Frame {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.mobile-left-column {
  position: absolute;
  left: 0;
  width: 20% !important;
  height: 100%;
}

.HelpHeader {
  border-bottom: 2px solid $md-gray-100;
}

.mobile-single-column {
  position: absolute;
  left: 0;
  height: 100%;
}

.xmpTabCheckOutBadge {
  position: absolute;
  top: -1px;
  left: 97%;
}

.EmailUserInvitation {
  width: 500px;
  height: 350px;
  padding: 4px;
}

.EmailUserInvitationSummary {
  .x-grid3 {
    height: 320px;
  }
}

.EmailPanel {
  textarea {
    width: 100%;
    height: 225px;
  }

  input {
    width: 100%;
  }
}

.field-add {
  background-image: $url_mb_11;
}

.Rename-folder-cm {
  background-image: $url_mb_11;
}

.hasTextContent {
  &::before {
    margin-right: 4px;
  }
}

.MBCheckbox {
  padding: 0 4px;

  &::before {
    font-family: $font-family-material;
    font-weight: 400;
    content: "check_box_outline_blank";
    white-space: nowrap;
    color: $color_tapa_approx;
    font-feature-settings: "liga";
    text-rendering: optimizeLegibility;
  }

  &.check::before {
    content: "check_box";
  }
}

.XmpTemplatePanel {
  .x-toolbar {
    height: 21px;
  }

  > .x-tab-strip-top > .flexRow {
    width: 100%;
  }
}

.float-left {
  float: left;
}

.HtmlContainer {
  padding: 2px;
  border: 1px solid $white;
}

.ThreeDimensionalPanelImage {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.fullScreen {
  position: absolute;
  top: 2px;
  right: 2px;

  &::before {
    content: "fullscreen";
  }
}

.WYSIWYG-holder {
  height: auto !important;

  &.disabled {
    opacity: 1;
  }
}

.WYSIWIG-editor-row {
  height: 200px;
}

.cal-high {
  background-color: $color_alizarin_crimson_approx !important;
}

.cal-med {
  background-color: $color_orange_approx !important;
}

.cal-low {
  background-color: $color_tree_poppy_approx !important;
}

.cal-lower {
  background-color: $color_turbo_approx !important;
}

.dictionary-information-holder {
  .mat-radio {
    min-width: 168px;
  }
}

.showingIcon {
  padding-left: 44px !important;
  background-repeat: no-repeat;
  background-position: 22px;
  background-size: $spacer $spacer;
}

.hasIcon {
  padding-left: 21px;
  background: no-repeat 3px;
  background-size: 18px 18px;
}

.checked {
  width: 10px;
  margin-left: 2px;
}

.searchRefineText {
  padding-right: 2px;
}

.RenditionsBottomTab {
  position: relative;
  height: 200px;

  .RenditionImageHolder {
    position: absolute;
    bottom: 6px;
    margin-left: 10px;

    .selected {
      border: none;
    }

    img {
      padding: 5px;
      border: 2px solid $color_celeste_approx;
      border-radius: 4px;
      box-shadow: 1px 1px 4px $color_celeste_approx;
    }

    > div {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
    }

    .standard_font {
      text-align: center;
    }
  }

  .PagingPanel {
    position: absolute;
    right: 100px;
    left: 0;
    height: 26px;
    border-top: $default-border;
  }
}

.alignment-selected {
  background-color: $md-gray-100;
}

.VisualDateGraphPanel {
  & + .VisualDateGraphPanel {
    margin-top: 0.5rem;
  }
}

.DateRangeBox {
  .font-weight-bold:empty {
    display: none;
  }

  &:last-child {
    .font-weight-bold {
      text-align: right;
    }
  }

  @include transition($btn-transition);
  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
    color: $list-group-action-hover-color;
    background-color: transparentize($progress-bar-bg, 0.9);
  }
}

.slideshowSelected {
  background-color: $md-gray-100;
}

.VideoFrameContent {
  height: 95%;

  &:hover {
    background-color: $md-gray-100;
  }
}

.HighlightElementPanel-error {
  border: 1px solid $form-feedback-invalid-color !important;
  background: transparent;
}

.remote-edit-conflicting {
  &.x-form-invalid {
    border: 1px solid $form-feedback-invalid-color !important;
  }
}

.VideoTimelineInfo {
  position: relative;
  padding: 1px 3px;
  background-color: $white;
}

.panel_debug_panel_seperated {
  width: 100%;
  margin-bottom: 4px;
  border-right: $default-border;
  border-bottom: $default-border;
  border-left: $default-border;
}

.CV-YVH {
  .ForwardPagingButton-base {
    top: -8px;
    left: 0;
  }

  .ForwardPagingButton-hover {
    top: -8px;
    left: 0;
  }

  .BackwardPagingButton-base {
    top: -8px;
    left: -14px;
  }

  .BackwardPagingButton-hover {
    top: -8px;
    left: -14px;
  }
}

.CV-YV {
  padding: 5px;
}

.CropOverlay {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  cursor: move;
  background-color: $color_cloud_80_approx;

  .relative {
    overflow: hidden;
  }
}

.InjectableEditableLabel {
  @extend .text-truncate !optional;
}

.my-tbl-col-overflow {
  overflow: hidden;
}

.CuePointTimeMarkerMiddle {
  font-size: $font-size-sm;
  position: absolute;
  top: 57px;
  left: -10px;
  overflow: visible;
  width: $spacer;
  height: ($spacer * 2);
  text-align: center;
  background-color: transparent;
}

.absolute_badge_wrapper {
  overflow: visible !important;
  width: 4px;
  height: 4px;
}

.TransformationActionPanelBody {
  margin: 4px;

  .row {
    position: relative;
    height: 26px;

    .RenditionName {
      position: inherit;
      top: inherit;
      right: inherit;
      width: 100%;
      height: 22px !important;
    }

    .standard_font {
      position: relative;
      top: 5px;
    }
  }

  .x-btn {
    width: 100% !important;
    margin-bottom: 4px;
  }
}

.Action,
.error {
  cursor: pointer;

  .x-progress-bar:hover {
    background-color: theme-color("primary");
  }
}

.x-progress-text {
  text-decoration: underline;
}

.searchrefine_bg-global {
  .DefaultHeader {
    border: none;
    background-color: theme-color("primary");

    .standard_font {
      color: $white !important;
    }

    &::before {
      color: $white !important;
    }
  }
}

.commentHolder {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 57px;

  .blue {
    color: theme-color("primary");
  }
}

.componentDescriptionTitle {
  font-weight: $font-weight-500;
  color: theme-color("primary");
}

.top-label {
  color: theme-color("primary") !important;
}

.replaceAssetDialog {
  margin-left: 10px;
}

.WordFieldSet {
  padding: 10px 0 0;

  .x-fieldset-header-text {
    margin-left: 10px;
  }

  .x-grid3-row-table {
    cursor: pointer;
  }
}

.topMiddle {
  border-top: 1px solid $color_silver_chalice_approx;
  background-color: $white;
}

.DefaultValueTextBox {
  position: absolute;
  top: 3px;
  left: 5px;
}

.separator {
  padding-right: 7px;
}

.minor {
  color: $color_shady_lady_approx !important;
}

.current-acl-name {
  margin-bottom: 4px !important;
  padding-top: 4px;
  padding-left: 4px;
  white-space: nowrap;
}

.login-login-wait {
  position: absolute;
  top: 8px;
}

.login-fields-progress-container {
  width: 230px;
  margin-left: 10px;

  .standard-text-box {
    width: 210px;
    padding: 0 2px;
  }

  * {
    margin-bottom: 5px !important;
  }
}

//#ExtendedViewRoot {
//  .xmpButtonBar {
//    position: relative;
//  }
//  .DiscussionPanelHolder {
//    height: 560px;
//  }
//}
.DiscussionXmpTabItem {
  position: relative;
}

.MultiPagePanel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 56px) !important;
  height: calc(100% - 56px);
  margin: 28px;
}

.DiscussionPanelHolder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.x-panel-body {
    position: relative;
    height: 320px;
  }
}

.downloadSlide {
  font-family: $font-family-material;
  font-size: ($spacer * 2);
  font-weight: 400;
  position: absolute;
  z-index: 2;
  right: 3%;
  bottom: 3%;
  display: flex;
  @include size(35px);
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  transform: rotate(180deg);
  text-decoration: none;
  opacity: 0;
  background-color: $md-gray-100;
}

.TJSDisabled {
  position: absolute;
  pointer-events: none;
  opacity: 0 !important;
}

.XmpTemplatePanelBody {
  overflow-x: hidden;
}

.debug_panel_hatch {
  background: $url_mb_32 repeat;
}

.loading_dock_item_log {
  margin-top: 6px;
}

.scrolling-fieldSet {
  padding-top: 5px;
  padding-right: 0;
  padding-bottom: 2px;
}

.SiliconConnectorManagementPanel {
  .x-fieldset {
    padding-right: 0;
    padding-left: 0;
  }

  .x-fieldset-header {
    padding-left: 10px;
  }
}

.pinPointContent {
  max-width: 350px;
  padding: 10px;
}

.Reset-Clip-Boundaries-cm {
  display: none !important;
}

.Search-Bundle-cm {
  background-image: $url_mb_36;
}

.Notify-cm {
  background-image: $url_mb_37;
}

.Create-Clip-From-Asset-cm {
  background-image: $url_mb_39;
}

.To-Loading-Dock-cm {
  background-image: $url_mb_39;
}

.Preview-Queue-cm {
  background-image: $url_mb_42;
}

.Video-cm {
  background-image: $url_mb_43;
}

.Hide-Hidden-Workspaces-cm {
  background-image: $url_mb_44;
}

.Show-Hidden-Workspaces-cm {
  background-image: $url_mb_44;
}

.Approvals-cm {
  background-image: $url_mb_45;
}

.Keyword-Search-cm {
  background-image: $url_mb_49;
}

.FeedAction {
  background-image: $url_mb_50;
}

.RSS-cm {
  background-image: $url_mb_50;
}

.HEPanel {
  margin: 0;

  .x-form-invalid-icon {
    display: none;
  }

  .moveable {
    margin: 0;
    background-color: $color_bon_jour_approx;
  }
}

.ScratchBasketItem {
  .ThreeDimensionalPanel {
    .MediaPlayButton {
      display: none;
    }
  }
}

.BasketIndicatorBar {
  height: ($spacer * 2);
  background-color: $color_quill_gray_approx;

  &.inactive {
    display: none;
    height: 0;
  }

  .standard_font {
    position: relative;
    top: 7px;
    text-align: center;
  }
}

.RenditionAssetInfo {
  position: relative;

  .topPanel {
    position: relative;
    height: 509px;
    padding: 10px;

    .gwt-Button {
      width: 100% !important;
      margin-bottom: 5px;
    }

    > img {
      position: relative;
    }

    > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 200px;
      border-left: $default-border;
    }
  }

  &.noRendition {
    .topPanel {
      height: 445px !important;

      .gwt-Button {
        width: auto !important;
        margin: 5px 0 3px 3px;
      }

      > div {
        width: 310px !important;
        border: none !important;
      }
    }

    .CuePointsTab {
      bottom: -21px;
      left: 8px !important;
      height: 205px !important;
    }
  }

  .bottomPanel {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    height: 200px;
    border-top: $default-border;
  }

  .MetadataBlock {
    width: 160px;
    margin: 0;
    padding-right: 5px;
    padding-left: 5px;

    .value {
      overflow-y: scroll;
      height: 48px;
      word-break: break-all;
    }
  }

  .CuePointsTab {
    left: 0 !important;
    width: 480px !important;
    border-left: none !important;
  }

  .FilmstripBorder {
    position: absolute;
    top: 13px;
  }

  .FilmstripRight {
    right: 6px;
  }

  .FilmstripLeft {
    left: 6px;
  }

  .x-toolbar .DisplayingLabel {
    display: none;
  }
}

.TodoViewHeader {
  .editButton-base {
    display: none;
  }

  &:hover .editButton-base {
    display: inherit;
  }
}

.PlusExpandButton {
  float: left;
  margin-top: 4px;
}

.SelectionPopupPanel {
  .standard-text-box {
    width: inherit !important;
  }

  .SelectionPopupPanelBody > .standard-text-box {
    width: 330px !important;
    margin-bottom: 4px;
  }
}

.AssetsAreaPanel {
  .x-toolbar {
    padding-right: 0;
    padding-left: 0;
  }
}

.badgeflowpanel {
  width: 100%;
  border-top: $default-border;
  border-bottom: $default-border;
}

.FeedSummary {
  padding-left: 5px;
}

.title-bar-button {
  @include size($spacer);
  margin-left: 2px;
}

.OpenSocialWidget-RSSButton {
  vertical-align: top !important;
}

.RssOutgoing {
  background-image: $url_mb_52;
}

.RssIncoming {
  transform: rotate(-180deg);
  background-image: $url_mb_53;
}

.RssButton {
  background-image: $url_mb_53;
}

.HierarchyLabel {
  height: $spacer;
  cursor: pointer;
}

.VideoTrimOverlayActive {
  padding-top: 0 !important;
  border-top: 1px solid $color_fuscous_gray_approx;
  border-bottom: 1px solid $color_fuscous_gray_approx;
  border-radius: 5px;
}

.hasBackgroundImage {
  padding-left: 27px;
  background-repeat: no-repeat;
  background-position: 4px;
  background-size: 18px;
}

.MBGridCell {
  border: $border-width solid $border-color;
}

.database-background {
  width: 259px;
}

.hover-database-panel {
  border: 1px solid $color_pigeon_post_approx;
  background-color: $pink;
}

.ACLSpacer {
  width: 276px;
  height: 1px;
  margin-right: 3px;
  margin-left: 4px;
}

.paletteCell {
  width: 100%;
  height: 75px;
  border-radius: 6px;
  background-color: $color_jordy_blue_approx;
}

.related_asset_selected,
.selected_placed_thumbnail,
.share_selected_placed_thumbnail,
.toolbar-action-active,
.CuePointCodeType {
  color: $white !important;
  border-color: theme-color("primary") !important;
  background-color: theme-color("primary") !important;
}

.infoIcon {
  @include square($spacer);
  margin-left: 4px;
  cursor: pointer;
}

.gwt-hier-spacer {
  width: 100%;
  height: 1px;
}

.hierarchy-clear-button-off {
  margin-left: 3px;
  opacity: 0.3;
}

.hierarchy-clear-button-on {
  margin-left: 3px;
  cursor: pointer;
}

.onTop {
  z-index: 10000;
}

.keywordFrequencyPanel-item {
  display: inline;
  margin: 1px;
}

.perspective-table {
  height: 98%;
}

.link__switchdb__,
.link__switch__ {
  width: 74px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-position: 0 -22px;
  }
}

.link__switchdb__ {
  &:hover {
    background-position: -160px -22px;
  }
}

.summary-holder {
  display: inline-table;
  height: 65px;
}

.PermissionWorkflowEditPanelCodeEditor {
  width: 100%;
  height: 400px;
  border-bottom: $default-border;
}

.MBDragProxy-top {
  margin-bottom: 2px;
}

.place-config-proxy {
  cursor: move;
  opacity: 0.7;
}

.fourPixelBottomMargin {
  margin-bottom: 4px;
}

.version-tab-asset-container {
  padding-top: 2px !important;
  padding-right: 0;
  padding-bottom: 2px;
  padding-left: 0;
}

.moveable {
  cursor: move;
}

.auto-center {
  margin-right: auto;
  margin-left: auto;
}

.R3SearchMaxScrollHeightValue {
  display: block;
}

.Feed-Buttons-Holder {
  margin-right: ($spacer * 2);
}

.Feed-Margin-Left {
  margin-left: 20px;
}

.requiredUploadTabsPanel {
  display: inline;
}

.actionPaletteTab {
  position: relative;
  left: 60px;
  width: 120px;
  margin-bottom: 0;
  background-color: $white;
  box-shadow: 0 1px 6px $black_8;

  .BaseWidget-title::before {
    content: "";
  }
}

.dimLegend {
  opacity: 0.3 !important;
}

.badge_panel_item_cover {
  z-index: 30;
  border-right: 1px solid $color_natural_gray_approx;
  background: $url_mb_63 repeat;
}

.badge_panel_item_cover_borderless {
  z-index: 30;
  background: $url_mb_63 repeat;
}

.attach_file_fields {
  margin: 0 !important;
  padding: 0 !important;

  .x-panel-body {
    background: $md-gray-100 !important;
  }
}

.WorkflowCreateRadioButton {
  margin-right: $spacer;
}

.scratch-basket-tab-icon {
  @include size(18px);
  background-image: $url_mb_64;
}

.shadow_grid {
  border-collapse: collapse;
}

.searchrefine_border {
  width: 100%;
  height: 2px;
  background-color: $pink;
}

.searchrefine_bg-left,
.searchrefine_bg-right,
.searchrefine_bg-global-left,
.searchrefine_bg-global-right {
  width: 2px;
  height: 5px;
  border-right: 1px solid $pink;
  background-color: theme-color("primary");
}

.searchrefine_bg-middle,
.searchrefine_bg-global-middle {
  width: 100%;
  height: 4px;
  background-color: theme-color("primary");
}

.searchrefine_tab {
}

.searchrefine_header {
  padding-left: 5px;
  border-bottom: 2px solid $color_tundora_approx;
}

.searchrefine_suggestion {
  float: left;
  margin-left: 3px;
}

.searchrefine_alt_item {
  display: inline;
  margin-bottom: 5px;
  margin-left: 8px;
}

.searchrefine_hit_meter {
  background-color: $color_mountain_mist_approx;
}

.searchrefine_hit_meter-global {
  background-color: $color_torea_bay_approx;
}

.CuePointPanelButtons {
  float: right;

  .editButton {
    margin-right: 4px;
  }
}

.XmpCommonTabItem {
  position: relative;
}

.RenditionMetadataTab {
  position: relative;

  .XmpCommonTabItem {
    float: right;
    width: 590px;
    height: 100%;
    margin-left: 5px;
    border-left: $default-border;
  }

  .RenditionPlaceBundleButton {
    position: absolute;
    top: 160px;
    left: $spacer;
  }

  > img {
    position: absolute;
    bottom: 575px;
    max-width: 118px;
    margin: 10px;
    padding: 5px;
    border: $border-width solid $border-color;
  }
}

.searchrefine_strength {
  float: right;
}

.sort_label {
  float: right;
  margin-top: 4px;
  margin-right: 2px;
}

.video_comment_sort_list_box {
  float: right;
  margin-top: 3px;
}

.LoadingDockStatusItem {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.LoadingDockStatusToolBar {
  padding-bottom: 0 !important;
}

.ActionsPanel {
  border: $border-width solid $border-color;
}

.GeneratePdf {
  .detailPanel {
    width: 420px;
    height: 100px;
    cursor: pointer;

    img {
      padding-right: 4px;
      padding-left: 4px;
    }
  }

  .flexColumn.stretch {
    width: 460px;
  }
}

.extended-button {
  width: 140px;
  height: 24px;
  text-align: center;
  background-repeat: repeat-x;
}

.datebutton {
  margin-top: 0;
}

.templateSummaryPanel {
  margin: 4px;
  padding: 4px;
  border: $border-width solid $border-color;
  alignment: left;
}

.Notify-Users-cm {
  background-image: $url_mb_69;
}

.text-add {
  background-image: $url_mb_73;
}

.CS4-cm {
  background-image: $url_mb_74;
}

.CS3-cm {
  background-image: $url_mb_75;
}

.XMPR-cm {
  background-image: $url_mb_76;
}

.plus {
  @include size(18px);
  background: $url_mb_77;
}

.document-add {
  background-image: $url_mb_78;
}

.To-Metadata-Asset-cm {
  background-image: $url_mb_78;
}

.document-import {
  background-image: $url_mb_79;
}

.ScratchBasketTabBodyPanel {
}

.CommentBoard {
  position: absolute !important;
  top: 0;
  bottom: 120px;
  width: 100%;

  .scrollPanel {
    position: absolute !important;
    top: 0;
    bottom: 0;
    width: 310px;
    background-color: $color_alabaster_approx;
  }
}

.DateLabel {
  z-index: 1;
  top: 5px;
  right: 5px;
}

.response {
  font-style: italic;
  margin-right: 4px;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.video_annotated_image {
  position: absolute !important;
  bottom: 27px;
  margin-left: 2px;
}

.opacityHidden {
  opacity: 0;
}

.video_annotated_image_internal {
  opacity: 0;
}

.sort_panel {
  clear: right;
}

.loading_dock_download_single {
  @include size(24px);
  background-image: $url_mb_82;
}

.loadingDockActionButton {
  @include size(24px);
  margin-right: 8px;
}

.arrow_priority_base,
.baseButton {
  @include size(18px);
}

.faqForceCancel {
  opacity: 0.3;
}

.three-dimensional-view-button {
  position: relative;
  z-index: 1;
  top: -18px;
  left: -18px;
  padding: 4px;
  background-color: $black_70;
}

.screenMask {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
}

.scratchbasket-drop-target {
  position: absolute;
  z-index: 1;
  bottom: 0 !important;
  left: 0;
}

.BCPlayerRegularStyle {
  position: absolute;
  z-index: 30;
}

.DeleteButton-LDItem {
  position: absolute;
  z-index: 2;
  top: -4px;
  right: -4px;
}

.ErrorButton-LDItem {
  position: relative;
  top: -8px;
  left: -8px;
}

.DownloadButton-LDItem {
  position: relative;
  top: -12px;
  left: -12px;
}

.DownloadButton-Label {
  width: 60px !important;
  margin-right: -8px !important;
  margin-bottom: -8px !important;
  text-align: center;
}

.FileLabel-LDItem {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Organizer {
  .OrganizerDetails {
    width: 100%;
  }
}

.UploadToFolderNotification {
  box-shadow: 4px 4px 5px $color_suva_gray_approx;
}

.LinkInfoTopHolder {
  position: absolute;
  top: -12px;
  left: 12px;
}

.xmp-popup-panel {
  background-color: $color_soapstone_approx;
}

.xmp-button {
  width: $spacer;
  height: 24px;
  text-align: center;
}

.xmp-tab-arrow {
  margin-top: 3px;
  border: 1px solid $pink;
  background-color: $pink;
  azimuth: right;
}

.xmp-tab-item {
  width: 98%;
  margin-left: 2px;
  vertical-align: bottom;
}

.tab-bar-button {
  height: 20px;
  margin: 2px 2px 1px 10px;
  text-align: center;
}

.XmpTabItemExtended {
  border: 0;
}

.xmp-data-table {
  width: 100%;
}

.cell-background-c {
  background-color: $color_celeste_approx;
}

.cell-background-e {
  background-color: $color_gallery_approx;
}

.tab-item-title-align {
  margin-top: 3px;
}

.xmp-colomn-name-width {
  width: 20%;
}

.xmp-colomn-data-width {
  width: 80%;
}

.xmp-tab-active-lable {
  color: $atomic_tangerine;
}

.xmp-tab-item-more {
  background-color: $atomic_tangerine;
}

.xmp-item-title-hidden {
  background-color: $color_mountain_mist_approx;
}

.separate-tab-margin {
  margin: 10px 1px 5px;
}

.xmp-tab-header-margin {
  margin: 5px 1px;
}

.ExtendedViewTabButton {
  top: 2px !important;
}

.report_thumbnail {
  margin-top: 10px;
}

.discussion_button {
  background-image: $url_mb_139;
}

.hover_discussion_button {
  background-image: $url_mb_140;
}

.down_discusion_button {
  background-image: $url_mb_141;
}

.page_thumbnail_highlight {
  margin-top: 3px;
  padding-top: 7px;
  padding-right: 7px;
  padding-left: 7px;
  background-color: $color_celeste_approx;
}

.page_num_label {
  margin-top: 3px;
  padding-bottom: 3px;
}

.page_num_label_highlight {
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: $color_celeste_approx;
}

.page_thumbnails_panel {
  background-color: $color_athens_gray_approx;
}

.page_full_panel {
  background-color: $color_gallery_approx;
}

.publish_info_panel {
  margin-left: 10px;
}

.publish_text_panel {
  margin-top: 4px;
  margin-left: 10px;
  border: $border-width solid $border-color;
  background-color: $white;
}

.PlacedImageCell {
  min-width: 125px;

  .placeholder {
    height: 110px !important;
  }
}

.slide_selection_panel {
  border-top: 1px solid;
  background-color: $color_athens_gray_approx;
}

.ScrollAreaDropTarget {
  position: absolute;
  width: 100%;
  height: 10px;
}

.BundleOpaqueButton {
  background: $url_mb_145 no-repeat 5px;
}

.BundleTransparentButton {
  background: $url_mb_6 no-repeat 5px;
}

.bundleLabel {
  width: 400px;
}

.indented {
  padding-left: 20pt;
}

.gray-rule {
  width: 1px;
  background-color: $color_gallery_approx;
}

.view-search-bundle-members {
  background-image: $url_mb_149;
}

.apply-search-bundle-criteria {
  background-image: $url_mb_150;
}

.import-legend {
  color: $red !important;
}

.Attach-asset-feed-cm {
  background-image: $url_mb_160;
}

.rss-manager-icon {
  float: left;
  width: 20px;
}

.WorkflowDetailsPanel {
  .CodeEditor {
    height: 800px;
  }
}

.permissionworkflowcheckbox {
  font-weight: $font-weight-bold;
  padding: 5px;
}

.Bundle-folder-cm {
  background-image: $url_mb_6;
}

.BundleManagementIcon-parent {
  background-image: $url_mb_6;
}

.BundleManagementIcon-managed {
  background-image: $url_mb_164;
}

.BundleManagementIcon-unmanaged {
  opacity: 0.3;
  background-image: $url_mb_164;
}

.Remove-Metadata-folder-cm {
  background-image: $url_mb_145;
}

.Unbundle-folder-cm {
  background-image: $url_mb_145;
}

.TransformationDisclosure {
  top: 10px;
  right: 4px;
  transition: transform 0.1s ease-out !important;
}

.AbstractTransformationActionPanel {
  border-bottom: $default-border;
  background-color: $md-gray-100;

  .standard-text-box {
    position: absolute !important;
    top: 0 !important;
    right: 4px;
    margin-bottom: 5px;
  }
}

.cropMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color_cloud_80_approx;
}

.dragCorner {
  position: absolute;
  right: 0;
  bottom: 0;
  @include size(8px);
  cursor: move;
  background-color: $gray;
}

.RenditionFieldDefaultHeight {
  min-height: $spacer;
}

.RenditionButton {
  padding: 0 0.563rem 0 1.663rem;
  background-repeat: no-repeat;
  background-position: 5px;
}

.RenditionsEditButton {
  background-image: $url_mb_168;
}

.RenditionsDeleteButton {
  background-image: $url_mb_169;
}

.RenditionsRegenerateButton {
  background-image: $url_mb_170;
}

.ButtonHolder {
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
}

.noRendition {
  .ButtonHolder {
    position: relative;
    bottom: -25px !important;
    float: right;
  }

  .CuePointsHolder {
    height: 369px;
    border: none !important;
  }

  .CuePointsWrapperWide {
    width: 306px;
  }

  .CuePointsWrapperNarrow {
    width: 290px;
  }

  .CuePointsEditPanel {
    top: 358px;
    background-color: $white !important;
  }

  .CuePointEditPanelHeader {
    background-color: $white !important;
  }
}

.RenditionPlaceBundleButton {
  background-image: $url_mb_171;
}

.CuePointsNewButton {
  background-image: $url_mb_172;
}

.EditableInfoBlock {
  .clickable,
  .nonClickable {
    min-height: $spacer;
  }
}

.BrightcoveVideo-expanded {
  outline: 0;
}

.CuePointHeader {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.CuePointsHolder {
  height: 462px;
  @extend .pretty-scroll !optional;
  border-bottom: $default-border;
}

.CuePointsHolderScroll,
.CuePointsHolderNone {
  overflow-y: inherit;
}

.CuePointsWrapperWide {
  width: 196px;
}

.CuePointsWrapperNarrow {
  width: 180px;
}

.CuePointPanel {
  width: 100%;
  padding-right: 4px;
}

.CuePointName {
  font-weight: $font-weight-bold;
}

.CuePointIndent {
  text-indent: 10px;
}

.CuePointTimeLinePanel {
  .x-panel-header {
    line-height: 13px;
    width: 100%;
    padding: 0;
  }

  .standard_font {
    text-align: center;
  }
}

.CuePointsEditPanel {
  position: relative;
}

.CuePointEditPanelHeaderLabel {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  position: absolute;
  top: 4px;
  left: 4px;
}

.CuePointEditPanelHolder {
  position: absolute;
  width: 100%;
  padding: 3px;
}

.ForceCheckBoxHolder {
  @include size(100%, 17px);
}

.CuePointExistsWarning {
  font-weight: 700 !important;
  color: color("red");
}

.CuePointMarkerInfoAd {
  text-align: center;
}

.CuePointMarkerInfoCode {
  text-align: center;
  background-color: $color_jordy_blue_approx;
}

.CuePointMarkerPanel {
  position: absolute;
  z-index: 1;
}

.CuePointMarkerCode {
  @include size(17px, 157px);
  background-image: $url_mb_173;
}

.CuePointMarkerAd {
  @include size(17px, 157px);
  background-image: $url_mb_174;
}

.CuePointTimeLineBuffer {
  position: absolute;
  top: 13px;
  @include size(6px, 130px);
  background-color: $black;
}

.CuePointTimeLineBufferRight {
  right: 0;
}

.CuePointTimeMarkerPanel {
  position: absolute;
  top: 13px;
  @include size(1px, 157px);
}

.CuePointTimeMarkerTop {
  @include size(1px, 50px);
  margin-left: 8px;
  background: $black_50;
}

.CuePointTimeMarkerBottom {
  position: absolute;
  top: 80px;
  @include size(1px, 50px);
  margin-left: 8px;
  background: $black_50;
}

.SimpleDownloadLabels {
  width: 100%;
}

.dashboardTitle {
  color: $white;

  &::before {
    position: absolute;
    top: 0;
    left: 3px;
  }
}

.date-filter-label-general {
  min-width: 50px;
  margin: 0;
  text-align: right;
}

.feed_icon {
  height: 18px;
  margin: auto auto auto 10px;
  background: $url_mb_160 no-repeat;
}

.backToMediaBeacon {
  font-weight: $font-weight-500;
  color: $white !important;
}

.iconFrame {
  width: 100%;
  height: 600px;
  border: none;
}

.ViewTask {
  top: 4px;
  right: 4px;
}

.nextSlide {
  order: 3;
  margin-right: 20px;
}

.prevSlide {
  order: 1;
  margin-left: 20px;
}

.nextSlide,
.prevSlide {
  width: 22px;

  &:hover,
  &:active {
    background-color: $pink;
  }

  &.disabled {
    &:active,
    &:hover {
      background-color: transparent;
    }
  }
}

.actionContainer {
  position: absolute;
  top: 5px;
  right: -73px;
  background-color: $white_80;
}

.filename {
  font-weight: $font-weight-bold;
  position: absolute;
  z-index: 2;
  bottom: 4px;
  left: 0;
  @include size(89px, 15px);
  @extend .text-truncate !optional;
  display: block;
  cursor: default;
}

.esc {
  position: absolute;
  z-index: 10000;
  top: 10px;
  left: 0;
  @include size(100%, 40px);

  > div {
    @include size(140px, 30px);
    line-height: ($spacer * 2);
    margin: auto;
    text-align: center;
    color: $white;
    border-radius: 10px;
    background: $black_50;
  }
}

.made {
  line-height: 40px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: $color_storm_dust_approx;
}

.LayoutDropZoneSettingButton {
  position: absolute;
  top: 0;
  right: 0;
  animation: fade-in 0.2s normal;

  &.fade-out {
    animation: fade-out 0.2s normal;
  }
}

.uploadHTMLContainer img {
  max-width: 100%;
}

.XmpFieldEditProgressPanel .x-grid3-td-values {
  width: 313px !important;
}

.extended-root-panel .DiscussionPanelHolder {
  position: relative;
  width: 300px;
}

.AVFullScreen .assetViewLeft {
  width: 0;
}

.HtmlFieldContainer .DefaultValueTextArea {
  border: $border-width solid $border-color;
  background: $white;
}

.ClickablePathPanel .standard_font.clickable:hover {
  background-color: $color_seashell_approx;
}

.SavedSelectionDialog .holder {
  @extend .pretty-scroll !optional;
}

.SelectionImportDialog,
.ExternalPortalPanel {
  .gridbox {
    margin: 4px;
    background-color: $color_alabaster_approx;

    > div {
      width: 40px;
      height: 40px;
    }
  }
}

.Success .ext-mb-warning::before,
.dashboard-passing .dashboardTitle {
  background-color: $md-green-a700;
}

.ResubmitVideosDialog .x-window-body > .standard_font {
  width: 350px;
  margin: 5px;
}

.newFolder .ActionDialogBox {
  min-width: 325px;
}

.BundleManagementIndicator .MBHoverChangeButton {
  @include size(18px);
  margin-right: 3px;
  background-position: 50%;
}

.AlignmentMenuItem .MBButton {
  margin: 2px 4px;
  padding: 2px;
}

.better .x-view-item {
  white-space: normal;
}

.gwt-DisclosurePanel .x-window-mc {
  border-radius: 4px 4px 0 0 !important;
}

.BaseWidget-subheader .x-toolbar {
  padding-right: 0;
  padding-left: 0;
}

.DownloadJavascriptsPanel .PermissionWorkflowEditPanelCodeEditor {
  border: $border-width solid $border-color;
}

.WidgetConfigPanel .x-window-mc > .x-window-body {
  width: auto !important;
}

.linkParent .gridbox {
  border: $border-width solid $border-color;
}

.RssManagerPanel .ButtonPanel {
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.highlightedAsset .filenamecolumn {
  background-color: $color_celeste_approx;
}

.DragDropBadge.FullBadgeContainer {
  padding-left: 0;
}

.StarCell img,
.DotPagingPanel .ext-chrome .FakeGXTTabItem {
  display: inline-block;
}

.showHiddenWorkspaces .DraggableTab.Tabhidden {
  display: inherit;
}

.UploadPanel .x-fieldset {
  margin-bottom: 0;
  padding: 5px;
}

.searchrefine_bg_over-global .DefaultHeader .standard_font {
  color: $white !important;
}

.searchrefine_bg .DefaultHeader {
  border: none;
  background-color: $color_bon_jour_approx;
}

.searchrefine_header-global .standard_font {
  font-size: $font-size-sm;
  color: $white !important;
}

.searchrefine_panel .gwt-DisclosurePanel {
  padding-left: 5px;
}

.admin-mode-animation * {
  transition: background-color 0.15s ease-in;
}

.copyButton-base::before {
  content: $icon_add_to_photos;
}

.childrenLoading .hasChildren::before {
  opacity: 0.5;
}

.Add-To-Basket .x-menu-item::before {
  content: $icon_add_shopping_cart;
}

.mobile .MediaPlayButton {
  opacity: 1;
}

.ThreeDimensionalDialog .gwt-Frame {
  @include size(100%);
  border: 0;
}

.CopyrightDisplayDialog .ScrollVertical {
  width: 350px;
  height: 200px;
}

.halfHeight .dateRangeBar {
  height: 50px;
}

.ScratchBasketCP .BaseWidget-title::before {
  content: "";
}

.BaseWidget-title {
  outline: none;
}

.RestoreWidget-root .x-button-bar {
  padding-top: 5px;
  border-top: $default-border;
}

.R3Search-Sparklines-Button-on {
  font-weight: 700 !important;
  color: $black !important;
}

.CreateWorkflowPanelBody input {
  margin-top: 5px;
  margin-bottom: 10px;
}

.TransformationScalePanel .TransformationActionPanelHeader {
  background: $url_mb_46 no-repeat 4px;
}

.TransformationGeneralPanel .TransformationActionPanelHeader {
  background: $url_mb_165 no-repeat 4px;
}

.TransformationRotatePanel .TransformationActionPanelHeader {
  background: $url_mb_166 no-repeat 4px;
}

.TransformationCropPanel .TransformationActionPanelHeader {
  background: $url_mb_167 no-repeat 4px;
}

.TransformationPanel .ImageHolder {
  position: absolute;
  top: 7px;
  right: 264px;
  bottom: 7px;
  left: 7px;

  img {
    transition: 0.35s ease-out;
  }

  .noAnimate img {
    transition: none;
  }
}

.MetadataBlock.border {
  border-left: $default-border;
}

.BrightcovePrePanel .gwt-Image {
  width: 480px;
  height: 274px;
}

.dashboard-indeterminant .dashboardTitle {
  font-weight: $font-weight-500;
  background-color: $web_orange;
}

.dashboard-failing .dashboardTitle {
  background-color: $red;
}

.ChangePasswordPanel > input {
  padding-left: 4px;
}

.annotated_image_opening > .annotation_box {
  pointer-events: none;
  opacity: 0 !important;
}

.page:hover {
  .AnnotationButton,
  .TJSAnnotation {
    opacity: 1 !important;
  }
}

.last .actionContainer {
  right: 400px;
}

.MBDialog.modal-view-resize-preview {
  right: inherit !important;
  left: 20px !important;
}

.placedImages .contain {
  @include size(80px);
}

.MetricsViewWidgetHeight {
  height: calc(100% - 308px);
}

.dnd-upload-link-info {
  position: absolute;
  top: 5px;
  left: 5px;

  .MBButton:before {
    color: $white;
  }
}

.MissingPlacedImagePanel {
  width: 150px;
  height: 180px !important;
}
