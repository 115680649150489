.sentinel {
  position: relative;
  display: block;
  width: 100% !important;
  height: 1px;
}

.x-layer {
  visibility: hidden;
}

.overflow-hidden,
.x-grid3-viewport {
  @extend .overflow-hidden !optional;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-visible {
  overflow: visible !important;

  > {
    .AbstractMultiField,
    .MBHierarchyField {
      height: auto !important;
    }
  }
}

.pretty-horizontal-scroll,
.VideoEditTimeline,
.smallAssetDisplay {
  overflow: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  // The vertical thumb
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 5px;
    background-color: rgba($black, 0.5);
    -webkit-box-shadow: none;
  }
}

.maxHeight,
.InddXmpTabItem .InddContent,
.x-grid3-scroller,
.pretty-scroll {
  @include mb-scroll-mixin(true, false);
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
}

.pretty-scroll-y {
  @include mb-scroll-mixin(true, false);
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
  backface-visibility: hidden;
}

.pretty-scroll-y-no-flex {
  @include mb-scroll-mixin(true, false);
  overflow-y: auto;
}

@include media-breakpoint-only(md) {
  .pretty-md-down-scroll {
    @include pretty-scroll-mixin();
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .overflow#{$infix}-hidden {
      overflow: hidden !important;
    }
    .pretty#{$infix}-scroll {
      @include pretty-scroll-mixin();
    }
  }
}

.pretty-scroll-shown {
  @include pretty-scroll-mixin(false);
}

.CodeMirror-vscrollbar {
  @include pretty-scroll-mixin(false, false);
}
