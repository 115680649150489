//
// Base Widget
//
.card-widget {
  .draggable-widget & {
    box-shadow: none;

    &.card-vertical-widget {
      //&.DropZone-dragging {
      //
      //  &::after {
      //    content: "";
      //    position: absolute;
      //    top: 0;
      //    right: 0;
      //    bottom: 0;
      //    left: 0;
      //    border: 1px solid $blue;
      //    @include border-radius($card-border-radius);
      //  }
      //}
      //.drop-zone-valid {
      //  background-color: $component-active-bg-light !important;
      //}
    }
  }

  .no-admin-mode & {
    &.minimized {
      &.titleless {
        &:not(.toggleable) {
          border: none;
        }
      }
    }

    &.titleless {
    }
  }

  &.is-closed {
    .card-header {
      border-color: transparent;
    }

    .btn-control-indicator {
      display: none;
    }
  }

  .SearchStatusPanel {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .dragdrop-movable-panel & {
    width: 100%;
  }

  .ext-el-mask {
    display: none !important;
    opacity: 0;
  }

  .logged-in & {
    .form-control:not(.form-group-chip-list) {
      @extend .form-control-sm;
    }
  }
}

.btn-control-indicator {
  position: absolute;
  z-index: 10;
  top: 50%;
  margin-top: -15px;
}

.btn-control-prev {
  left: ($spacer / 2);
}

.btn-control-next {
  right: ($spacer / 2);
}

.card-loading-mask {
  &.position-relative {
    //position: absolute !important;
    top: inherit !important;
    bottom: 0;
    width: 100%;

    > div {
      padding: 5px;
      border-radius: 5em;
      background-color: $white;
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }
  }
}

.BaseWidgetContentPanel {
  .FieldEditPanel {
    position: relative;
    background-color: $pink;

    &.edited .xmpButtonBar {
      position: relative;
    }
  }

  .QuickSearchIcon {
    display: none;
  }

  .statGraph {
    min-width: 10px;
    min-height: 10px;
  }

  .xmpButtonBar {
    background-color: $pink;
  }
}

.BaseWidgetFooter {
  .VideoEditTimeline {
    height: 157px;
  }

  .VideoEditTimeline-internal {
    width: 100%;
  }

  .VideoTrimOverlay {
    top: -1px;
  }

  .ScrollAreaDropTarget {
    bottom: 0;
    height: 0;
  }

  &.visible .ScrollAreaDropTarget {
    height: 10px;
  }
}

.MBHeaderRowDragBar {
  float: right;
  width: 3px;
  min-height: 27px;
  cursor: col-resize;
}

.MBHeaderRowGhostBar {
  position: absolute;
  z-index: 9999;
  width: 2px;
  cursor: col-resize;
  background-color: #007bff;
}

.ScratchBasket {
  .BaseWidgetHeader {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
}
