//
// Negative
//
.negative {
  .HoverStylePanel,
  .bg-negative {
    &:hover {
      border-color: $form-feedback-invalid-color !important;
      background-color: $form-feedback-invalid-color !important;

      &,
      &::before,
      &::after,
      .material-icon {
        color: $white !important;
      }

      .badge {
        background-color: darken($form-feedback-invalid-color, 15%);
      }
    }

    .btn-outline-primary {
      color: $white;
      border-color: $white;

      &:hover {
        background-color: darken($form-feedback-invalid-color, 15%);
      }
    }
  }
}

.negative .btn-negative:hover,
.negative-color {
  &.text-dark {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='#000' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");

    .material-icon {
      background-color: $white;
    }
  }

  .material-icon {
    border-radius: 5rem;
  }

  &.text-light,
  &.text-white {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='#fff' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");

    .material-icon {
      background-color: $black;
    }
  }
}

.searchStatusNegativeColor {
  background-image: url("../images/negativeColor.png");
  background-repeat: repeat !important;
}
