//
// Asset View
//
//.split,
//.split-flex {
//  overflow-y: auto;
//  overflow-x: hidden;
//}
//
//.split.split-horizontal,
//.gutter.gutter-horizontal {
//
//}
//
//.gutter {
//  background-color: $md-black-lighter;
//  background-repeat: no-repeat;
//  background-position: 50%;
//  opacity: 0;
//
//
//  &.gutter-horizontal {
//    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
//    cursor: ew-resize;
//  }
//
//  &.gutter-vertical {
//    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
//    cursor: ns-resize;
//  }
//
//  &.is-dragging,
//  &:hover,
//  &:focus {
//    opacity: 1;
//  }
//
//}
.VideoCommentEditPanel {
  .format-hint {
    display: none;
  }

  .custom-select {
    width: auto;
  }
}

.format-hint {
  font-size: ($font-size-base * 0.8);
  color: $text-muted;
}

.video-clip-holder {
  video {
    position: absolute;
    top: 0;
    @include size(100% !important);
    left: 0;
    object-fit: contain;
  }
}

.KeywordDrop:before,
.KeywordDrop:after {
  content: none;
}

.asset-view-media-panel {
  .annotated-image {
    .gwt-Image {
      opacity: 0 !important;
    }
  }
}

.asset-view-audio-panel {
  img,
  audio {
    max-width: 300px;
  }

  audio {
    &::-webkit-media-controls-panel {
      border-radius: $border-radius;
    }
  }
}

.CompareVersionPanel {
  .modal-xxl {
    max-width: 1650px !important;
  }
}

.LinkGroupPanel .card-grid-box:hover .infinite-scroll-item-badges {
  opacity: 1 !important;
}

.ReadOnly {
  .input-group-trigger-field {
    pointer-events: none;
    background-color: $input-disabled-bg;
  }
}

.btn-group-3d {
  top: $spacer;
  right: auto;
  left: auto;
  min-width: auto;
  margin-top: 0;
}

.modal-asset-view {
  .ClickablePathPanel {
    .col-field-form-element {
      .EditableFileLabel,
      .EditableFileLabel > * {
        display: inline;
      }
    }
  }

  .asset-select-badge {
    opacity: 1;
    color: $white;
  }

  &.asset-view-selected {
    .asset-select-badge {
      .material-icon {
        color: $primary !important;
        z-index: 1 !important;
        transform: scale(1.6);
      }
    }
    .asset-select-background {
      opacity: 1;
      color: $white;
      background-color: $white !important;
      width: 17px;
      height: 17px;
      //z-index: 0 !important;
    }
  }

  .max-height-46 {
    height: 46px;
  }

  .modal-content {
    background-color: $black;
  }

  .dissolvingImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include size(100%);
  }

  .timeline-empty-label {
    display: none;
  }

  .toggleMetadata {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fullScreen {
    display: none;
  }

  .FieldEditPanel {
    position: relative;
  }

  .VideoTrimOverlay {
    top: -9px;
  }

  .DiscussionPanelHolder {
    height: 100%;
  }

  .alignLeftButton {
    top: 8px !important;
    right: 8px !important;
  }

  .collapsed {
    top: 150px;
    right: 0;
    background-color: $black_20;
  }

  .MBButton {
    &:hover {
      background-color: transparent !important;
    }
  }

  &.AVSplitScreen {
    .toggleMetadata {
      background: $color_mercury_approx;

      &:before {
        color: $color_tapa_approx !important;
      }

      &:hover {
        background-color: $color_mercury_approx !important;
      }
    }
  }

  .selected {
    .AssetViewTabLabel {
      color: $white !important;
    }
  }

  .tab {
    &.selected {
      margin-bottom: 0;
      background-color: theme-color("primary");
    }
  }

  &.match-media-sm {
    .col-field-label,
    .col-field-form-element {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-field-label {
      padding-top: 0;
    }
  }

  &:not(.match-media-sm) {
    .EmptyFieldToggle {
      margin-bottom: $spacer;
    }
  }

  .x-window-header {
    .x-window-header-text {
      line-height: 24px;
      display: block;
      overflow: hidden;
      width: 980px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .MediaPlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;

    span {
      transform: scale(2.5);
    }
  }

  .m3taform_placeholder {
    .waiting {
      margin-bottom: -15px;
    }
  }

  .ThreeDimensionalPanel {
    &,
    .ThreeDimensionalPanelImage {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: 100% !important;
    }

    .btn-media-play {
      background-color: transparent;
    }

    .annotated-image {
      height: 100%;
    }

    .three-dimensional-view-button-wrapper {
      position: absolute;
      z-index: 6;
      top: 50%;
      left: 50%;
      width: 0 !important;
      height: 0 !important;
    }
  }

  .RDB {
    .EditFieldContentPanel {
      position: inherit;
      top: inherit;
      right: inherit;
      bottom: inherit;
      left: inherit;
    }
  }

  .noRendition {
    .VideoEditTimeline {
      bottom: -21px;
      left: 8px !important;
      height: 205px !important;
    }
  }

  .MultiPagePanel .FullScreenPanelCloseButton:before {
    color: white;
  }

  .AnimationPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .annotated-image {
      height: 100%;
    }

    .img-fluid {
      max-height: 100%;
    }

    .closeButton-base {
      position: absolute;
      top: 0;
      right: 5px;
      color: #fff;
    }
  }

  .meta-form-panel {
    .avButtonPlace {
      height: ($spacer * 5);
    }
  }

  .Download-all-cm:before {
    position: relative;
    top: -3px;
    color: hsla(0, 0%, 100%, 0.9);
  }
}

// Animation Class to transition between full screen and split screen.s
.is-animating {
  // Set the transition on the .is-animating so it doesn't get picked up with next/previous
  .asset-view-left,
  .asset-view-right {
    transition: all $swift-ease-in-out-duration $swift-ease-out-timing-function;
  }

  // Kill the transition on the accordion
  .accordion-tab-body {
    transition: none !important;
  }

  // Kill the drag container if it shows up
  .asset-view-drag-container {
    display: none;
  }
}

.asset-view-drag-container {
  overflow: visible !important;
  width: 100%;

  .modal-full-screen & {
    display: none;
  }

  .dragdrop-movable-panel {
    top: 0 !important;
    bottom: 0;
    left: 0 !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

.dragdrop-dropTarget-engage {
  & + .asset-view-left {
    pointer-events: none;

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }
}

.col-field-label {
  font-weight: 500;
}

.col-field-form-element .field-holder {
  margin-bottom: ($spacer * 0.75);
}

.asset-view-header {
  z-index: 2;
  @include mat-elevation-transition();
}

.vertical-drag-container {
  position: absolute;
  z-index: $zindex-tooltip;
  width: ($spacer * 1.5);
  height: 100% !important;

  .vertical-drag-handle {
    transition: opacity $swift-ease-out-duration $swift-ease-out-timing-function;
    opacity: 0;
  }

  &.dragdrop-dragging {
    opacity: 1;
  }

  &:hover,
  .dragdrop-dropTarget-engage & {
    .vertical-drag-handle {
      opacity: 1;
    }
  }
}

.vertical-drag-handle {
  margin-left: -20px;
}

.card-meta-data-wrapper {
  height: 0;
  transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
  transform: translateY(100%);

  &.show {
    height: auto;
    transform: none;
  }
}

.asset-view-col {
  position: relative;
  display: flex;
  align-content: stretch; // Extra space in Cross Axis
  align-items: stretch; // align items in Cross Axis
  flex-direction: column;
  justify-content: flex-start; // align items in Main Axis
  width: 100%;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  min-height: 1px; // Prevent collapsing
  contain: strict;
  backface-visibility: hidden;
  //will-change: max-width, flex, -ms-flex;
  @include media-breakpoint-up(md) {
    position: absolute !important;
    overflow: hidden;
    height: 100vh;
    max-height: 100vh;
  }
}

.asset-view-left {
  @include media-breakpoint-up(md) {
    left: 0;
    @include make-col(8);
    .modal-full-screen & {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 5;
    top: 50%;
    margin-top: -40px;
    @include size(80px);
    cursor: pointer;
    @include hover-focus {
      opacity: 1;
    }
  }
}

.asset-view-right {
  transform: translate3d(0, 0, 0);
  background-color: $body-bg;
  @include media-breakpoint-up(md) {
    right: 0;
    @include make-col(4);
  }

  .modal-full-screen & {
    transform: translate3d(100%, 0, 0);
  }

  .xmpButtonBar {
    position: fixed;
    z-index: 3;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    padding: $spacer ($spacer * 1.5);
    background-color: $white;
    box-shadow: 0 -1px 5px rgba($black, 0.2);

    &.edited {
      display: flex;
    }
  }

  .alert {
    margin-bottom: 0;
  }
}

.carousel-control-wrapper {
  border-radius: 28px;
  background-color: rgba($md-gray-800, 0.75);
  @include size(56px);
}

.carousel-control-icon {
  fill: $white;
}

.meta-form-panel {
  .card-meta-form {
    &:last-child {
      margin-bottom: ($spacer * 6);
    }
  }
}

.card-meta-form {
  margin-bottom: ($spacer * 0.75);

  .form-group-field-list {
    .col-form-label {
      font-size: $font-size-sm;
      font-weight: bold;
    }

    .mb-checkbox,
    .mb-radio label {
      padding-left: ($spacer * 2);

      &::before {
        left: 0;
      }

      &::after {
        left: -9px;
      }
    }

    &:last-child {
      .col-field-form-element {
        margin-bottom: 0;
      }
    }
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    cursor: pointer;
  }

  &:not(.active) {
    .card-accordion-header {
      border: none;
      background-color: transparent;
    }
  }
}

.header-footer {
  position: absolute;
  z-index: $zindex-modal;
  top: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  padding: ($spacer * 1.5);
  @include mat-elevation(4);
  transition: all $swift-ease-out-duration $swift-ease-out-timing-function;
  transform: translateY(-100%);
  background-color: $white;

  .show-meta-form-add-combobox & {
    visibility: visible;
    transform: translateY(0);
  }
}

.asset-view-image-edit-col {
  flex: 0 0 400px;
  max-width: 400px;
}

.asset-cropper-preview {
  @include square(200px);
}

.asset-view-compare-label {
  bottom: $spacer;
  left: 50%;
  transition: opacity 200ms ease-in-out;
  transform: perspective(1px) translateX(-50%);
  opacity: 0;
  background-color: $md-black-dark;
}

.image-edit-canvas-container {
  &:hover {
    .asset-view-compare-label {
      opacity: 1;
    }
  }
}

.aspect-ratio-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0 !important;
  min-height: 0;
  max-height: 100%;
  margin: 0;
  padding: 0;
  $a_local: ($spacer / 2);

  .embed-responsive-item {
    position: absolute;
    top: $a_local;
    right: $a_local;
    bottom: $a_local;
    left: $a_local;
    border: 0;
  }
}

.ui-bs-4-menu {
  .StampPanelHandle,
  .StampPanelEditButton,
  .stampBlank {
    display: none;
  }
}
