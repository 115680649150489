//
// Color Search Widget
//
//.ColorSearchWidget {
//
//}
.colorSearchStatusItem:after {
  font-weight: 700 !important;
  color: $white !important;
}

.colorButton-missing {
  cursor: default !important;
  background-color: $body-bg !important;

  &:hover {
    transform: inherit !important;
  }
}

.addedColor {
  &:hover {
    transform: inherit !important;
  }
}

.btn-color {
  border: none;
  @include hover-focus {
    &:not(:disabled):not(.disabled) {
      z-index: 2;
      transform: scale(1.3);
      box-shadow: 0 5px 15px rgba($black, 0.5);
    }
  }

  &.disabled {
    z-index: 0;
    transform: none !important;
    opacity: 0 !important;
    box-shadow: none !important;
  }
}

.color-missing {
  opacity: 0.15 !important;
}
