// stylelint-disable declaration-no-important
// Flex variation
.d-flex-col-fill {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.d-flex-auto {
  flex: 0 0 auto;
  width: auto !important;
  max-width: none;
}

.d-flex-card-deck-open {
  > .card.is-open {
    // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
    flex: 1 0 0%;
    min-height: 0;
    margin-bottom: 0;

    .collapse.show {
      display: flex;
      flex: 1 1 auto;
      height: 100% !important;
      min-height: 0;

      .CodeMirror,
      .CodeEditor {
        @include size(100% !important);
      }
    }
  }
}

.noShrink,
.card-header,
.flex-shrink-none {
  @extend .flex-shrink-0 !optional;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.flex-grow-none {
  @extend .flex-grow-0 !optional;
}

.flex-truncate {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flex-fill-parent {
  @include size(100%);
  display: flex;
  align-content: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}

.fill-area {
  display: flex;
  align-content: stretch; // Extra space in Cross Axis
  align-items: stretch; // align items in Cross Axis
  flex-direction: row;
  justify-content: flex-start; // align items in Main Axis
}

.flex-basis-0px {
  flex-basis: 0px !important;
}

//
// Auto Generate .flex (grow) classes
//
// stylelint-disable
$flex-basis: ();
$flex-basis: map-merge(
  (
    "0": 0,
    "20": 20%,
    "30": 30%,
    "40": 40%,
    "50": 50%,
    "60": 60%,
    "70": 70%,
    "80": 80%,
    "350px": 350px,
  ),
  $flex-basis
);
@each $key, $value in $flex-basis {
  .flexBasis#{$key} {
    flex-basis: $value;
  }
}

//
// Auto Generate .flex (grow) classes
//
$flex-grow: ();
$flex-grow: map-merge(
  (
    "Initial": 0,
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
  ),
  $flex-grow
);
@each $key, $value in $flex-grow {
  .flex#{$key} {
    @if $key == "1" {
      flex: auto;
    }
    flex-grow: $value;
  }
}
