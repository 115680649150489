//
// Vertical Solr Widget
//
.card-vertical-solr-widget {
  > .card-header {
    border: none;
  }

  .no-admin-mode & {
    &.titleless {
      .card-group-flush {
        .card {
          &:first-child {
            border-top: 0;
            @include border-top-radius($card-border-radius);
          }

          &:last-child {
            @include border-bottom-radius($card-border-radius);
          }
        }
      }
    }
  }
}

.SolrMoreButton {
  padding-left: $spacer;
  text-decoration: underline;
  @extend .cursor-pointer;
}

.solr_warning_link {
  text-decoration: underline;
  @extend .cursor-pointer;
}

.SolrHierarchyFacetPanelValueHolder {
  padding-right: 3px;
  padding-left: 3px;
}

.solrSearchItem:after {
  text-transform: none;
}

.VerticalSolrConfigPanel .x-grid-panel {
  height: 250px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.SolrHierarchyTermLabel {
  .SolrLink {
    &.showingIcon {
      padding-left: 35px !important;
      background-position: $spacer;

      &.hasChildren {
        padding-left: 40px !important;
        background-position: 21px;

        &:before {
          position: absolute;
          left: 5px;
        }
      }
    }

    &.hasChildren {
      padding-left: 5px;
    }
  }

  .cp {
    padding-left: $spacer;
    text-align: left;
  }
}

//.btn-clear-filter,
.facet-action-panel {
  position: absolute;
  top: $card-spacer-y / $line-height-base;
  right: $card-spacer-x / $line-height-base;
  //  padding: $card-spacer-y $card-spacer-x;
}

.SolrHierarchyTermLabel {
  .list-group-item {
    border: none;
  }
}

.more-filter-field {
  .btn-chip {
    word-break: break-word;
  }
}

.LabelFacetPanel {
  .value {
    word-break: break-word;
  }
}
