// stylelint-disable declaration-no-important
// Margin and Padding
.marginBottom50 {
  margin-bottom: 50px;
}

.px-2px {
  padding-right: 2px;
  padding-left: 2px;
}
