//
// Calendar View Widget
//
.card-calendar-view-widget {
  .mb-header-grid .x-grid3-scroller {
    display: block;
  }

  .CalendarView-backToMonth {
    background-image: url("../images/back-to-month-icon-base.png");
  }

  .CalendarView-backToMonth-over {
    background-image: url("../images/back-to-month-icon-hover.png");
  }

  .CalendarView-backToMonth-click {
    background-image: url("../images/back-to-month-icon-click.png");
  }

  .CalendarViewIcon:before {
    content: "event_note";
  }

  .CalendarView-monthViewHeader {
    margin: 5px !important;
  }

  .CalendarView-myInternalTable-Cell {
    border-right: $default-border;
    border-bottom: $default-border;
  }

  .CalendarView-mainMonthViewTable {
    border-top: $default-border;
    border-left: $default-border;
  }

  .CalendarView-numericDayHtml {
    float: right;
    margin: 5px;
    color: $color_celeste_approx;
    @extend .cursor-pointer;
  }

  .CalendarAsset {
    padding: 2px;
  }

  .CalendarButton {
    position: absolute;
    top: 0;
    right: 37px;
    bottom: 0;

    &.x-form-trigger {
      &:hover {
        background-color: $color_seashell_approx !important;
      }
    }
  }

  .TodayButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    &.x-form-trigger {
      &:hover {
        background-color: $color_seashell_approx !important;
      }
    }
  }
}
