//
// Search Status Widget
//
.card-search-status-widget {
  //&,
  //.card-header,
  //.card-footer {
  //  border: 0;
  //  border-radius: 0;
  //  background-color: transparent;
  //}
  @extend .card-normalize-styles;

  .btn-chip {
    background-color: rgba($black, 0.1);
  }

  /*.btn-chip.TextBoxItem {
    max-width: 75%;

    @extend .text-truncate;

    .chip-value {
      @extend .text-truncate;
    }
  }*/
}

.TextBoxItem.MandatorySearch {
  &::after {
    display: none;
  }
}

.TextBoxItem.editable-search-status {
  &::after {
    display: none;
  }

  input {
    @extend .py-0;
    height: 22px !important;
  }
}

.search-status-container {
  .btn {
    &:not(.btn-chip) {
      margin-left: 0.25rem;
    }
  }

  .btn-chip {
    margin-bottom: 0;
  }

  .folder-only {
    &:before {
      @extend .material-icon;
      font-size: 18px;
      position: relative;
      top: 1px;
      margin-right: 6px;
      content: "folder";
      cursor: pointer;
    }
  }
}

.SearchStatusPanel {
  &.dropdown-menu {
    .homebutton-base,
    .menu-save-button {
      display: none;
    }

    .search-status-panel {
      max-width: 400px;

      .TextBoxItem .chip-value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
