// stylelint-disable declaration-no-important
//
// Border
//
//.border-top {
//  border-top: $border-width solid $default-border-color;
//}
//
//.border-bottom {
//  border-bottom: $border-width solid $default-border-color;
//}
.bordered {
  @extend .border;
}

.border-black {
  border-color: $black !important;
}

//
// Border-radius
//
.circle {
  border-radius: 5rem !important;
}

.has-border-after {
  position: relative;
  padding-bottom: $spacer;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    content: "";
    background-color: $border-color;
  }
}

.rounded-top-cap {
  border-radius: $border-radius $border-radius 0 0 !important;
}

.rounded-bottom-cap {
  border-radius: 0 0 $border-radius $border-radius !important;
}

.left-border-gray {
  border-left-color: $color_quill_gray_approx !important;
  border-left-width: 3px !important;
}
