//
// Login Widget
//
.card-login-widget {
  width: 90%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    width: 100%;
  }

  .logged-out &,
  .logged-in.no-admin-mode & {
    z-index: 10;
    margin-top: $spacer;
    margin-bottom: $spacer;
    background-color: $white;
    @extend .wf-10;
    @include media-breakpoint-up(sm) {
      margin-top: $spacer * 5;
      margin-bottom: $spacer * 5;
    }

    .card-header {
      display: none;
    }
  }
}

.LoginWidget {
  .admin-mode.logged-out & {
    top: 65px;
  }

  .BaseWidget-buttons {
    display: inherit;
  }
}

.logoHolder {
  height: 45px;
  background-image: url("../images/logo-MediaBeacon.png");
}
