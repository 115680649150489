.treeMapChart {
  .d3plus_data {
    cursor: pointer;
    opacity: 1 !important;
    stroke: $white !important;
  }

  #d3plus,
  #d3plus #bg {
    fill: transparent !important;
  }
}

.treeMapLegend {
  margin-top: 16px;
}

#d3plus_tooltip_id_tree_map {
  box-shadow: none !important;

  .d3plus_tooltip_container {
    @include border-radius($border-radius-lg);
    display: flex;
    flex-direction: row;
    width: auto !important;
    margin-top: 10px;
    margin-left: 10px;
    box-shadow: 0 8px 10px 1px rgba($black, 0.14), 0 3px 14px 2px rgba($black, 0.12), 0 5px 5px -3px rgba($black, 0.2);

    > * {
      z-index: 1 !important;
    }

    .d3plus_tooltip_header {
      text-transform: lowercase;

      &:before {
        font-size: 24px;
        line-height: 21px;
        margin-right: 3px;
        content: "●";
      }
    }

    .d3plus_tooltip_title {
      font-family: $font-family-sans-serif !important;
      font-size: 14px !important;
      width: auto !important;
      padding-left: 6px;
      color: black !important;
    }

    .d3plus_tooltip_data_container {
      line-height: 18px;
      @extend .overflow-hidden !optional;
      display: flex;
      flex-direction: row;
      max-height: none !important;

      .d3plus_tooltip_data_block:first-child {
        padding-left: 4px !important;

        .d3plus_tooltip_data_value {
          &:before {
            content: none !important;
          }

          &:after {
            content: none !important;
          }
        }
      }

      .d3plus_tooltip_data_seperator {
        width: 3px !important;
        margin: 0 !important;
      }

      .d3plus_tooltip_data_block {
        padding: 0 !important;

        .d3plus_tooltip_data_name {
          display: none !important;
        }

        .d3plus_tooltip_data_value {
          font-family: $font-family-sans-serif !important;
          font-size: 14px !important;
          font-weight: bold;
          position: relative !important;
          right: 0 !important;
          width: auto !important;

          &:before {
            content: "(";
          }

          &:after {
            content: ")";
          }
        }
      }
    }

    .d3plus_tooltip_footer {
      display: none;
    }
  }

  .d3plus_tooltip_arrow {
    display: none;
  }
}
