//
// Alerts
//
////////// DIRECT COPY FROM BS4 //////////
.alert {
  position: relative;
  margin-bottom: $alert-margin-bottom;
  padding: $alert-padding-y $alert-padding-x;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.
.alert-dismissible {
  padding-right: ($close-font-size + $alert-padding-x * 2);
  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}

@function alert-color($key: "primary") {
  @return map-get($alert-colors, $key);
}

@mixin alert-icon-variant($background) {
  color: $body-color;
  background-color: $md-gray-200;
  //border-color: $background;
  .alert-icon-holder {
    @if ($background == alert-color("secondary")) {
      color: alert-color("primary");
      @include gradient-bg(darken($background, 5%));
    } @else {
      color: color-yiq($background);
      @include gradient-bg($background);
    }
  }
  //hr {
  //  border-top-color: darken($background, 5%);
  //}
}

.alert-icon {
  overflow: hidden;
  padding: 0;
  border: none;

  .alert-icon-holder {
    padding: $alert-padding-y ($alert-padding-x / 2);
  }

  .media-body {
    padding: $alert-padding-y $alert-padding-x;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
@each $color, $value in $alert-colors {
  .alert-#{$color} {
    @include alert-variant(lighten(alert-color($color), 50%), alert-color($color), color-yiq(alert-color($color)));
  }
  .alert-icon-#{$color} {
    @include alert-icon-variant(alert-color($color));
  }
}

.alert-info {
  background-color: $md-blue-50;
}

.alert-secondary {
  color: $body-color;
  border-color: $md-gray-100;
  background-color: $md-gray-200;

  .material-icon {
    color: theme-color("primary");
  }
}

.alert-danger {
  color: $body-color;
  border-color: $md-red-500;
  background-color: $white;

  .alert-icon-holder {
    color: $white;
    background-color: $md-red-500;
  }
}

.alert-success {
  color: $body-color;
  background-color: $white;

  .alert-icon-holder {
    color: $white;
    background-color: $md-green-500;
  }
}

.alert-primary {
  color: $body-color;
  background-color: $white;

  .alert-icon-holder {
    color: $white;
    background-color: theme-color("primary");
  }
}

.alert-component-error {
  .photo-wall & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2rem;

    &::before {
      font-size: 4rem;
      margin: 2rem auto 1rem;
      content: $icon_collections;
      opacity: 0.5;
    }
  }
}

.alert-media {
  overflow: hidden;
  border: none;
  background-color: $md-gray-50;
}
