//
// Badges
//
.badge-notification {
  font-size: 10px;
  line-height: 1.3;
  z-index: 1;
  right: 50%;
  min-height: 13px;
  padding: 1px 3px;
  transform: translateX(100%);
  color: #fff;
  border-radius: 2px;
  background-color: #fa3e3e;
  -webkit-font-smoothing: subpixel-antialiased;
}

.notification-icon-badge {
  font-size: 90%;
  width: auto;
  height: auto;
  padding: 0.2rem;
}

.badge-discussion-count {
  background-color: $md-black-lighter;
}

.badge-secondary-dark {
  color: $white;
  background-color: rgba($black, 0.45);
}

.badge-asset-count {
  margin-left: $spacer / 2;
  padding-left: $spacer;
}

.badge-danger-light {
  color: $md-red-a700;
  background-color: $md-red-100;
}

.badge-light {
  background-color: $md-gray-200;
}

.badge-tree-count {
  background-color: $default-hover-bg;
}

.badge-and-or {
  opacity: 0.7;
}

.btn-icon-inverse.asset-select-badge {
  background-color: transparent !important;
  color: $md-light-icons-inactive;
  transition: transform 0.1s ease-out;
  transform: scale(0.9);
  outline: none !important;
  box-shadow: none;

  .material-icon {
    outline: none !important;
    box-shadow: none;
  }

  :hover {
    color: $white;
  }

  .active & {
    color: $primary !important;
    opacity: 1 !important;
    transform: scale(1.1);
  }
}

.asset-select-background {
  z-index: -1;
  background-color: transparent !important;
  .active & {
    width: 18px;
    height: 18px;
    background-color: $white !important;
  }
}

.asset-badge-panel {
  z-index: 3;
  top: 0;
  left: 0;

  .asset-badge {
    &:only-child,
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.playable-asset {
  &.asset-badge-panel {
    padding-top: 45px !important;
  }
}

.asset-badge {
  img {
    transform: scale(0.8);
  }
}

.badge-show-hover {
  .tile-view-image-grid &,
  .infinite-scroll-item & {
    visibility: hidden;
    transition: transform 300ms $swift-ease-out-timing-function;
    transform: translateY(-100%) scale(0);
  }

  .tile-view-image-grid:hover &,
  .infinite-scroll-item:hover & {
    visibility: visible;
    transform: translateY(0) scale(1);
    will-change: transform, visibility;
  }
}
