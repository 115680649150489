@import "~cropperjs/src/index.scss";

.cropper-point,
.cropper-point.point-se:before,
.cropper-line {
  background-color: $md-blue-a400;
}

.aspect-ratio-box-inside {
  padding: 4px;
}

.cropper-view-box {
  outline: 1px solid $md-blue-a400;
  outline-color: rgba($md-blue-a400, 0.75);
}

.btn-image-filter {
  width: ($spacer * 7);
  color: $white;
  background-color: transparent;

  .text-center {
    transition: opacity 0.15s $swift-ease-in-out-timing-function;
  }

  &:hover,
  &:focus,
  &.focus {
    border-color: transparent;
    box-shadow: none;

    .text-center {
      opacity: 1 !important;
    }
  }

  .embed-responsive {
    transition: transform 0.15s cubic-bezier(0, 0, 0.6, 1);
    transform: scale(0.8);
    will-change: transform, box-shadow;
  }

  &.is-selected {
    .embed-responsive {
      z-index: 1;
      transform: scale(1);
      //box-shadow: 0 0 0 2px $md-gray-900, 0 0 0 5px theme-color("primary");
      @include mat-elevation(3);
    }
  }

  &:not(.is-selected) {
    .text-center {
      opacity: 0.6;
    }
  }
}
