//
// MediaBeacon Current Colors and URL's
//
$black_10: rgba($black, 0.1) !default;
$black_18: rgba($black, 0.18) !default;
$black_20: rgba($black, 0.2) !default;
$black_30: rgba($black, 0.3) !default;
$black_40: rgba($black, 0.4) !default;
$black_50: rgba($black, 0.5) !default;
$black_70: rgba($black, 0.7) !default;
$black_8: rgba($black, 0.08) !default;
$black_90: rgba($black, 0.9) !default;
$white_36: rgba($white, 0.36) !default;
$white_50: rgba($white, 0.5) !default;
$white_80: rgba($white, 0.8) !default;
$color_cloud_80_approx: rgba(196, 196, 196, 0.8) !default;
$color_pumice_20_approx: rgba(198, 198, 198, 0.2) !default;
$color_pumice_40_approx: rgba(198, 198, 198, 0.4) !default;
$concrete_80: rgba(242, 242, 242, 0.8) !default;
$color_endeavour_40_approx: rgba(8, 95, 169, 0.4) !default;
$color_tundora_approx: #444 !default;
$color_fuscous_gray_approx: #555 !default;
$color_storm_dust_approx: #626262 !default;
$color_tapa_approx: #777 !default;
$gray: #808080 !default;
$color_suva_gray_approx: #888 !default;
$color_mountain_mist_approx: #999 !default;
$color_shady_lady_approx: #a5a5a5 !default;
$color_silver_chalice_approx: #aaa !default;
$color_pumice_approx: #cacaca !default;
$color_celeste_approx: #ccc !default;
$color_quill_gray_approx: #d5d5d5 !default;
$color_alto_approx: #ddd !default;
$color_bon_jour_approx: #e1e1e1 !default;
$color_mercury_approx: #e3e3e3 !default;
$color_gray_nurse_approx: #e8e8e8 !default;
$color_gallery_approx: #eee !default;
$color_concrete_approx: #f3f3f3 !default;
$color_black_haze_approx: #f7f7f7 !default;
$color_alabaster_approx: #fbfbfb !default;
$color_soapstone_approx: #fffafa !default;
$color_orange_approx: #f26522 !default;
$atomic_tangerine: #f96 !default;
$color_tree_poppy_approx: #f8941d !default;
$web_orange: #ffa500 !default;
$color_natural_gray_approx: #8c887e !default;
$color_turbo_approx: #fff200 !default;
$yellow: #ff0 !default;
$color_la_rioja_approx: #bbbe19 !default;
$color_dingley_approx: #657948 !default;
$color_olive_drab_approx: #688939 !default;
$color_chalet_green_approx: #4d682b !default;
$color_fern_green_approx: #5b7e32 !default;
$color_black_olive_approx: #243017 !default;
$color_woodland_approx: #476928 !default;
$color_feijoa_approx: #9ecf7a !default;
$color_tom_thumb_approx: #355828 !default;
$color_asparagus_approx: #6ca35a !default;
$green: #0f0 !default;
$color_amulet_approx: #70a97a !default;
$color_cararra_approx: #eaebeb !default;
$color_iron_approx: #d2d6d6 !default;
$color_seashell_approx: #eff1f1 !default;
$color_malibu_approx: #6fb5f1 !default;
$color_pigeon_post_approx: #b0c4de !default;
$color_spindle_approx: #b8cfee !default;
$color_jordy_blue_approx: #87b3ff !default;
$color_athens_gray_approx: #e7eaf5 !default;
$color_torea_bay_approx: #202a9c !default;
$blue: #00f !default;
$color_alizarin_crimson_approx: #ed1c24 !default;
// \$black_10|\$black_18|\$black_20|\$black_30|\$black_40|\$black_50|\$black_70|\$black_8|\$black_90|\$white_36|\$white_50|\$white_80|\$color_cloud_80_approx|\$color_pumice_20_approx|\$color_pumice_40_approx|\$concrete_80|\$color_endeavour_40_approx|\$color_tundora_approx|\$color_fuscous_gray_approx|\$color_storm_dust_approx|\$color_tapa_approx|\$gray|\$color_suva_gray_approx|\$color_mountain_mist_approx|\$color_shady_lady_approx|\$color_silver_chalice_approx|\$color_pumice_approx|\$color_celeste_approx|\$color_quill_gray_approx|\$color_alto_approx|\$color_bon_jour_approx|\$color_mercury_approx|\$color_gray_nurse_approx|\$color_gallery_approx|\$color_concrete_approx|\$color_black_haze_approx|\$color_alabaster_approx|\$color_soapstone_approx|\$color_orange_approx|\$atomic_tangerine|\$color_tree_poppy_approx|\$web_orange|\$color_natural_gray_approx|\$color_turbo_approx|\$yellow|\$color_la_rioja_approx|\$color_dingley_approx|\$color_olive_drab_approx|\$color_chalet_green_approx|\$color_fern_green_approx|\$color_black_olive_approx|\$color_woodland_approx|\$color_feijoa_approx|\$color_tom_thumb_approx|\$color_asparagus_approx|\$green|\$color_amulet_approx|\$color_cararra_approx|\$color_iron_approx|\$color_seashell_approx|\$color_malibu_approx|\$color_pigeon_post_approx|\$color_spindle_approx|\$color_jordy_blue_approx|\$color_athens_gray_approx|\$color_torea_bay_approx|\$blue|\$color_alizarin_crimson_approx
//$alabaster: #fafafa !default;
//$alice_blue: #f0f8ff !default;
//$baby_blue: #e0ffff !default;
//$black_12: rgba($black, .12) !default;
//$black_14: rgba($black, .14) !default;
//$black_15: rgba($black, .15) !default;
//$black_60: rgba($black, .6) !default;
//$blue_ribbon: #06f !default;
//$color_apple_approx: #4ecd39 !default;
//$color_astronaut_approx: #233d6d !default;
//$color_azure_approx: #3764a0 !default;
//$color_biloba_flower_approx: #b9f !default;
//$color_biscay_approx: #1b376c !default;
//$color_bittersweet_approx: #ff7870 !default;
//$color_blue_approx: #0505ff !default;
//$color_blue_gem_approx: #219 !default;
//$color_blue_ribbon_approx: #0036ff !default;
//$color_boston_blue_approx: #39b !default;
//$color_botticelli_approx: #cad9ec !default;
//$color_bunker_approx: #121212 !default;
//$color_cape_cod_approx: #404040 !default;
//$color_casper_approx: #a9bfd3 !default;
//$color_catskill_white_70_approx: rgba(241, 244, 249, .7) !default;
//$color_cello_approx: #17385b !default;
//$color_chambray_approx: #325882 !default;
//$color_chatelle_approx: #b5b8c8 !default;
//$color_congress_blue_approx: #15428b !default;
//$color_coral_red_approx: #ff4545 !default;
//$color_cornflower_approx: #9cf !default;
//$color_cornflower_blue_approx: #5ca0fd !default;
//$color_cornflower_lilac_approx: #ffa8a8 !default;
//$color_cream_can_approx: #eec05b !default;
//$color_danube_approx: #6593cf !default;
//$color_dark_blue_approx: #00c !default;
//$color_denim_approx: #206fb1 !default;
//$color_dodger_blue_approx: #2f8cff !default;
//$color_dove_gray_approx: #696969 !default;
//$color_downriver_approx: #001f52 !default;
//$color_east_bay_approx: #3b5a82 !default;
//$color_edward_approx: #9daca9 !default;
//$color_endeavour_90_approx: rgba(8, 95, 169, .9) !default;
//$color_endeavour_approx: #085fa9 !default;
//$color_eucalyptus_approx: #1da55d !default;
//$color_fair_pink_approx: #fee !default;
//$color_fiord_approx: #46546c !default;
//$color_forest_green_approx: #0faf39 !default;
//$color_french_pass_approx: #bee8ff !default;
//$color_fuel_yellow_approx: #eea236 !default;
//$color_fun_blue_approx: #1c62a9 !default;
//$color_geyser_approx: #d4dee5 !default;
//$color_golden_tainoi_approx: #fc6 !default;
//$color_gravel_approx: #494949 !default;
//$color_gray_70_approx: rgba(127, 127, 127, .7) !default;
//$color_green_white_approx: #ebeadb !default;
//$color_gurkha_approx: #997 !default;
//$color_hawkes_blue_approx: #cedff5 !default;
//$color_hoki_approx: #689 !default;
//$color_indigo_approx: #4d77c3 !default;
//$color_international_orange_approx: #f50 !default;
//$color_jacksons_purple_approx: #222297 !default;
//$color_japanese_laurel_approx: #008000 !default;
//$color_japonica_approx: #dd7870 !default;
//$color_jewel_approx: #164 !default;
//$color_jordy_blue_50_approx: rgba(132, 189, 232, .5) !default;
//$color_lavender_magenta_approx: #ff87eb !default;
//$color_link_water_approx: #dfe8f6 !default;
//$color_log_cabin_approx: #222 !default;
//$color_macaroni_and_cheese_approx: #ffc470 !default;
//$color_mandy_approx: #e94f5f !default;
//$color_mantis_approx: #6c6 !default;
//$color_mariner_approx: #36c !default;
//$color_matisse_approx: #206eac !default;
//$color_mauve_approx: #e7a9fe !default;
//$color_mexican_red_approx: #a52a2a !default;
//$color_mine_shaft_approx: #333 !default;
//$color_mona_lisa_60_approx: rgba(255, 153, 153, .6) !default;
//$color_moon_raker_approx: #d7d4f0 !default;
//$color_mystic_approx: #e0e8f3 !default;
//$color_napa_approx: #aca899 !default;
//$color_nepal_approx: #899ec1 !default;
//$color_nobel_approx: #b5b4b4 !default;
//$color_pastel_green_approx: #7e7 !default;
//$color_pattens_blue_approx: #deecfd !default;
//$color_perano_approx: #aaccf6 !default;
//$color_periwinkle_gray_70_approx: rgba(192, 208, 236, .7) !default;
//$color_periwinkle_gray_approx: #c5d2df !default;
//$color_pink_swan_approx: #bbb !default;
//$color_pizazz_30_approx: rgba(255, 150, 0, .3) !default;
//$color_polo_blue_approx: #7eadd9 !default;
//$color_purple_approx: #708 !default;
//$color_purple_heart_approx: #6c3ac7 !default;
//$color_red_approx: #f10000 !default;
//$color_reef_approx: #c0ffa0 !default;
//$color_regal_blue_approx: #083772 !default;
//$color_regent_st_blue_approx: #add8e6 !default;
//$color_rich_gold_approx: #a50 !default;
//$color_romance_approx: #fefefe !default;
//$color_royal_blue_approx: #425aff !default;
//$color_salem_approx: #085 !default;
//$color_san_marino_approx: #416aa3 !default;
//$color_sapphire_approx: #2c4ba0 !default;
//$color_shark_25_approx: rgba(38, 38, 38, .25) !default;
//$color_shark_approx: #262626 !default;
//$color_ship_cove_approx: #718bb7 !default;
//$color_silver_approx: #c1c1c1 !default;
//$color_solitude_approx: #e8f2ff !default;
//$color_spring_rain_approx: #9ccea5 !default;
//$color_star_dust_30_approx: rgba(155, 155, 155, .3) !default;
//$color_star_dust_approx: #9c9c9c !default;
//$color_stratos_approx: #005 !default;
//$color_suva_gray_30_approx: rgba(135, 135, 135, .3) !default;
//$color_suva_gray_55_approx: rgba(135, 135, 135, .55) !default;
//$color_tamarillo_approx: #a11 !default;
//$color_tango_20_approx: rgba(244, 116, 35, .2) !default;
//$color_tapa_14_approx: rgba(117, 117, 117, .14) !default;
//$color_tapa_40_approx: rgba(117, 117, 117, .4) !default;
//$color_tiara_approx: #c9ced1 !default;
//$color_torch_red_approx: #f22 !default;
//$color_tradewind_approx: #58baa0 !default;
//$color_tropical_blue_approx: #c3daf9 !default;
//$color_tuatara_approx: #393939 !default;
//$color_valencia_approx: #d44 !default;
//$color_whiskey_approx: #ce9864 !default;
//$color_wild_sand_approx: #f5f5f5 !default;
//$color_woodsmoke_approx: #111 !default;
//$cornflower_blue: #6495ed !default;
//$gallery: #efefef !default;
//$laser_lemon: #ff6 !default;
//$lavender_magenta: #ee82ee !default;
//$mercury: #e5e5e5 !default;
//$midnight_blue: #036 !default;
//$mine_shaft: #323232 !default;
//$seashell: #f1f1f1 !default;
//$silver: #c0c0c0 !default;
//$white_70: rgba($white, .7) !default;
//$white_85: rgba($white, .85) !default;
//$yellow_40: rgba(255, 255, 0, .4) !default;
