//
// HTML Widget
//
// This class is only to be used for the inside of the HTML iFrame
// The modifications are needed to override our CSS inside of the iFrame.
.html-component-inner-frame {
  &,
  body {
    display: block;
    min-height: auto;
    background-color: transparent;
  }
}

.html-config-panel {
  .CodeEditor {
    margin-top: 0 !important;
    border-bottom: $list-group-border-width solid $list-group-border-color;
  }

  .outer {
    padding: $btn-padding-x;
    border-bottom: $list-group-border-width solid $list-group-border-color;
    background-color: $md-gray-50;
  }
}
