//
// Opacity utilities
//
.transparent {
  opacity: 0 !important;
}

.opacity-100-active {
  opacity: 0;

  .btn.active & {
    opacity: 1;
  }
}

.opacity-100 {
  opacity: 1 !important;
}

.offline {
  opacity: 0.7;
}

.disabled {
  cursor: default !important;
  user-select: none;
  pointer-events: none;
  opacity: $btn-disabled-opacity;
  box-shadow: none !important;
}

.ReadOnly {
  .disabled {
    pointer-events: all;
    -webkit-user-select: all;
    user-select: all;
  }
}
