//
// gwt-Tree
//
.treeFirstTableCell {
  position: relative;
  width: 1.5rem;
}

.treeFirstTableCellEmpty {
  width: 24px;

  &::before {
    content: "" !important;
  }
}

.MBTreeItem {
  position: relative;

  > div {
    width: auto !important;
  }

  > .gwt-TreeItem {
    margin-left: 24px;
  }
}

.directory-h-scroll {
  .my-tree-item-text {
    .white-space-pre {
      white-space: pre;
    }
  }
}

.my-tree-item-text {
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .white-space-pre {
    white-space: normal;
  }
}

.mb-tree-table {
  &,
  tbody,
  tr,
  th,
  td {
    padding: 0;
    border-spacing: 0;
    border: none;
  }

  &:hover {
    .my-tree-item-text {
      &:not(:disabled):not(.disabled) {
        background-color: rgba($black, 0.05);
      }

      &.disabled {
        cursor: default;
      }
    }
  }

  &:hover,
  .my-tree-item-text:hover,
  .my-tree-item-text.show {
    .badge-tree-count,
    .btn-icon {
      position: static;
      transform: translate3d(0, 0, 0);
      pointer-events: auto;
    }

    .btn-icon {
      opacity: 1;
    }
  }
}

.my-tree-item-text {
  min-height: 39px;

  .btn-icon:not(.show) {
    opacity: 0;
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      background-color: rgba($black, 0.05);
    }
  }

  &.disabled {
    cursor: default;
  }
}

.my-tree-has-count {
  .badge-tree-count,
  .btn-icon {
    transition: $swift-ease-out;
  }

  .btn-icon {
    position: absolute;
    right: 0;
    transform: translate3d(100%, 0, 0);
    pointer-events: none;
  }
}

.gwt-TreeItem {
  display: block;
  //cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:not(.gwt-TreeItem-selected) {
    .hasNoItems {
      .cursor-pointer {
        font-weight: $font-weight-base;
        cursor: default !important;
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
      }
    }
  }
}

.my-tree-item-text {
  margin-left: -25px;
  padding: $dropdown-item-padding-y ($dropdown-item-padding-x / 4) $dropdown-item-padding-y $dropdown-item-padding-x;
}

.btn-tree-trigger {
  font-size: 20px;
  position: relative;

  &::before {
    transform: rotate(-90deg);
  }

  .open & {
    &::before {
      transform: none;
    }
  }

  img {
    position: absolute;
    @include size(100% !important);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.gwt-TreeItem-selected {
  z-index: 1; // Place active items above their siblings for proper border styling
  .DropZone-dragging & {
    z-index: 0;
  }

  .my-tree-item-text {
    &,
    &:hover {
      background-color: $component-active-bg-light;
    }
  }
}

.tree-item-selected {
  .my-tree-item-text {
    background-color: $component-active-bg-light;
  }

  .selectable-icon {
    color: theme-color("primary");
  }

  .icon-is-selected {
    display: inline-block;
  }

  .icon-is-not-selected {
    display: none;
  }
}

.selectable-icon {
  color: rgba($black, 0.4);
}

.icon-is-selected {
  display: none;
}

.icon-is-not-selected {
  display: inline-block;
}

.mb-tree-select-panel {
  > .MBTreeItem {
    margin-bottom: $dropdown-padding-y;
    padding-bottom: $dropdown-padding-y !important;

    &:not(:last-child) {
      border-bottom: $border-width solid $md-gray-300;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.folder-icon,
.workflow-icon {
  color: $md-gray-500;

  .hasNoItems & {
    opacity: $btn-disabled-opacity;
  }

  .gwt-TreeItem-selected & {
    color: theme-color("primary");
  }
}
